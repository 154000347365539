import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Select,
  Checkbox,
  Button,
  Spin,
  DatePicker,
  Switch,
  Modal,
  message,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import moment from "moment";
import BarLoader from "react-spinners/BarLoader";
import { ToastContainer, toast } from "react-toastify";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function FiltersView() {
  const [activePatient, setActivePatient] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [patientDates, setPatientDates] = useState({});
  const [dataSelected, setDataSelected] = useState({});
  const [defaultData, setDefaultData] = useState([]);
  const [showAllData, setShowAllData] = useState(false);
  const [rowToggles, setRowToggles] = useState({});
  const startOfWeek = dayjs(dayjs().startOf("week"), "DD/MM/YYYY");
  const endOfWeek = dayjs(dayjs().endOf("week"), "DD/MM/YYYY");
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedLabs, setSelectedLabs] = useState([]);
  const [form] = Form.useForm();
  const [selectedDispensed, setSelectedDispensed] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [dosages, setDosages] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [previousSearchResults, setPreviousSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isPartialMatch, setIsPartialMatch] = useState(true);
  const [showOrdered, setShowOrdered] = useState(false);
  const tableRef = useRef(null);
  const [pendingRequests, setPendingRequests] = useState(0);

  useEffect(() => {
    if (tableRef.current) {
      if (tableRef.current.rows.length === 1 && currentPage !== totalPages) {
        setCurrentPage(currentPage + 1);
        console.log(
          "tableRef.current.rows.length",
          tableRef.current.rows.length,
          currentPage,
          totalPages
        );
        form.submit();
      }
    }
  }, [searchResults]);

  const handleToggleChange = (checked) => {
    form.submit();
    setIsPartialMatch(checked);
  };
  const handleShowOrderedToggleChange = (checked) => {

    setShowOrdered(checked);
    if (checked) {
      setPreviousSearchResults(searchResults)
      const orders = searchResults.filter((item) => item.type === "Order")
      setSearchResults(orders);
    }
    else {
      setSearchResults(previousSearchResults)
    }
  };

  const GetActivePatientProfiles = async () => {
    try {
      setLoader(true);
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        setActivePatient(response.data);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
    }
  };

  const GetDefaultData = async () => {
    try {
      setLoading(true);

      const responseDefault = await axios.get(
        "/defaultData",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );


      setDefaultData(responseDefault.data);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetActivePatientProfiles();
    GetDefaultData();
  }, []);

  const handlePatientChange = (value) => {
    setSelectedPatient(value);
  };

  const handleDosageChange = (item, dosage, checked) => {
    setDosages((prevDosages) => {
      const updatedDosages = { ...prevDosages };
      if (checked) {
        if (!updatedDosages[item._id]) {
          updatedDosages[item._id] = [];
        }
        updatedDosages[item._id].push(dosage);
      } else {
        updatedDosages[item._id] = updatedDosages[item._id].filter(
          (d) => d !== dosage
        );
      }
      return updatedDosages;
    });
  };

  const getNameById = (list, id) => {
    const item = list.find((i) => i._id === id);
    return item
      ? item.name ||
      item.procedure_name ||
      item.supplement_name ||
      item.test_name
      : id;
  };

  const decrementPendingRequests = () => {
    setPendingRequests((prev) => {
      const newPendingRequests = prev - 1;
      if (newPendingRequests === 0) {
        setLoadingTableData(false);
      }
      return newPendingRequests;
    });
  };

  const handleSubmit = async (values) => {
    setLoadingTableData(true);
    setPendingRequests((prev) => prev + 1);
    console.log("values", values);

    if (
      (values?.dateRange === undefined || values?.dateRange === null) &&
      (values?.patient === undefined || values?.patient === null) &&
      (!Array.isArray(values?.labs) || values?.labs?.length === 0) &&
      (!Array.isArray(values?.dispensed) || values?.dispensed?.length === 0) &&
      (!Array.isArray(values?.procedures) ||
        values?.procedures?.length === 0) &&
      (!Array.isArray(values?.supplements) || values?.supplements?.length === 0)
    ) {
      setCurrentPage(1);
      setSearchResults([]);
      setLoadingTableData(false);
      setTotalPages(1);
      decrementPendingRequests();
      console.log("return calling");
      return;
    }

    const dateRange = values.dateRange;
    const startDate = dateRange ? dateRange[0].format("YYYY-MM-DD") : null;
    const endDate = dateRange ? dateRange[1].format("YYYY-MM-DD") : null;

    const selectedData = {
      patient: values.patient,
      labs: Array.isArray(values?.labs)
        ? values?.labs?.map((labId) => getNameById(defaultData.labs, labId))
        : [],
      dispensed: Array.isArray(values?.dispensed)
        ? values?.dispensed?.map((dispensedId) => ({
          name: getNameById(defaultData.dispensed, dispensedId),
          dosages: dosages[dispensedId] || [],
        }))
        : [],
      procedures: Array.isArray(values?.procedures)
        ? values?.procedures?.map((procedureId) => ({
          name: getNameById(defaultData.procedures, procedureId),
          dosages: dosages[procedureId] || [],
        }))
        : [],
      supplements: Array.isArray(values?.supplements)
        ? values?.supplements?.map((supplementId) => ({
          name: getNameById(defaultData.supplements, supplementId),
          dosages: dosages[supplementId] || [],
        }))
        : [],
      page: currentPage,
      startDate,
      endDate,
    };



    if (
      (values.patient &&
        Array.isArray(selectedData?.labs) &&
        selectedData?.labs?.length === 0 &&
        Array.isArray(selectedData?.dispensed) &&
        selectedData?.dispensed?.length === 0 &&
        Array.isArray(selectedData?.procedures) &&
        selectedData?.procedures?.length === 0 &&
        Array.isArray(selectedData?.supplements) &&
        selectedData?.supplements?.length === 0) ||
      (values.dateRange !== undefined &&
        values.dateRange !== null &&
        Array.isArray(selectedData?.labs) &&
        selectedData?.labs?.length === 0 &&
        Array.isArray(selectedData?.dispensed) &&
        selectedData?.dispensed?.length === 0 &&
        Array.isArray(selectedData?.procedures) &&
        selectedData?.procedures?.length === 0 &&
        Array.isArray(selectedData?.supplements) &&
        selectedData?.supplements?.length === 0)
    ) {
      setShowAllData(true);
    } else {
      setShowAllData(false);
    }
    setDataSelected(selectedData);



    try {
      const response = await axios.post(
        "/searchOrders",
        selectedData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );


      if (values.patient === undefined || values.patient === null) {
        setSearchResults((prevSearchResults) => {
          const combinedResults = [
            ...prevSearchResults,
            ...response.data.orders,
          ];

          const uniqueResults = Array.from(
            combinedResults
              .reduce((map, item) => map.set(item._id, item), new Map())
              .values()
          );

          uniqueResults.sort((a, b) => new Date(b.date) - new Date(a.date));

          return uniqueResults;
        });
      } else {

        setSearchResults(response.data.orders);
      }
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      // window.scrollTo(0, 0);
      setLoadingTableData(false);
    } catch (error) {
      console.error("Error searching orders:", error);
      setLoadingTableData(false);
      // setSearchResults([]);
    }
  };

  const renderCheckboxes = (item) => (
    <div className="checkbox-container">
      {item.dosages.map((dosage, index) => (
        <div key={index}>
          <Checkbox
            onChange={(e) => {
              handleDosageChange(item, dosage, e.target.checked);
              form.submit();
            }}
            checked={dosages[item._id]?.includes(dosage)}
            value={dosage}
          >
            {dosage}
          </Checkbox>
        </div>
      ))}
    </div>
  );

  const handleDownloadPDF = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "letter",
    });

    const renderHeader = (doc) => {
      doc.setFontSize(8);
      doc.text("FACILITY", 10, 10);
      doc.setFont(undefined, "bold");
      doc.text("Sunridge Medical", 10, 15);
      doc.setFont(undefined, "normal");
      doc.text("T (480) 659-9135", 10, 20);
      doc.text("F (480) 659-9197", 10, 25);
      doc.text("14200 N Northsight Blvd 160", 135, 15);
      doc.text("Scottsdale, AZ 85260", 135, 20);
      doc.text(`Print Date: ${moment().format("MM/DD/YYYY")}`, 135, 25);
    };

    renderHeader(doc);

    const tableColumn = ["Date", "Patient Name", "Details"];
    const tableRows = [];
    if (isPartialMatch) {
      searchResults.forEach((item) => {
        const showRowData = rowToggles[item._id] || showAllData;

        const filteredLabs = item.userLabs.filter(
          (lab) =>
            lab.checked &&
            (showRowData || dataSelected.labs.includes(lab.test_name))
        );

        const filteredProcedures = item.userProcedures?.filter(
          (procedure) =>
            (procedure.checked === true ||
              (procedure.selectedField && procedure.selectedField !== "")) &&
            (showRowData ||
              dataSelected.procedures.some(
                (pr) =>
                  pr.name === procedure.procedure_name &&
                  (pr.dosages.length === 0 ||
                    pr.dosages.includes(procedure.selectedField))
              ))
        );

        const filteredDispensed = item.userdispensed?.filter(
          (disp) =>
            disp.selectedField !== "" &&
            (showRowData ||
              dataSelected.dispensed.some(
                (ds) =>
                  ds.name === disp.name &&
                  (ds.dosages.length === 0 ||
                    ds.dosages.includes(disp.selectedField))
              ))
        );

        const filteredSupplements = item.userSupplements?.filter(
          (supplement) =>
            supplement.quantity > 0 &&
            (showRowData ||
              dataSelected.supplements.some(
                (sp) =>
                  sp.name === supplement.name &&
                  (sp.dosages.length === 0 ||
                    sp.dosages.includes(supplement.selectedField))
              ))
        );

        const labsMatch =
          dataSelected.labs.length === 0 ||
          dataSelected.labs.every((lab) =>
            filteredLabs.some((filteredLab) => filteredLab.test_name === lab)
          );
        const proceduresMatch =
          dataSelected.procedures.length === 0 ||
          dataSelected.procedures.every((proc) =>
            filteredProcedures.some(
              (filteredProc) =>
                filteredProc.procedure_name === proc.name &&
                (proc.dosages.length === 0 ||
                  proc.dosages.includes(filteredProc.selectedField))
            )
          );
        const dispensedMatch =
          dataSelected.dispensed.length === 0 ||
          dataSelected.dispensed.every((disp) =>
            filteredDispensed.some(
              (filteredDisp) =>
                filteredDisp.name === disp.name &&
                (disp.dosages.length === 0 ||
                  disp.dosages.includes(filteredDisp.selectedField))
            )
          );
        const supplementsMatch =
          dataSelected.supplements.length === 0 ||
          dataSelected.supplements.every((supp) =>
            filteredSupplements.some(
              (filteredSupp) =>
                filteredSupp.name === supp.name &&
                (supp.dosages.length === 0 ||
                  supp.dosages.includes(filteredSupp.selectedField))
            )
          );

        const showRow =
          labsMatch && proceduresMatch && dispensedMatch && supplementsMatch;

        // Only add the row if there are non-empty filtered results
        if (
          (filteredSupplements.length > 0 ||
            filteredDispensed.length > 0 ||
            filteredProcedures.length > 0 ||
            filteredLabs.length > 0) &&
          showRow
        ) {
          const details = [];

          if (filteredProcedures.length > 0) {
            details.push(
              `Procedures: ${filteredProcedures
                .map(
                  (proc) =>
                    `${proc.procedure_name}${proc.selectedField ? ` (${proc.selectedField})` : ""
                    }`
                )
                .join(", ")}`
            );
          }

          if (filteredDispensed.length > 0) {
            details.push(
              `Dispensed: ${filteredDispensed
                .map(
                  (disp) =>
                    `${disp.name}${disp.selectedField ? ` (${disp.selectedField})` : ""
                    } x ${disp.quantity}`
                )
                .join(", ")}`
            );
          }

          if (filteredLabs.length > 0) {
            details.push(
              `Labs: ${filteredLabs.map((lab) => lab.test_name).join(", ")}`
            );
          }

          if (filteredSupplements.length > 0) {
            details.push(
              `Supplements: ${filteredSupplements
                .map(
                  (supp) =>
                    `${supp.name}${supp.selectedField ? ` (${supp.selectedField})` : ""
                    } x ${supp.quantity}`
                )
                .join(", ")}`
            );
          }

          tableRows.push([
            new Date(item.date).toLocaleDateString(),
            item.patientName || "Unknown",
            details.join("\n"),
          ]);
        }
      });
    } else {
      searchResults.forEach((item) => {
        const showRowData = rowToggles[item._id] || showAllData;

        const filteredLabs =
          dataSelected.labs.length === 0 && !showAllData
            ? []
            : item.userLabs.filter((lab) => lab.checked);

        const hasUncheckedLabs =
          dataSelected.labs.length > 0 &&
          item.userLabs.some(
            (lab) =>
              lab.checked === true && !dataSelected.labs.includes(lab.test_name)
          );

        const labsMatch =
          dataSelected.labs.length === 0 ||
          (filteredLabs.length === dataSelected.labs.length &&
            !hasUncheckedLabs);

        const filteredProcedures =
          dataSelected.procedures.length === 0 && !showAllData
            ? []
            : item.userProcedures?.filter(
              (procedure) =>
                procedure.checked === true ||
                (procedure.selectedField && procedure.selectedField !== "")
            );

        const hasUncheckedProcedures =
          dataSelected.procedures.length > 0 &&
          item.userProcedures.some(
            (proc) =>
              (proc.checked === true ||
                (proc.selectedField && proc.selectedField !== "")) &&
              !dataSelected.procedures.some(
                (pr) =>
                  pr.name === proc.procedure_name &&
                  (pr.dosages.length === 0 ||
                    pr.dosages.includes(proc.selectedField))
              )
          );

        const proceduresMatch =
          dataSelected.procedures.length === 0 ||
          (filteredProcedures.length === dataSelected.procedures.length &&
            !hasUncheckedProcedures);

        const filteredDispensed =
          dataSelected.dispensed.length === 0 && !showAllData
            ? []
            : item.userdispensed?.filter((disp) => disp.selectedField !== "");

        const hasUncheckedDispensed =
          dataSelected.dispensed.length > 0 &&
          item.userdispensed.some(
            (disp) =>
              disp.selectedField !== "" &&
              !dataSelected.dispensed.some(
                (ds) =>
                  ds.name === disp.name &&
                  (ds.dosages.length === 0 ||
                    ds.dosages.includes(disp.selectedField))
              )
          );

        const dispensedMatch =
          dataSelected.dispensed.length === 0 ||
          (filteredDispensed.length === dataSelected.dispensed.length &&
            !hasUncheckedDispensed);

        const filteredSupplements =
          dataSelected.supplements.length === 0 && !showAllData
            ? []
            : item.userSupplements?.filter(
              (supplement) => supplement.quantity > 0
            );

        const hasUncheckedSupplements =
          dataSelected.supplements.length > 0 &&
          item.userSupplements.some(
            (supplement) =>
              supplement.quantity > 0 &&
              !dataSelected.supplements.some(
                (sp) =>
                  sp.name === supplement.name &&
                  (sp.dosages.length === 0 ||
                    sp.dosages.includes(supplement.selectedField))
              )
          );

        const supplementsMatch =
          dataSelected.supplements.length === 0 ||
          (filteredSupplements.length === dataSelected.supplements.length &&
            !hasUncheckedSupplements);

        if (
          !labsMatch ||
          !proceduresMatch ||
          !dispensedMatch ||
          !supplementsMatch
        ) {
          return null;
        }

        if (
          filteredSupplements.length > 0 ||
          filteredDispensed.length > 0 ||
          filteredProcedures.length > 0 ||
          filteredLabs.length > 0
        ) {
          const details = [];

          if (filteredProcedures.length > 0) {
            details.push(
              `Procedures: ${filteredProcedures
                .map(
                  (proc) =>
                    `${proc.procedure_name}${proc.selectedField ? ` (${proc.selectedField})` : ""
                    }`
                )
                .join(", ")}`
            );
          }

          if (filteredDispensed.length > 0) {
            details.push(
              `Dispensed: ${filteredDispensed
                .map(
                  (disp) =>
                    `${disp.name}${disp.selectedField ? ` (${disp.selectedField})` : ""
                    } x ${disp.quantity}`
                )
                .join(", ")}`
            );
          }

          if (filteredLabs.length > 0) {
            details.push(
              `Labs: ${filteredLabs.map((lab) => lab.test_name).join(", ")}`
            );
          }

          if (filteredSupplements.length > 0) {
            details.push(
              `Supplements: ${filteredSupplements
                .map(
                  (supp) =>
                    `${supp.name}${supp.selectedField ? ` (${supp.selectedField})` : ""
                    } x ${supp.quantity}`
                )
                .join(", ")}`
            );
          }

          tableRows.push([
            new Date(item.date).toLocaleDateString(),
            item.patientName || "Unknown",
            details.join("\n"),
          ]);
        }
      });
    }

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      theme: "grid",
      headStyles: { fillColor: [220, 220, 220] },
    });

    doc.save("Filter_Data.pdf");
  };

  const handleDateChange = (customerId, date) => {
    if (!initialDate) {
      setInitialDate(date);
    }
    setPatientDates((prevDates) => ({
      ...prevDates,
      [customerId]: date,
    }));
  };

  const handleSubmitTreatment = async () => {
    if (selectedPatients.length === 0) {
      message.error("Please select at least one patient.");
      return;
    }

    if (selectedPatients.some((customerId) => !patientDates[customerId])) {
      message.error("Please enter a date for each selected patient.");
      return;
    }

    const treatmentData = selectedPatients.map((customerId) => ({
      customerId,
      date: patientDates[customerId]?.format("MM/DD/YYYY"),
    }));

    const selectedData = {
      patient: selectedPatient,
      labs: selectedLabs.map((labId) => getNameById(defaultData.labs, labId)),
      dispensed: selectedDispensed.map((dispensedId) => ({
        name: getNameById(defaultData.dispensed, dispensedId),
        dosages: dosages[dispensedId] || [],
      })),
      procedures: selectedProcedures.map((procedureId) => ({
        name: getNameById(defaultData.procedures, procedureId),
        dosages: dosages[procedureId] || [],
      })),
      supplements: selectedSupplements.map((supplementId) => ({
        name: getNameById(defaultData.supplements, supplementId),
        dosages: dosages[supplementId] || [],
      })),
    };



    try {
      const responses = await Promise.all(
        treatmentData.map((treatment) =>
          axios.post(
            "/createTreatment",
            {
              PatientId: treatment.customerId,
              SCDate: treatment.date,
              treatmentData: selectedData,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                "Content-Type": "application/json",
              },
            }
          )
        )
      );


      toast.success("Treatments created successfully.");
      // Handle success (e.g., show a success message, refresh data, etc.)
    } catch (error) {
      console.error("Error creating treatments:", error);
      // Handle error (e.g., show an error message)
    }

    setIsModalVisible(false);
  };

  const SData = {
    labs: selectedLabs.map((labId) => getNameById(defaultData.labs, labId)),
    dispensed: selectedDispensed.map((dispensedId) => ({
      name: getNameById(defaultData.dispensed, dispensedId),
      dosages: dosages[dispensedId] || [],
    })),
    procedures: selectedProcedures.map((procedureId) => ({
      name: getNameById(defaultData.procedures, procedureId),
      dosages: dosages[procedureId] || [],
    })),
    supplements: selectedSupplements.map((supplementId) => ({
      name: getNameById(defaultData.supplements, supplementId),
      dosages: dosages[supplementId] || [],
    })),
  };

  // useEffect(() => {
  //   handleSubmit({
  //     patient: selectedPatient,
  //     dateRange: form.getFieldValue("dateRange"),
  //     labs: selectedLabs,
  //     dispensed: selectedDispensed,
  //     procedures: selectedProcedures,
  //     supplements: selectedSupplements,
  //   });
  // }, []);

  const [initialDate, setInitialDate] = useState(null);

  const handleDateClear = (patientId) => {
    setPatientDates((prevDates) => {
      const updatedDates = { ...prevDates };
      delete updatedDates[patientId];
      return updatedDates;
    });

    setInitialDate(null);

  };

  const [labsOptions, setLabsOptions] = useState([]);
  const [dispensedOptions, setDispensedOptions] = useState([]);
  const [proceduresOptions, setProceduresOptions] = useState([]);
  const [supplementsOptions, setSupplementsOptions] = useState([]);

  useEffect(() => {
    setLabsOptions(getOptions(defaultData?.labs, selectedLabs));
    setDispensedOptions(getOptions(defaultData?.dispensed, selectedDispensed));
    setProceduresOptions(
      getOptions(defaultData?.procedures, selectedProcedures)
    );
    setSupplementsOptions(
      getOptions(defaultData?.supplements, selectedSupplements)
    );
  }, [defaultData]);

  const handleDropdownVisibleChange = (type, open) => {
    if (!open) {
      switch (type) {
        case "labs":
          setLabsOptions(getOptions(defaultData?.labs, selectedLabs));
          break;
        case "dispensed":
          setDispensedOptions(
            getOptions(defaultData?.dispensed, selectedDispensed)
          );
          break;
        case "procedures":
          setProceduresOptions(
            getOptions(defaultData?.procedures, selectedProcedures)
          );
          break;
        case "supplements":
          setSupplementsOptions(
            getOptions(defaultData?.supplements, selectedSupplements)
          );
          break;
        default:
          break;
      }
    }
  };

  const getOptions = (data = [], selected) => {
    const selectedData = (selected || []).map((item) => ({
      ...data.find((d) => d._id === item),
      priority: 1, // Higher priority for selected items
    }));

    const otherData = (data || []).map((item) => ({
      ...item,
      priority: selected.includes(item._id) ? 1 : 2, // Higher priority for selected items
    }));

    const combinedData = [...selectedData, ...otherData];

    const uniqueData = Array.from(
      new Map(combinedData.map((item) => [item._id, item])).values()
    );

    uniqueData.sort((a, b) => a.priority - b.priority);

    return uniqueData;
  };

  return (
    <div className="container mx-auto">
      <ToastContainer />
      <Modal
        title="Create Treatment"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            className="text-white bg-blue-500"
            key="submit"
            type="primary"
            onClick={handleSubmitTreatment}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="mt-4">
          <h3 className="font-bold">Selected Filters</h3>

          {SData?.labs?.length > 0 && (
            <div className="mt-2 flex">
              <h4 className="font-semibold">Labs: </h4>
              <p className="ml-2"> {SData?.labs.join(", ")}</p>
            </div>
          )}
          {SData?.procedures?.length > 0 && (
            <div className="mt-2 flex">
              <h4 className="font-semibold">Procedures: </h4>
              <p className="ml-2">
                {SData?.procedures.map((procedure, index) => (
                  <span key={index}>
                    {procedure.name}
                    {procedure.dosages.length > 0 && (
                      <span> ({procedure.dosages.join(", ")})</span>
                    )}
                    {index < SData?.procedures.length - 1 && ", "}
                  </span>
                ))}
              </p>
            </div>
          )}

          {SData?.dispensed?.length > 0 && (
            <div className="mt-2 flex">
              <h4 className="font-semibold">Dispensed: </h4>
              <p className="ml-2">
                {SData?.dispensed?.map((dispensed, index) => (
                  <span key={index}>
                    {dispensed.name}
                    {dispensed.dosages.length > 0 && (
                      <span> ({dispensed.dosages.join(", ")})</span>
                    )}
                    {index < SData?.dispensed.length - 1 && ", "}
                  </span>
                ))}
              </p>
            </div>
          )}

          {SData?.supplements?.length > 0 && (
            <div className="mt-2 flex">
              <h4 className="font-semibold">Supplements: </h4>
              <p className="ml-2">
                {SData?.supplements?.map((supplement, index) => (
                  <span key={index}>
                    {supplement.name}
                    {supplement.dosages.length > 0 && (
                      <span> ({supplement.dosages.join(", ")})</span>
                    )}
                    {index < SData.supplements.length - 1 && ", "}
                  </span>
                ))}
              </p>
            </div>
          )}
        </div>
        <Select
          mode="multiple"
          allowClear
          placeholder="Select patients"
          className="w-full m-3 border-gray-300 rounded-lg shadow-sm"
          onChange={(value) => setSelectedPatients(value)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {activePatient.map((patient) => (
            <Option key={patient["Customer ID"]} value={patient["Customer ID"]}>
              {patient["Full Name"]}
            </Option>
          ))}
        </Select>

        {selectedPatients.map((patientId) => {
          const patient = activePatient.find(
            (p) => p["Customer ID"] === patientId
          );
          return (
            <div key={patientId} className="mt-4 flex justify-between">
              <h4>{patient["Full Name"]}</h4>
              <DatePicker
                placeholder="Select treatment date"
                className="w-[70%]"
                onChange={(date) => {
                  if (date === null) {
                    handleDateClear(patientId);
                  } else {
                    handleDateChange(patientId, date);
                  }
                }}
                value={patientDates[patientId] || initialDate}
              />
            </div>
          );
        })}
      </Modal>

      <h1 className="text-2xl font-bold mb-4 mt-6">Filters View</h1>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="inline"
        className="flex flex-wrap items-center space-x-4 p-4 bg-gray-100 rounded-lg shadow-lg"
      >
        <Form.Item name="patient" className="flex-grow">
          {loader ? (
            <div className="m-2 flex items-center justify-center">
              <BarLoader color="#000" />
            </div>
          ) : (
            <Select
              showSearch
              allowClear
              placeholder="Select a patient"
              optionFilterProp="children"
              onChange={(value) => {
                setSelectedPatient(value);
                handleSubmit({
                  patient: value,
                  dateRange: form.getFieldValue("dateRange"),
                  labs: selectedLabs,
                  dispensed: selectedDispensed,
                  procedures: selectedProcedures,
                  supplements: selectedSupplements,
                });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className="w-full m-3 border-gray-300 rounded-lg shadow-sm"
            >
              {activePatient.map((patient) => (
                <Option key={patient["_id"]} value={patient["Customer ID"]}>
                  {patient["Full Name"]}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          name="dateRange"
          className="flex-grow"
          style={{ minWidth: "20%" }}
        // initialValue={[startOfWeek, endOfWeek]}
        >
          <RangePicker
            // defaultValue={[startOfWeek, endOfWeek]}
            format="MM/DD/YYYY"
            onChange={(dates) => {
              handleSubmit({
                patient: selectedPatient,
                dateRange: dates,
                labs: selectedLabs,
                dispensed: selectedDispensed,
                procedures: selectedProcedures,
                supplements: selectedSupplements,
              });
            }}
          />
        </Form.Item>

        <Form.Item
          name="labs"
          className="flex-grow"
          style={{ minWidth: "15%" }}
        >
          {loading ? (
            <div className="m-2 flex items-center justify-center">
              <BarLoader color="#000" />
            </div>
          ) : (
            <Select
              mode="multiple"
              allowClear
              placeholder="Select labs"
              className="w-full m-3 border-gray-300 rounded-lg shadow-sm"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              value={selectedLabs}
              onChange={(value) => {
                setSelectedLabs(value);
                handleSubmit({
                  patient: selectedPatient,
                  dateRange: form.getFieldValue("dateRange"),
                  labs: value,
                  dispensed: selectedDispensed,
                  procedures: selectedProcedures,
                  supplements: selectedSupplements,
                });
              }}
              onDropdownVisibleChange={(open) =>
                handleDropdownVisibleChange("labs", open)
              }
            >
              {labsOptions.map((lab) => (
                <Option key={lab._id} value={lab._id}>
                  {lab.test_name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          name="dispensed"
          className="flex-grow"
          style={{ minWidth: "15%" }}
        >
          {loading ? (
            <div className="m-2 flex items-center justify-center">
              <BarLoader color="#000" />
            </div>
          ) : (
            <Select
              mode="multiple"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children[0].toLowerCase().includes(input.toLowerCase())
              }
              placeholder="Select dispensed"
              className="w-full m-3 border-gray-300 rounded-lg shadow-sm"
              value={selectedDispensed}
              onChange={(value) => {
                setSelectedDispensed(value);
                handleSubmit({
                  patient: selectedPatient,
                  dateRange: form.getFieldValue("dateRange"),
                  labs: selectedLabs,
                  dispensed: value,
                  procedures: selectedProcedures,
                  supplements: selectedSupplements,
                });
              }}
              onDropdownVisibleChange={(open) =>
                handleDropdownVisibleChange("dispensed", open)
              }
            >
              {dispensedOptions.map((item) => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                  <span onClick={(e) => e.stopPropagation()}>
                    {selectedDispensed.includes(item._id) &&
                      item.dosages &&
                      item.dosages.length > 0 &&
                      renderCheckboxes(item)}
                  </span>
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          name="procedures"
          className="flex-grow"
          style={{ minWidth: "15%" }}
        >
          {loading ? (
            <div className="m-2 flex items-center justify-center">
              <BarLoader color="#000" />
            </div>
          ) : (
            <Select
              mode="multiple"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children[0].toLowerCase().includes(input.toLowerCase())
              }
              placeholder="Select procedures"
              className="w-full m-3 border-gray-300 rounded-lg shadow-sm"
              value={selectedProcedures}
              onChange={(value) => {
                setSelectedProcedures(value);
                handleSubmit({
                  patient: selectedPatient,
                  dateRange: form.getFieldValue("dateRange"),
                  labs: selectedLabs,
                  dispensed: selectedDispensed,
                  procedures: value,
                  supplements: selectedSupplements,
                });
              }}
              onDropdownVisibleChange={(open) =>
                handleDropdownVisibleChange("procedures", open)
              }
            >
              {proceduresOptions.map((procedure) => (
                <Option key={procedure._id} value={procedure._id}>
                  {procedure.procedure_name}
                  <span onClick={(e) => e.stopPropagation()}>
                    {selectedProcedures.includes(procedure._id) &&
                      procedure.dosages &&
                      procedure.dosages.length > 0 &&
                      renderCheckboxes(procedure)}
                  </span>
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          name="supplements"
          className="flex-grow"
          style={{ minWidth: "15%" }}
        >
          {loading ? (
            <div className="m-2 flex items-center justify-center">
              <BarLoader color="#000" />
            </div>
          ) : (
            <Select
              mode="multiple"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children[0].toLowerCase().includes(input.toLowerCase())
              }
              placeholder="Select supplements"
              className="w-full m-3 border-gray-300 rounded-lg shadow-sm"
              value={selectedSupplements}
              onChange={(value) => {
                setSelectedSupplements(value);
                handleSubmit({
                  patient: selectedPatient,
                  dateRange: form.getFieldValue("dateRange"),
                  labs: selectedLabs,
                  dispensed: selectedDispensed,
                  procedures: selectedProcedures,
                  supplements: value,
                });
              }}
              onDropdownVisibleChange={(open) =>
                handleDropdownVisibleChange("supplements", open)
              }
            >
              {supplementsOptions.map((supplement) => (
                <Option key={supplement._id} value={supplement._id}>
                  {supplement.supplement_name}
                  <span onClick={(e) => e.stopPropagation()}>
                    {selectedSupplements.includes(supplement._id) &&
                      supplement.dosages &&
                      supplement.dosages.length > 0 &&
                      renderCheckboxes(supplement)}
                  </span>
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item className="flex-grow" style={{ minWidth: "10%" }}>
          <Switch
            checked={showAllData}
            className="bg-gray-300 ml-4"
            onChange={() => {
              setShowAllData(!showAllData);
              setRowToggles({});
            }}
            checkedChildren="Show All"
            unCheckedChildren="Show Filtered"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            className="flex items-center ml-3 justify-center text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-lg shadow-lg"
          >
            Search
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleDownloadPDF}
            className="flex items-center ml-3 justify-center text-white bg-green-500 hover:bg-green-600 px-4 py-2 rounded-lg shadow-lg"
          >
            Download PDF
          </Button>
        </Form.Item>
        {loader ? (
          <div className="m-2 flex items-center justify-center">
            <BarLoader color="#000" />
          </div>
        ) : (
          <Button
            type="primary"
            onClick={() => setIsModalVisible(true)}
            className="flex items-center ml-3 justify-center text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-lg shadow-lg"
          >
            Create Treatment
          </Button>
        )}

        <Form.Item
          name="isPartialMatch"
          className="flex-grow"

        >
          {" "}
          <Switch
            className="bg-gray-300"
            checkedChildren="Partial Match"
            unCheckedChildren="Exact Match"
            onChange={handleToggleChange}
            value={isPartialMatch}
          />
        </Form.Item>
        <Form.Item
          name="showOrdered"
          className="flex-grow"
          style={{ minWidth: "15%" }}
        >
          {" "}
          <Switch
            className="bg-gray-300"
            checkedChildren="Show Orders"
            unCheckedChildren="Show Orders"
            onChange={handleShowOrderedToggleChange}
            value={showOrdered}
          />
        </Form.Item>
      </Form>
      {showOrdered === false && <div className="my-4 w-full">
        <div className="flex flex-row items-center gap-3 shadow-lg py-2 px-4 bg-white rounded-lg w-fit">
          {" "}
          <Typography>Orders</Typography>
          <div className="bg-yellow-100 p-4"></div>
        </div>
      </div>}
      <div className="overflow-x-auto mt-4">
        <Spin spinning={loadingTableData}>
          <table
            ref={tableRef}
            className="table-auto w-full border-collapse rounded-lg shadow-md overflow-hidden"
          >
            <thead>
              <tr className="bg-gray-200 h-16">
                <th className="px-4 py-2 border border-gray-200 font-medium">
                  Date
                </th>
                <th className="px-10 py-2 border border-gray-200 font-medium">
                  Patient Name
                </th>
                <th className="px-4 py-2 border border-gray-200 font-medium">
                  Custom Name
                </th>
                <th className="px-4 py-2 border border-gray-200 font-medium">
                  Medicine
                </th>
              </tr>
            </thead>
            {isPartialMatch ? (
              <tbody>
                {searchResults.length > 0 &&
                  searchResults.map((item, index) => {
                    const showRowData = rowToggles[item._id] || showAllData;


                    // Filter labs based on criteria
                    const filteredLabs = item.userLabs.filter(
                      (lab) =>
                        lab.checked &&
                        (showRowData ||
                          dataSelected.labs.includes(lab.test_name))
                    );

                    // Filter procedures based on criteria
                    const filteredProcedures = item.userProcedures?.filter(
                      (procedure) =>
                        (procedure.checked === true ||
                          (procedure.selectedField &&
                            procedure.selectedField !== "")) &&
                        (showRowData ||
                          dataSelected.procedures.some(
                            (pr) =>
                              pr.name === procedure.procedure_name &&
                              (pr.dosages.length === 0 ||
                                pr.dosages.includes(procedure.selectedField))
                          ))
                    );

                    // Filter dispensed items based on criteria
                    const filteredDispensed = item.userdispensed?.filter(
                      (disp) =>
                        disp.selectedField !== "" &&
                        (showRowData ||
                          dataSelected.dispensed.some(
                            (ds) =>
                              ds.name === disp.name &&
                              (ds.dosages.length === 0 ||
                                ds.dosages.includes(disp.selectedField))
                          ))
                    );

                    // Filter supplements based on criteria
                    const filteredSupplements = item.userSupplements?.filter(
                      (supplement) =>
                        supplement.quantity > 0 &&
                        (showRowData ||
                          dataSelected.supplements.some(
                            (sp) =>
                              sp.name === supplement.name &&
                              (sp.dosages.length === 0 ||
                                sp.dosages.includes(supplement.selectedField))
                          ))
                    );

                    // Determine if all dataSelected items are present
                    // const labsMatch =
                    //   dataSelected.labs.length === 0 ||
                    //   dataSelected.labs.every((lab) =>
                    //     filteredLabs.some(
                    //       (filteredLab) => filteredLab.test_name === lab
                    //     )
                    //   );
                    // const proceduresMatch =
                    //   dataSelected.procedures.length === 0 ||
                    //   dataSelected.procedures.every((proc) =>
                    //     filteredProcedures.some(
                    //       (filteredProc) =>
                    //         filteredProc.procedure_name === proc.name &&
                    //         (proc.dosages.length === 0 ||
                    //           proc.dosages.includes(filteredProc.selectedField))
                    //     )
                    //   );
                    // const dispensedMatch =
                    //   dataSelected.dispensed.length === 0 ||
                    //   dataSelected.dispensed.every((disp) =>
                    //     filteredDispensed.some(
                    //       (filteredDisp) =>
                    //         filteredDisp.name === disp.name &&
                    //         (disp.dosages.length === 0 ||
                    //           disp.dosages.includes(filteredDisp.selectedField))
                    //     )
                    //   );
                    // const supplementsMatch =
                    //   dataSelected.supplements.length === 0 ||
                    //   dataSelected.supplements.every((supp) =>
                    //     filteredSupplements.some(
                    //       (filteredSupp) =>
                    //         filteredSupp.name === supp.name &&
                    //         (supp.dosages.length === 0 ||
                    //           supp.dosages.includes(filteredSupp.selectedField))
                    //     )
                    //   );


                    const labsMatch =
                      dataSelected.labs.length === 0 ||
                      filteredLabs.some((filteredLab) =>
                        dataSelected.labs.includes(filteredLab.test_name)
                      );

                    const proceduresMatch =
                      dataSelected.procedures.length === 0 ||
                      filteredProcedures.some((filteredProc) =>
                        dataSelected.procedures.some((proc) =>
                          proc.name === filteredProc.procedure_name &&
                          (proc.dosages.length === 0 || proc.dosages.includes(filteredProc.selectedField))
                        )
                      );

                    const dispensedMatch =
                      dataSelected.dispensed.length === 0 ||
                      filteredDispensed.some((filteredDisp) =>
                        dataSelected.dispensed.some((disp) =>
                          disp.name === filteredDisp.name &&
                          (disp.dosages.length === 0 || disp.dosages.includes(filteredDisp.selectedField))
                        )
                      );

                    const supplementsMatch =
                      dataSelected.supplements.length === 0 ||
                      filteredSupplements.some((filteredSupp) =>
                        dataSelected.supplements.some((supp) =>
                          supp.name === filteredSupp.name &&
                          (supp.dosages.length === 0 || supp.dosages.includes(filteredSupp.selectedField))
                        )
                      );

                    const showRow = labsMatch || proceduresMatch || dispensedMatch || supplementsMatch;

                    if (!showRow) {
                      return null;
                    }

                    return (
                      <>
                        {(filteredSupplements.length > 0 ||
                          filteredDispensed.length > 0 ||
                          filteredProcedures.length > 0 ||
                          filteredLabs.length > 0) && (
                            <tr
                              key={index}
                              className={`${index % 2 === 0 ? "bg-gray-100" : ""
                                } cursor-pointer ${item.type === "Order" && showOrdered === false ? "bg-yellow-100" : ""}`}
                              onClick={() => {
                                setRowToggles((prev) => ({
                                  ...prev,
                                  [item._id]: !prev[item._id],
                                }));
                              }}
                            >

                              <td className="px-4 py-2 border border-gray-200">
                                {moment.utc(item.date).format("MM/DD/YYYY")}
                              </td>
                              <td className="px-5 py-2 border border-gray-200">
                                {item.patientName || "Unknown"}
                              </td>
                              <td className="px-4 py-2 border border-gray-200">
                                {item.custom}
                              </td>
                              <td className="px-4 py-2 border w-[80%] border-gray-200">
                                <div>
                                  {filteredProcedures?.length > 0 && (
                                    <p className="mb-1">
                                      <span className="font-bold">
                                        Procedures:{" "}
                                      </span>
                                      {filteredProcedures.map((proc, idx) => (
                                        <span key={idx}>
                                          {proc.procedure_name}
                                          {proc.selectedField &&
                                            ` (${proc.selectedField})`}
                                          {idx !== filteredProcedures.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}
                                    </p>
                                  )}
                                  {filteredDispensed?.length > 0 && (
                                    <p className="mb-1">
                                      <span className="font-bold">
                                        Dispensed:{" "}
                                      </span>
                                      {filteredDispensed.map((disp, idx) => (
                                        <span key={disp._id}>
                                          {disp.name}
                                          {disp.selectedField &&
                                            ` (${disp.selectedField})`}
                                          {` x ${disp.quantity}`}
                                          {idx !== filteredDispensed.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}
                                    </p>
                                  )}
                                  {filteredLabs?.length > 0 && (
                                    <p className="mb-1">
                                      <span className="font-bold">Labs: </span>
                                      {filteredLabs.map((lab, idx) => (
                                        <span key={idx}>
                                          {lab.test_name}
                                          {idx !== filteredLabs.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}
                                    </p>
                                  )}
                                  {filteredSupplements?.length > 0 && (
                                    <p className="mb-1">
                                      <span className="font-bold">
                                        Supplements:{" "}
                                      </span>
                                      {filteredSupplements.map(
                                        (supplement, idx) => (
                                          <span key={idx}>
                                            {supplement.name}
                                            {supplement.selectedField &&
                                              ` (${supplement.selectedField})`}
                                            {` x ${supplement.quantity}`}
                                            {idx !==
                                              filteredSupplements.length - 1
                                              ? ", "
                                              : ""}
                                          </span>
                                        )
                                      )}
                                    </p>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                      </>
                    );
                  })}
              </tbody>
            ) : (
              <tbody>
                {searchResults.length > 0 &&
                  searchResults.map((item, index) => {
                    const showRowData = rowToggles[item._id] || showAllData;

                    // Filter labs based on criteria
                    const filteredLabs = item.userLabs.filter(
                      (lab) =>
                        lab.checked &&
                        (showRowData ||
                          dataSelected.labs.includes(lab.test_name))
                    );

                    // Filter procedures based on criteria
                    const filteredProcedures = item.userProcedures?.filter(
                      (procedure) =>
                        (procedure.checked === true ||
                          (procedure.selectedField &&
                            procedure.selectedField !== "")) &&
                        (showRowData ||
                          dataSelected.procedures.some(
                            (pr) =>
                              pr.name === procedure.procedure_name &&
                              (pr.dosages.length === 0 ||
                                pr.dosages.includes(procedure.selectedField))
                          ))
                    );

                    // Filter dispensed items based on criteria
                    const filteredDispensed = item.userdispensed?.filter(
                      (disp) =>
                        disp.selectedField !== "" &&
                        (showRowData ||
                          dataSelected.dispensed.some(
                            (ds) =>
                              ds.name === disp.name &&
                              (ds.dosages.length === 0 ||
                                ds.dosages.includes(disp.selectedField))
                          ))
                    );

                    // Filter supplements based on criteria
                    const filteredSupplements = item.userSupplements?.filter(
                      (supplement) =>
                        supplement.quantity > 0 &&
                        (showRowData ||
                          dataSelected.supplements.some(
                            (sp) =>
                              sp.name === supplement.name &&
                              (sp.dosages.length === 0 ||
                                sp.dosages.includes(supplement.selectedField))
                          ))
                    );

                    // Determine if all dataSelected items are present
                    const labsMatch =
                      dataSelected.labs.length === 0 ||
                      dataSelected.labs.every((lab) =>
                        filteredLabs.some(
                          (filteredLab) => filteredLab.test_name === lab
                        )
                      );
                    const proceduresMatch =
                      dataSelected.procedures.length === 0 ||
                      dataSelected.procedures.every((proc) =>
                        filteredProcedures.some(
                          (filteredProc) =>
                            filteredProc.procedure_name === proc.name &&
                            (proc.dosages.length === 0 ||
                              proc.dosages.includes(filteredProc.selectedField))
                        )
                      );
                    const dispensedMatch =
                      dataSelected.dispensed.length === 0 ||
                      dataSelected.dispensed.every((disp) =>
                        filteredDispensed.some(
                          (filteredDisp) =>
                            filteredDisp.name === disp.name &&
                            (disp.dosages.length === 0 ||
                              disp.dosages.includes(filteredDisp.selectedField))
                        )
                      );
                    const supplementsMatch =
                      dataSelected.supplements.length === 0 ||
                      dataSelected.supplements.every((supp) =>
                        filteredSupplements.some(
                          (filteredSupp) =>
                            filteredSupp.name === supp.name &&
                            (supp.dosages.length === 0 ||
                              supp.dosages.includes(filteredSupp.selectedField))
                        )
                      );

                    const showRow =
                      labsMatch &&
                      proceduresMatch &&
                      dispensedMatch &&
                      supplementsMatch;

                    if (!showRow) {
                      return null;
                    }

                    return (
                      <>
                        {(filteredSupplements.length > 0 ||
                          filteredDispensed.length > 0 ||
                          filteredProcedures.length > 0 ||
                          filteredLabs.length > 0) && (
                            <tr
                              key={index}
                              className={`${index % 2 === 0 ? "bg-gray-100" : ""
                                } cursor-pointer ${item.type === "Order" && showOrdered === false ? "bg-yellow-100" : ""}`}
                              onClick={() => {
                                setRowToggles((prev) => ({
                                  ...prev,
                                  [item._id]: !prev[item._id],
                                }));
                              }}
                            >
                              <td className="px-4 py-2 border border-gray-200">
                                {moment(item.date).format("MM/DD/YYYY")}
                              </td>
                              <td className="px-4 py-2 border border-gray-200">
                                {item.patientName || "Unknown"}
                              </td>
                              <td className="px-4 py-2 border w-[80%] border-gray-200">
                                <div>
                                  {filteredProcedures?.length > 0 && (
                                    <p className="mb-1">
                                      <span className="font-bold">
                                        Procedures:{" "}
                                      </span>
                                      {filteredProcedures.map((proc, idx) => (
                                        <span key={idx}>
                                          {proc.procedure_name}
                                          {proc.selectedField &&
                                            ` (${proc.selectedField})`}
                                          {idx !== filteredProcedures.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}
                                    </p>
                                  )}
                                  {filteredDispensed?.length > 0 && (
                                    <p className="mb-1">
                                      <span className="font-bold">
                                        Dispensed:{" "}
                                      </span>
                                      {filteredDispensed.map((disp, idx) => (
                                        <span key={disp._id}>
                                          {disp.name}
                                          {disp.selectedField &&
                                            ` (${disp.selectedField})`}
                                          {` x ${disp.quantity}`}
                                          {idx !== filteredDispensed.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}
                                    </p>
                                  )}
                                  {filteredLabs?.length > 0 && (
                                    <p className="mb-1">
                                      <span className="font-bold">Labs: </span>
                                      {filteredLabs.map((lab, idx) => (
                                        <span key={idx}>
                                          {lab.test_name}
                                          {idx !== filteredLabs.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      ))}
                                    </p>
                                  )}
                                  {filteredSupplements?.length > 0 && (
                                    <p className="mb-1">
                                      <span className="font-bold">
                                        Supplements:{" "}
                                      </span>
                                      {filteredSupplements.map(
                                        (supplement, idx) => (
                                          <span key={idx}>
                                            {supplement.name}
                                            {supplement.selectedField &&
                                              ` (${supplement.selectedField})`}
                                            {` x ${supplement.quantity}`}
                                            {idx !==
                                              filteredSupplements.length - 1
                                              ? ", "
                                              : ""}
                                          </span>
                                        )
                                      )}
                                    </p>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                      </>
                    );
                  })}
              </tbody>
            )}
          </table>

          {
            <div className="flex justify-center mt-4">
              <button
                className={`${currentPage === totalPages
                  ? " bg-gray-200"
                  : "bg-blue-500 hover:bg-blue-700"
                  }  text-white font-bold py-2 px-4 rounded`}
                onClick={() => {
                  form.submit();
                  setCurrentPage(currentPage + 1);
                }}
                disabled={currentPage === totalPages}
              >
                {currentPage === totalPages
                  ? "No more treatments found"
                  : loadingTableData
                    ? "Loading..."
                    : "See More"}
              </button>
            </div>
          }
        </Spin>
      </div>
    </div>
  );
}
