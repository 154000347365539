import React, { useState } from "react";
import { Select, Modal, message, List, Input, Button } from "antd";
import { ReloadOutlined, RightOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "./Updations.css";
import moment from "moment";
import OrderDetailsModal from './OrderDetailsModal';

function RestorePatientData({ patients, showToast }) {
  const [open, setOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orderPairs, setOrderPairs] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPair, setCurrentPair] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
    setSelectedPatient(null);
    setOrderDetails(null);
    setPassword("");
    setShowPassword(false);
    setSelectedOrders([]);
  };

  const handlePatientSelect = async (patientId) => {
    const patient = patients.find((p) => p._id === patientId);
    setSelectedPatient(patient);
    setShowPassword(false);
    setPassword("");
    setSelectedOrders([]);
    await fetchOrderDetails(patient["Customer ID"]);
  };

  const fetchOrderDetails = async (customerId) => {
    try {
      const response = await fetch(
        `/customer-orders/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setOrderDetails({
          ...data,
          orderDates: data.orderDates.map((order) => ({
            ...order,
            created_at: moment(order.created_at).format("MM/DD/YYYY"),
            closed_at: moment(order.closed_at).format("MM/DD/YYYY"),
          })),
        });
      } else {
        message.error("Failed to fetch order details.");
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
      message.error("Failed to fetch order details.");
    }
  };

  const handleOrderSelect = (orderId) => {
    const selectedIndex = selectedOrders.indexOf(orderId);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = [...selectedOrders, orderId];
    } else {
      newSelectedOrders = selectedOrders.filter((id) => id !== orderId);
    }

    setSelectedOrders(newSelectedOrders);
  };

  const handleSelectAll = () => {
    if (selectedOrders.length === orderDetails.orderDates.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(orderDetails.orderDates.map((order) => order.id));
    }
  };

  const handleRestore = async () => {
    if (!selectedPatient) {
      message.warning("Please select a patient.");
      return;
    }

    if (!showPassword) {
      setShowPassword(true);
      return;
    }

    if (!password) {
      message.warning("Please enter the password.");
      return;
    }

    if (selectedOrders.length === 0) {
      message.warning("Please select at least one order.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        "/restore-shopify-orders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify({
            customerId: selectedPatient["Customer ID"],
            password: password,
            orderIds: selectedOrders,
          }),
        }
      );

      if (response.ok) {
        message.success(
          `Patient data for '${selectedPatient["Full Name"]
          }' has been successfully migrated from Shopify.`
        );
        setOrderDetails(null);
        setPassword("");
        setShowPassword(false);
        setSelectedOrders([]);
        setOpen(false);
      } else {
        message.error("Failed to restore patient data from Shopify.");
      }
    } catch (error) {
      console.error("Error restoring patient data:", error);
      message.error("Failed to restore patient data from Shopify.");
    } finally {
      setLoading(false);
    }

  };

  const handleCancel = () => {
    setOpen(false);
    setShowPassword(false);
    setPassword("");
    setSelectedOrders([]);
  };

  const handleCompareOrders = async () => {
    if (!selectedPatient) {
      message.warning("Please select a patient.");
      return;
    }

    if (!showPassword) {
      setShowPassword(true);
      return;
    }

    if (!password) {
      message.warning("Please enter the password.");
      return;
    }

    if (selectedOrders.length === 0) {
      message.warning("Please select at least one order.");
      return;
    }

    setActionLoading(true);
    try {
      const response = await fetch("/compare-shopify-orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
        body: JSON.stringify({
          customerId: selectedPatient["Customer ID"],
          password,
          orderIds: selectedOrders,
        }),
      });

      if (response.ok) {
        const comparisonResults = await response.json();
        setOrderPairs(comparisonResults);  // Store order pairs in state
        message.success("Comparison complete. Ready to view details.");
      } else {
        message.error("Failed to compare orders.");
      }
    } catch (error) {
      console.error("Error comparing orders:", error);
      message.error("Failed to compare orders.");
    }

    setActionLoading(false);
  };


  const handleShowOrderDetails = (pair) => {
    if (pair) {
      setCurrentPair(pair);
      setIsModalVisible(true);
    } else {
      console.error('No order pair data available');
      message.error('No order pair data available.');
    }
  };

  const renderListHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          padding: '10px 16px', // Match the padding of list items for alignment
          backgroundColor: '#f0f0f0', // A light background for the header
          fontWeight: 'bold', // Make header text bold
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <p style={{ flex: 1, margin: 0, textAlign: 'left' }}>Date</p>
        <p style={{ flex: 1, margin: 0, textAlign: 'center' }}>Treatment Day Exists</p>
        <p style={{ width: '60px', textAlign: 'center' }}>Details</p>
      </div>
    );
  };

  const renderOrderItem = (order) => {
    const pairedOrder = orderPairs.find(pair => pair.shopifyOrder.id === order.id);

    // Handler to prevent the listItem click event when clicking on the icon
    const onIconClick = (e, pair) => {
      e.stopPropagation(); // Prevents the List.Item onClick event from firing
      handleShowOrderDetails(pair);
    };

    return (
      <List.Item
        key={order.id}
        onClick={() => handleOrderSelect(order.id)} // Maintain the original toggle select functionality
        style={{
          cursor: "pointer",
          backgroundColor: selectedOrders.includes(order.id) ? "#e6f7ff" : "white",
          paddingRight: '16px',
          display: 'flex', // Ensures flex layout
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <p style={{ flex: 1, margin: 0 }}>Date: {moment(order.created_at).format("MM/DD/YYYY")}</p>
        <div style={{ flex: 1, textAlign: 'center' }}>
          {order.orderExist ? <CheckOutlined style={{ color: 'green', fontSize: '16px' }} /> : <CloseOutlined style={{ color: 'red', fontSize: '16px' }} />}
        </div>
        <div style={{ width: '60px', textAlign: 'center' }}>
          {pairedOrder ? <RightOutlined onClick={(e) => onIconClick(e, pairedOrder)} style={{ color: 'green', fontSize: '16px' }} /> : <span>-</span>}
        </div>
      </List.Item>
    );
  };



  const { Option } = Select;

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        type="primary"
        onClick={showModal}
      >
        Restore Patient Data
      </button>
      <Modal
        title="Restore Patient Data"
        visible={open}
        confirmLoading={loading}

        // onOk={handleRestore}
        // okText={showPassword ? "Confirm Restore" : "Restore"}
        // okButtonProps={{
        //   disabled: showPassword && !password,
        //   style: { color: "white", backgroundColor: "#1976d2" },
        // }}

        onCancel={handleCancel}
        // cancelText="Cancel"
        // cancelButtonProps={{
        //   style: { color: "white", backgroundColor: "#1976d2" },
        // }}
        footer={[
          <Button key="back" onClick={handleCancel} style={{ color: "white", backgroundColor: "#1976d2" }} disabled={actionLoading || loading}>Cancel</Button>,
          <Button key="compare" onClick={handleCompareOrders} style={{ color: "white", backgroundColor: "#4caf50" }} disabled={actionLoading || loading || (showPassword && !password) || selectedOrders.length === 0}>Compare Orders</Button>,
          <Button key="submit" loading={loading} onClick={handleRestore} disabled={actionLoading || loading || showPassword && !password} style={{ color: "white", backgroundColor: "#1976d2" }}>{showPassword ? "Confirm Restore" : "Restore"}</Button>
        ]}
        width={'50vw'}
      >
        <Select
          showSearch
          placeholder="Select a patient"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handlePatientSelect}
          style={{ width: "100%", marginBottom: "16px" }}
          value={selectedPatient ? selectedPatient._id : undefined}
        >
          {patients.map((patient) => (
            <Option key={patient._id} value={patient._id}>
              {patient["Full Name"]}
            </Option>
          ))}
        </Select>
        {selectedPatient && (
          <Button
            icon={<ReloadOutlined />}
            onClick={() => fetchOrderDetails(selectedPatient["Customer ID"])}
            style={{ marginBottom: "16px" }}
          >
            Refresh Orders
          </Button>
        )}
        {showPassword && (
          <Input.Password
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: "16px" }}
          />
        )}
        {selectedPatient && (
          <>
            <p>
              Are you sure you want to restore patient data for '
              {selectedPatient["Full Name"]}'? This will update the patient's
              charts with all of their Shopify order data.
            </p>
            {orderDetails && (
              <div style={{ marginTop: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <p style={{ margin: 0 }}>
                    Total Orders: {orderDetails.totalOrders}
                  </p>
                  <Button onClick={handleSelectAll}>
                    {selectedOrders.length === orderDetails.orderDates.length
                      ? "Deselect All"
                      : "Select All"}
                  </Button>
                </div>
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    marginTop: "8px",
                  }}
                >
                  {renderListHeader()}
                  <List
                    bordered
                    dataSource={orderDetails.orderDates}
                    renderItem={renderOrderItem} // Use the renderOrderItem function here
                  />
                </div>
                <div>
                  <p>
                    Key:
                  </p>
                  <p>
                    select rows and press compare or restore
                  </p>
                </div>
              </div>
            )}
          </>
        )}
        {(currentPair && isModalVisible) && (
          <OrderDetailsModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            shopifyOrder={currentPair?.shopifyOrder}
            treatmentOrder={currentPair?.treatmentOrders}
            setActionLoading={setActionLoading}  // Pass setActionLoading to control loading state
            onCompare={handleCompareOrders}
          />
        )}
      </Modal>
    </>
  );
}

export default RestorePatientData;