import React, { useState, useEffect } from "react";
import { Button, Modal, Dropdown, Space, Input, Form, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";

function DeletePatient({ patients, showToast }) {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [DeletePatientOpen, setDeletePatientOpen] = useState(false);
  const [confirmDeletePatientLoading, setConfirmDeletePatientLoading] =
    useState(false);

  const showDeleteModal = () => {
    setDeletePatientOpen(true);
  };

  const handleDeleteOk = async () => {
    if (selectedPatient) {
      setConfirmDeletePatientLoading(true);
      await fetch(
        `/patient-profiles/${selectedPatient._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      showToast("Patient deleted successfully", "success");
      setDeletePatientOpen(false);
      setConfirmDeletePatientLoading(false);
      // Refresh the patient list or manage state to remove deleted patient
    }
  };

  const handleDeletePatientCancel = () => {
    
    setDeletePatientOpen(false);
  };

  return (
    <>
      <Modal
        title="Delete Patient"
        visible={DeletePatientOpen}
        onOk={handleDeleteOk}
        confirmLoading={confirmDeletePatientLoading}
        onCancel={handleDeletePatientCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Select a patient"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(value) =>
            setSelectedPatient(patients.find((p) => p._id === value))
          }
        >
          {patients.map((patient) => (
            <Select.Option key={patient._id} value={patient._id}>
              {patient["Full Name"]}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}

export default DeletePatient;
