import React, { useState } from "react";
import styles from "./styles.module.css";
import { Row, Col } from 'antd';
import logo from "../../Assets/Logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';  // Assuming you're using antd icons.

const Signup = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');

  const handleSignup = async() => {
    setError('');
    if (!userName.trim() || !email.trim() || !password.trim() || !confirmPassword.trim()) {
      return setError('All fields are required.');
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return setError('Please enter a valid email.');
    }

    if (password !== confirmPassword) {
      return setError('Passwords do not match.');
    }

    try { 
      const response = await axios.post(`/auth/signup`, {
        username: userName,
        email,
        password
      });
      
      if (response.status === 201) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/");
        }, 2000); 
      } 
    } catch (error) {
      if (error.response && error.response.status === 409){
        
        setError(error.response.data.message);
      }
      console.error("Signup Error:", error);
    }  
  };

  const handleLogin = () => { 
    navigate("/login");
  };

  return (
    <Row className={styles.signup_container} align="middle" justify="center">
      <Col xs={22} sm={16} md={12} lg={8} xl={6}>
        <div className={styles.logo_section}>
          <img className={styles.logo_image} src={logo} alt="Logo" />
        </div>
        <div className={styles.signup_box}>
          <div className={styles.signup_header}>Create a New Account</div> 

          <div className={styles.input_label}>User Name</div>
          <input 
            type="text" 
            className={styles.input_field} 
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter your user name"
          />

          <div className={styles.input_label}>Email</div>
          <input 
            type="email" 
            className={styles.input_field} 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
          />

          <div className={styles.input_label}>Password</div>
          <div className={styles.input_wrapper}>
            <input 
              type={showPassword ? "text" : "password"}
              className={styles.input_field} 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Choose a password"
            />
            <span className={styles.eye_icon} onClick={() => setShowPassword(prevState => !prevState)}>
              {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </span>
          </div>

          <div className={styles.input_label}>Confirm Password</div>
          <div className={styles.input_wrapper}>
            <input 
              type={showConfirmPassword ? "text" : "password"} 
              className={styles.input_field} 
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your password"
            />
            <span className={styles.eye_icon} onClick={() => setShowConfirmPassword(prevState => !prevState)}>
              {showConfirmPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </span>
          </div>

          {error && <div className={styles.error_message}>{error}</div>}


          <div className={styles.signup_button} onClick={handleSignup}>Sign Up</div>

          <div className={styles.existing_account}>
            Already have an account? <span className={styles.login_redirect} onClick={handleLogin}>Log In</span>
          </div>
        </div>
      </Col>
     <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </Row>
  );
};

export default Signup;