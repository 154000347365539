import ControlPointIcon from "@mui/icons-material/ControlPoint";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { IconButton } from "@mui/material";

export default function PatientSummaryView() {
  return (
    <div className="flex flex-wrap -mx-2 p-6">
      <div className="w-full px-2 sm:w-1/2 md:w-1/3">
        <div className="mb-4 p-4 h-64 w-full text-gray-700 bg-white shadow-md bg-clip-border rounded-xl">
          <div className="flex items-center">
            <h5 className="block text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
              Patient Risk Score
            </h5>
            <IconButton>
              <ControlPointIcon />
            </IconButton>
            <IconButton>
              <LocalPrintshopIcon />
            </IconButton>
          </div>
        </div>

        <div className="p-4 h-80 w-full text-gray-700 bg-white shadow-md bg-clip-border rounded-xl">
          <div className="flex items-center">
            <h5 className="block text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
              Family Health History
            </h5>
            <IconButton>
              <ControlPointIcon />
            </IconButton>
            <IconButton>
              <LocalPrintshopIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <div className="w-full px-2 sm:w-1/2 md:w-1/3">
        <div className="mb-4 p-4 h-80 w-full text-gray-700 bg-white shadow-md bg-clip-border rounded-xl">
          <div className="flex items-center">
            <h5 className="block text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
              Allergies
            </h5>
            <IconButton>
              <ControlPointIcon />
            </IconButton>
            <IconButton>
              <LocalPrintshopIcon />
            </IconButton>
          </div>
        </div>
        <div className="mb-4 p-4 h-80 w-full text-gray-700 bg-white shadow-md bg-clip-border rounded-xl">
          <div className="flex items-center">
            <h5 className="block text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
              Social History
            </h5>
            <IconButton>
              <LocalPrintshopIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <div className="w-full px-2 sm:w-1/2 md:w-1/3">
        <div className="mb-4 p-4 h-48 w-full text-gray-700 bg-white shadow-md bg-clip-border rounded-xl">
          <div className="flex items-center">
            <h5 className="block text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
              Health Concerns
            </h5>
            <IconButton>
              <ControlPointIcon />
            </IconButton>
            <IconButton>
              <LocalPrintshopIcon />
            </IconButton>
          </div>
        </div>
        <div className="p-4 h-96 w-full text-gray-700 bg-white shadow-md bg-clip-border rounded-xl">
          <div className="flex items-center">
            <h5 className="block text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
              Encounters
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
