import { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, DatePicker, Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css";
import PatientDataTable from "../MaterialTable";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const TestingPage = () => {
  const [tableData, setTableData] = useState([]);
  const [patientID, setPatientID] = useState();
  const [activePatient, setActivePatient] = useState([]);
  const navigate = useNavigate();
  const [inactivePatients, setInactivePatients] = useState([]);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState({
    G6PD: false,
    Note: "",
    Allergies: "",
    DOB: null, // or new Date().toISOString() for current date
  });
  const [showInactivePatients, setShowInactivePatients] = useState(false);
  //Patient Functionality
  
  const GetActivePatientProfiles = async () => {
    try {
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setActivePatient(response.data);
        setTableData([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        
      }
      if (error.response && error.response.status === 409) {
        
      }
      console.error("Signup Error:", error);
    }
  };

  useEffect(() => {
    GetActivePatientProfiles();
  }, []);

  const getInactivePatientProfiles = async () => {
    try {
      const response = await axios.get(
        `/patient-profiles/inActive-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setInactivePatients(response.data);
      }
    } catch (error) {
      console.error("Error fetching inactive patients:", error);
    }
  };

  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    setShowInactivePatients(isChecked);

    if (isChecked && inactivePatients.length === 0) {
      await getInactivePatientProfiles();
    }
  };

  const handleG6PDChange = (event) => {
    const newG6PDValue = event.target.checked;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, G6PD: newG6PDValue };
      // Update the patient profile after setting the new state
      updatePatientProfile(prevDetails._id, updatedDetails);
      return updatedDetails;
    });
  };

  const handleTxNotesChange = (event) => {
    const newTxNotes = event.target.value;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, Note: newTxNotes };
      updatePatientProfile(prevDetails._id, { Note: newTxNotes });
      return updatedDetails;
    });
  };

  const handleAllergiesChange = (event) => {
    const newAllergies = event.target.value;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, Allergies: newAllergies };
      updatePatientProfile(prevDetails._id, { Allergies: newAllergies });
      return updatedDetails;
    });
  };

  const handleDateChange = (date, dateString) => {
    const newDOB = date.toISOString();
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, DOB: newDOB };
      updatePatientProfile(prevDetails._id, { DOB: newDOB });
      return updatedDetails;
    });
  };

  const updatePatientProfile = async (patientId, updatedDetails) => {
    try {
      const response = await fetch(
        `/patient-profiles/${patientId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify(updatedDetails),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // Handle successful update here
      
    } catch (error) {
      console.error("Error updating patient profile:", error);
    }
  };

  const handlePatientSelect = async (selectedValue) => {
    setPatientID(selectedValue);
    try {
      const response = await axios.get(
        `/patient/new/${selectedValue},`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Data Fetched");
        setTableData(response.data);

        const selectedPatient = [...activePatient, ...inactivePatients].find(
          (patient) => patient["Customer ID"] === selectedValue
        );

        setSelectedPatientDetails(selectedPatient);
      } else {
        setTableData([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        
      }
      if (error.response && error.response.status === 409) {
        
      }
      console.error("Error fetching patient order1:", error);
      toast.error("No patient data");
      setTableData([]);
    }
  };

  function addNewOrder(type) {
    const url = `/patient/order/new/${patientID}/${type.type}`;
    axios
      .post(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      })
      .then((response) => {
        
        setTableData([...tableData, response.data]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <div>
        <div className={styles.container}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          ></ToastContainer>

          <div className={styles.patientInfo}>
            <div className={styles.patientSelectContainer}>
              <h3>Patient Name</h3>

              <Select
                placeholder="Select a patient"
                style={{ width: 250 }}
                onChange={handlePatientSelect}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {activePatient.map((patient) => (
                  <Option key={patient._id} value={patient["Customer ID"]}>
                    {patient["Full Name"]}
                  </Option>
                ))}

                {showInactivePatients &&
                  inactivePatients.map((patient) => (
                    <Option key={patient._id} value={patient["Customer ID"]}>
                      {patient["Full Name"]}
                    </Option>
                  ))}
              </Select>

              <div className={styles.InactiveCheckContainer}>
                <input
                  type="checkbox"
                  id="inactiveCheckbox"
                  checked={showInactivePatients}
                  onChange={handleCheckboxChange}
                  className={styles.InactiveCheck}
                />
                <label className={styles.InactiveCheckHeading}>
                  Show Inactive Patient
                </label>
              </div>
            </div>

            <div className={styles.patientDetailsContainer}>
              <h3>Patient Information</h3>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <div className={styles.editableField}>
                    <label className={styles.checkboxLabel}>
                      <strong>G6PD:</strong>
                      <input
                        type="checkbox"
                        checked={selectedPatientDetails.G6PD || false}
                        onChange={handleG6PDChange} // Define this function to handle the change
                      />
                    </label>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className={styles.editableField}>
                    <strong>Tx Notes:</strong>
                    <input
                      type="text"
                      defaultValue={selectedPatientDetails.Note || ""}
                      onBlur={handleTxNotesChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <div className={styles.editableField}>
                    <strong>Allergies:</strong>
                    <input
                      type="text"
                      defaultValue={selectedPatientDetails.Allergies}
                      onBlur={handleAllergiesChange} // Define this function to handle the change
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className={styles.editableField}>
                    <strong>DOB:</strong>
                    <DatePicker
                      key={selectedPatientDetails.DOB}
                      format="MM-DD-YYYY"
                      defaultValue={
                        selectedPatientDetails.DOB
                          ? moment(selectedPatientDetails.DOB)
                          : undefined
                      }
                      onChange={handleDateChange}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <h2 className={styles.tableContainerHeader}>Treatment View</h2>
            <div class="button-container">
              <Button
                variant="contained"
                onClick={() => addNewOrder({ type: "Order" })}
              >
                Add new Order
              </Button>

              <Button
                variant="contained"
                onClick={() => addNewOrder({ type: "Treatment" })}
              >
                Add new Treatment
              </Button>
            </div>
            <PatientDataTable data={tableData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TestingPage;
