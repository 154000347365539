import React, { useState, useEffect } from "react";
import { Button, Modal, Select } from "antd";
import axios from "axios";

const { Option } = Select;

function DeleteRoom({ showToast }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [room, setroom] = useState([]);
  const [selectedroom, setSelectedroom] = useState("");

  function ShowRoom() {
    axios
      .get("/get-room-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setroom(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the lab rooms", error);
      });
  }

  const showModal = () => {
    ShowRoom();
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    axios
      .delete(`/delete-room-option`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Add any other headers you need here
        },
        data: {
          roomOption: selectedroom,
        },
      })
      .then((response) => {
        showToast("Lab room deleted successfully", "success");
        
        setConfirmLoading(false);
        setOpen(false);
        // Optionally refresh the list of lab rooms
      })
      .catch((error) => {
        console.error("There was an error deleting the lab room", error);
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    
    setOpen(false);
  };

  const handleroomChange = (value) => {
    
    setSelectedroom(value);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Delete Room Option
      </button>
      <Modal
        title="Delete Room"
        visible={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        Delete
        Room
      >
        <Select
          onChange={handleroomChange}
          value={selectedroom}
          style={{ width: "100%" }}
        >
          {room.map((room) => (
            <Option key={room} value={room}>
              {room}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}

export default DeleteRoom;
