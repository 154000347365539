import React, { useState, useEffect } from "react";
import { Button, Modal, Select, Form, message } from "antd";
import axios from "axios";

function DeleteSupplement({ showToast }) {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [supplements, setSupplements] = useState([]);
  const [selectedSupplement, setSelectedSupplement] = useState(null);

  useEffect(() => {
    fetchSupplements();
  }, []);

  const fetchSupplements = async () => {
    try {
      const response = await axios.get(
        "/getAllSupplements",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      );
      setSupplements(response.data);
    } catch (error) {
      message.error("Failed to fetch supplements");
      console.error("Error fetching supplements:", error);
    }
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    axios
      .delete("/remove-supplement", {
        data: { supplementName: selectedSupplement },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Add any other headers you need here
        },
      })
      .then((response) => {
        showToast("Supplement deleted successfully", "success");
        
        setIsOpen(false);
        setConfirmLoading(false);
        setSelectedSupplement(null);
      })
      .catch((error) => {
        console.error("Error deleting supplement:", error);
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    
    setIsOpen(false);
  };

  const handleSelectChange = (value) => {
    setSelectedSupplement(value);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Delete Supplement
      </button>
      <Modal
        title="Delete Supplement"
        visible={isOpen}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form name="deleteSupplementForm" initialValues={{ remember: true }}>
          <Form.Item
            name="supplementSelect"
            rules={[{ required: true, message: "Please select a supplement!" }]}
          >
            <Select
              placeholder="Select a supplement"
              onChange={handleSelectChange}
              allowClear
            >
              {supplements.map((supplement) => (
                <Select.Option
                  key={supplement._id}
                  value={supplement.supplement_name}
                >
                  {supplement.supplement_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default DeleteSupplement;
