import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import DragAndDropList from "./DragAndDropList";
import axios from "axios";

export const SortDispenses = ({ showToast }) => {
  const [Open, setOpen] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [dispensed, setDispensed] = useState([]);
  const fetchDispensed = () => {
    axios
      .get("/allDispensed", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        
        setDispensed(response.data.allDispensed);
      })
      .catch((error) => {
        console.error("There was an error fetching the lab tests", error);
      });
  };

  const saveDispensedIndexes = () => {
    axios
      .post(
        "/saveDispensedIndexes",
        {
          dispensed: dispensed,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      )
      .then((response) => {
        showToast("Saved", "success");
      })
      .catch((error) => {
        
        showToast("Something went wrong", "error");
      })
      .finally(() => {
        setConfirmLoading(false);
        setOpen(false);
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setConfirmLoading(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    saveDispensedIndexes();
  };

  useEffect(() => {
    fetchDispensed();
  }, []);
  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Sort Dispenses
      </button>
      <Modal
        title="Sort Dispenses"
        open={Open}
        onOk={handleOk}
        okText={"Save"}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        styles={{
          body: {
            maxHeight: "700px",
            overflowY: "scroll",
          },
        }}
      >
        <DragAndDropList
          data={dispensed}
          setParentItems={setDispensed}
          primaryKey={"name"}
        />
      </Modal>
    </>
  );
};
