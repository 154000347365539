import React, { useState } from "react";
import { Button, Modal, Input, Form, Row, Col } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import "./Updations.css";

function AddProcedure({ showToast }) {
  const [form] = Form.useForm();
  const [addProcedureOpen, setAddProcedureOpen] = useState(false);
  const [confirmAddProcedureLoading, setConfirmAddProcedureLoading] =
    useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [procedureName, setProcedureName] = useState("");
  const [noteChecked, setNoteChecked] = useState(false);

  const showAddProcedureModal = () => {
    setAddProcedureOpen(true);
  };

  const handleAddProcedureOk = async () => {
    setConfirmAddProcedureLoading(true);
    const filledDosages = inputFields.filter(
      (field) => field.trim().length > 0
    );

    const procedureData = {
      procedure_name: procedureName,
      note: noteChecked ? "" : "",
      dosages: filledDosages,
      checked: false,
    };

    await addProcedureToAllOrders(procedureData);
    setAddProcedureOpen(false);
    setConfirmAddProcedureLoading(false);
  };

  const handleAddProcedureCancel = () => {
    form.resetFields();
    setProcedureName("");
    setInputFields([]);
    setNoteChecked(false);
    setAddProcedureOpen(false);
  };

  const addInputField = () => {
    setInputFields([...inputFields, ""]);
  };

  const handleInputChange = (e, index) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields[index] = e.target.value;
    setInputFields(updatedInputFields);
  };

  const removeInputField = (index) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields.splice(index, 1);
    setInputFields(updatedInputFields);
  };

  const addProcedureToAllOrders = async (procedureData) => {
    try {
      const response = await axios.post(
        "/addProcedureToAllOrders",
        procedureData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      );
      showToast("Procedure added successfully", "success");
      setProcedureName("");
      form.resetFields();

      setInputFields([]);
      setNoteChecked(false);
      setAddProcedureOpen(false);
      
    } catch (error) {
      console.error("Error adding procedure to orders:", error);
    } finally {
      setConfirmAddProcedureLoading(false);
    }
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showAddProcedureModal}
      >
        Add Procedure
      </button>
      <Modal
        title="Add Procedure"
        visible={addProcedureOpen}
        onOk={handleAddProcedureOk}
        confirmLoading={confirmAddProcedureLoading}
        onCancel={handleAddProcedureCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form name="procedureForm" form={form}>
          <Form.Item
            name="procedureName"
            rules={[{ required: true, message: "Please enter Procedure Name" }]}
          >
            <Input
              key={
                addProcedureOpen
                  ? "procedureNameInput"
                  : "procedureNameInputReset"
              }
              placeholder="Procedure Name"
              onChange={(e) => setProcedureName(e.target.value)}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="noteChecked">
                <label>
                  <input
                    type="checkbox"
                    checked={noteChecked}
                    onChange={(e) => setNoteChecked(e.target.checked)}
                  />
                  Note
                </label>
              </Form.Item>
            </Col>
          </Row>
          <div className="dosage-container">
            <button
              style={{
                color: "white",
                backgroundColor: "#1976d2",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
              className="py-1 px-10 w-full md:w-auto"
              type="primary"
              onClick={addInputField}
            >
              Add Dosage
            </button>
            <div
              className="dosage-list"
              style={{ maxHeight: "200px", overflowY: "auto" }}
            >
              {inputFields.map((field, index) => (
                <Row gutter={16} className="dosage-field" key={index}>
                  <Col span={20}>
                    <Input
                      value={field}
                      onChange={(e) => handleInputChange(e, index)}
                      style={{ marginRight: "10px" }}
                    />
                  </Col>
                  <Col span={4}>
                    <button
                      className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                      type="danger"
                      onClick={() => removeInputField(index)}
                    >
                      <DeleteTwoTone style={{ fontSize: "20px" }} />
                    </button>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default AddProcedure;
