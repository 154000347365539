// CustomToast.jsx
import React from "react";
import styles from "./styles.module.css";

const CustomToast = ({ variant, message, customStyles }) => {
  const toastClassName = `${styles.customToast} ${styles[variant]}`;

  return (
    <div className={toastClassName} style={customStyles}>
      <span>{message}</span>
    </div>
  );
};

export default CustomToast;
