import React, { useState } from "react";
import { Button, Modal, Input, Form, Row, Col, message } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import "./Updations.css";

function AddDispenses({ showToast }) {
  const [form] = Form.useForm();
  const [isAddDispensesOpen, setIsAddDispensesOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputFields, setInputFields] = useState([]);

  const showAddDispensesModal = () => {
    setIsAddDispensesOpen(true);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const filledDosages = inputFields.filter(
      (field) => field.trim().length > 0
    );
    const procedureData = {
      ...values,
      dosages: filledDosages,
      note: "",
      quantity: 0,
      selectedField: "",
    };

    try {
      const response = await axios.post(
        "/addDispensedToAllOrders",
        procedureData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Add any other headers you need here
          },
        }
      );
      // message.success("Dispenses added successfully");
      showToast("Dispenses added successfully", "success");
      form.resetFields();
      setInputFields([]);
      setIsAddDispensesOpen(false);
    } catch (error) {
      message.error("Failed to add dispenses");
      console.error("Error adding dispenses:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setInputFields([]);
    setIsAddDispensesOpen(false);
  };

  const addInputField = () => {
    setInputFields([...inputFields, ""]);
  };

  const handleInputChange = (value, index) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields[index] = value;
    setInputFields(updatedInputFields);
  };

  const removeInputField = (index) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields.splice(index, 1);
    setInputFields(updatedInputFields);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showAddDispensesModal}
      >
        Add Dispenses
      </button>
      <Modal
        title="Add Dispenses"
        visible={isAddDispensesOpen}
        confirmLoading={isSubmitting}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        footer={[
          <button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="py-1 px-10 w-full md:w-auto"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="ml-3 py-1 px-10 w-full md:w-auto"
            key="submit"
            type="primary"
            loading={isSubmitting}
            form="dispenseForm"
            htmlType="submit"
          >
            {isSubmitting ? "Adding..." : "Add"}
          </button>,
        ]}
      >
        <Form
          id="dispenseForm"
          form={form}
          name="dispenseForm"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <div className="dosage-container">
            <button
              style={{
                color: "white",
                backgroundColor: "#1976d2",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
              className="py-1 px-10 w-full md:w-auto"
              type="button"
              onClick={addInputField}
            >
              Add Dosage
            </button>
            <div
              className="dosage-list"
              style={{ maxHeight: "200px", overflowY: "auto" }}
            >
              {inputFields.map((field, index) => (
                <Row gutter={16} className="dosage-field" key={index}>
                  <Col span={20}>
                    <Input
                      value={field}
                      onChange={(e) => handleInputChange(e.target.value, index)}
                      style={{ marginRight: "10px" }}
                    />
                  </Col>
                  <Col span={4}>
                    <button
                      className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                      type="danger"
                      onClick={() => removeInputField(index)}
                    >
                      <DeleteTwoTone style={{ fontSize: "20px" }} />
                    </button>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default AddDispenses;
