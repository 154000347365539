import React from "react";

const Round = ({ color }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 5 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill={color} />
    </svg>
  );
};

export default Round;
