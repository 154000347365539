import React, { useState, useEffect } from 'react';
import { Modal, Select, Button, Form, Spin, List, Row, Col, Divider } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import axios from 'axios';

const { Option } = Select;

function MergeTemplatesModal({ orderId, visible, onClose }) {
    const [categories, setCategories] = useState([]);
    const [templates, setTemplates] = useState({
        procedures: [{ category: null, subCategories: [], selectedSubCategoryId: null, items: [], templateId: null }],
        dispensed: [{ category: null, subCategories: [], selectedSubCategoryId: null, items: [], templateId: null }],
        labs: [{ category: null, subCategories: [], selectedSubCategoryId: null, items: [], templateId: null }],
        supplements: [{ category: null, subCategories: [], selectedSubCategoryId: null, items: [], templateId: null }]
    });

    const [isLoading, setIsLoading] = useState(false);
    const [activeTypes, setActiveTypes] = useState({ procedures: false, dispensed: false, labs: false, supplements: false });

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        const response = await axios.get('/categories');
        setCategories(response.data.data);
    };

    const fetchSubCategories = async (type, categoryId, index) => {
        const response = await axios.get(`/subcategories/${categoryId}`);
        setTemplates(prev => {
            const updated = [...prev[type]];
            updated[index].subCategories = response.data.data;
            return { ...prev, [type]: updated };
        });
    };

    const fetchTemplateItems = async (type, subCategoryId, index) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/templates-by-subcategory/${subCategoryId}`);
            setTemplates(prev => {
                const updated = [...prev[type]];
                if (response.data.success && response.data.templates.length > 0) {
                    const templateData = response.data.templates[0];
                    updated[index] = {
                        ...updated[index],
                        items: templateData[type] || [],
                        templateId: templateData._id
                    };
                }
                return { ...prev, [type]: updated };
            });
        } catch (error) {
            console.error('Error fetching template items:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCategoryChange = (type, value, index) => {
        fetchSubCategories(type, value, index);
        setTemplates(prev => {
            const updated = [...prev[type]];
            updated[index] = { ...updated[index], category: value, subCategories: [], items: [] }; // Reset subcategories and items on category change
            return { ...prev, [type]: updated };
        });
    };

    const handleSubCategoryChange = (type, value, index) => {
        fetchTemplateItems(type, value, index);
        setTemplates(prev => {
            const updated = [...prev[type]];
            updated[index].selectedSubCategoryId = value; // Update the selected subcategory ID
            return { ...prev, [type]: updated };
        });
    };

    const addTemplateField = (type) => {
        setTemplates(prev => {
            const updated = [...prev[type], { category: null, subCategories: [], items: [], templateId: null }];
            return { ...prev, [type]: updated };
        });
    };

    const removeTemplateField = (type, index) => {
        setTemplates(prev => {
            const updated = [...prev[type]];
            updated.splice(index, 1); // Removes the template and shifts others up
            return { ...prev, [type]: updated };
        });
    };

    useEffect(() => {
        console.log("templates", templates);
    }, [templates]);

    const handleMerge = async () => {
        const mergedData = {
            procTempIds: templates.procedures.map(t => t.templateId),
            dispTempIds: templates.dispensed.map(t => t.templateId),
            labsTempIds: templates.labs.map(t => t.templateId),
            supsTempIds: templates.supplements.map(t => t.templateId),
        };

        try {
            await axios.post(`/merge-treatment/${orderId}`, mergedData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
                }
            });
            onClose();
        } catch (error) {
            console.error('Merge error:', error);
        }
    };

    const toggleDetails = (type) => {
        setActiveTypes(prev => ({ ...prev, [type]: !prev[type] }));
    };

    const renderDetails = (type) => {
        const combinedItems = templates[type].flatMap(t => t.items);
        const uniqueItems = {};
        combinedItems.forEach(item => {
            if (!uniqueItems[item.name]) {
                uniqueItems[item.name] = item;
            }
        });
        const detailItems = Object.values(uniqueItems);

        return (
            <List
                dataSource={detailItems}
                renderItem={item => (
                    <List.Item key={item._id}>
                        <List.Item.Meta
                            title={<div style={{ textAlign: 'left' }}>{item.name || item.test_name}</div>}
                            description={
                                <>
                                    {type === 'labs' ? (
                                        <div style={{ textAlign: 'left' }}>{item}</div>
                                    ) : (
                                        item.dosages && item.dosages.map((dosage, index) => {
                                            const isObject = typeof dosage === 'object' && dosage !== null;
                                            return (
                                                <div key={index} style={{ textAlign: 'left' }}>
                                                    {isObject ? (
                                                        `Dosage: ${dosage.dosage}${dosage.quantity ? `, Quantity: ${dosage.quantity}` : ''}`
                                                    ) : (
                                                        `Dosage: ${dosage}`
                                                    )}
                                                </div>
                                            );
                                        })
                                    )}
                                    {type === 'supplements' && item.quantity !== undefined && (
                                        <div style={{ textAlign: 'left', marginTop: 5 }}>
                                            Quantity: {item.quantity}
                                        </div>
                                    )}
                                </>
                            }
                        />
                    </List.Item>
                )}
            />
        );
    };

    const renderTemplateSelection = (type) => (
        <div>
            {templates[type].map((template, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                    <Select
                        placeholder="Select Category"
                        style={{ width: 200, marginRight: 8 }}
                        onChange={value => handleCategoryChange(type, value, index)}
                        value={template.category}
                    >
                        {categories.map(cat => (
                            <Option key={cat._id} value={cat._id}>{cat.name}</Option>
                        ))}
                    </Select>
                    <Select
                        placeholder="Select Subcategory"
                        style={{ width: 200, marginRight: 8 }}
                        onChange={value => handleSubCategoryChange(type, value, index)}
                        value={template.selectedSubCategoryId} // Use the new field for selection
                    >
                        {template.subCategories.map(sub => (
                            <Option key={sub._id} value={sub._id}>{sub.name}</Option>
                        ))}
                    </Select>
                    {index === 0 && (
                        <PlusCircleOutlined onClick={() => addTemplateField(type)} style={{ marginRight: 8, color: 'green' }} />
                    )}
                    {index > 0 && (
                        <MinusCircleOutlined onClick={() => removeTemplateField(type, index)} style={{ color: 'red', marginRight: 8 }} />
                    )}
                </div>
            ))}
            <Button type="link" onClick={() => toggleDetails(type)}>
                {activeTypes[type] ? <UpOutlined /> : <DownOutlined />}
            </Button>
            {activeTypes[type] && renderDetails(type)}
        </div>
    );

    return (
        <>
            <Modal
                title="Merge Templates"
                visible={visible}
                onOk={handleMerge}
                onCancel={onClose}
                width={1500} // Adjust the width for better display
                footer={[
                    <Button key="back" onClick={onClose}>Cancel</Button>,
                    <Button key="submit" type="primary" onClick={handleMerge} style={{ backgroundColor: '#31a43a', borderColor: '#31a43a' }}>Merge Templates</Button>
                ]}
                className={styles.modalContent}
            >
                <Spin spinning={isLoading}>
                    <Form layout="vertical">
                        <Row gutter={[16, 16]}>
                            {['procedures', 'dispensed', 'labs', 'supplements'].map((type, index) => (
                                <React.Fragment key={type}>
                                    <Col xs={24} sm={12} lg={6}>
                                        <Form.Item label={type[0].toUpperCase() + type.slice(1)}>
                                            {renderTemplateSelection(type)}
                                        </Form.Item>
                                    </Col> 
                                </React.Fragment>
                            ))}
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

export default MergeTemplatesModal;
