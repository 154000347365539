import React, { useState, useEffect } from "react";
import { Modal, Switch, Button, message, Radio } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import axios from "axios";
import AddUserEmail from './addUserEmail';
import AddLocalUser from './addLocalUser';
import { Box, Paper } from "@mui/material";
import { red } from "@mui/material/colors";


const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userFilter, setUserFilter] = useState("gmail");
  const [showAddUserEmailModal, setShowAddUserEmailModal] = useState(false);
  const [showAddLocalUserModal, setShowAddLocalUserModal] = useState(false);

  const fetchData = async () => {
    try {
      const usersResponse = await axios.get("/auth/users");
      setUsers(usersResponse.data);
      const emailsResponse = await axios.get("/get-all-emails", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });
      setEmails(emailsResponse.data);
    } catch (error) {
      console.error("There was an error fetching the data", error);
      message.error("Failed to fetch data");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTypeChange = (userId, checked) => {
    const updatedType = checked ? "admin" : "user";
    axios
      .put(`/auth/users/${userId}`, { type: updatedType }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        }
      })
      .then(() => {
        message.success("User type updated successfully");
        setUsers(
          users.map((user) =>
            user._id === userId ? { ...user, type: updatedType } : user
          )
        );
      })
      .catch((error) => {
        message.error("Error updating user type");
        console.error("There was an error updating the user type", error);
      });
  };

  const handleDelete = async (email) => {
    try {
      await axios.delete("/delete-email", {
        data: { email: email },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });
      message.success("Email deleted successfully");
      fetchData()
      setUsers(users.filter(user => user.email !== email));
    } catch (error) {
      message.error("Failed to delete email");
      console.error("There was an error deleting the email", error);
    }
  };

  const handleAddUser = () => {
    if (userFilter === "gmail") {
      setShowAddUserEmailModal(true);
    } else {
      setShowAddLocalUserModal(true);
    }
  };

  const filteredUsers = userFilter === "gmail"
    ? emails
    : users.filter(user => !emails.includes(user.email) && (user.username && user.password));

  return (
    <>
      <button className="btn-color-NoBg mr-4 text-xs md:text-sm w-full md:w-auto" type="primary" onClick={showModal}>
        Manage Accounts
      </button>
      <Modal
        title="User Management"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}

        footer={[
          <Button
            className="bg-[#007bff]"
            type="primary"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            className="bg-[#007bff]"
            type="primary"
            key="submit"
            onClick={handleOk}
          >
            OK
          </Button>,
          <Button
            className="bg-[#007bff]"
            type="primary"
            key="add"
            onClick={handleAddUser}
          >
            Add User
          </Button>
        ]}
      >
        <div style={{ margin: "0px auto", width: 'fit-content', marginTop: 5 }}>
          <Radio.Group value={userFilter} buttonStyle="solid" onChange={e => setUserFilter(e.target.value)} style={{ marginBottom: 16 }} className="shadow-lg ">
            <Radio.Button value="gmail">Gmail Account</Radio.Button>
            <Radio.Button value="local">Local User</Radio.Button>
          </Radio.Group>
        </div>

        <div
          className=" max-h-[400px] overflow-y-auto py-3">
          <div className="flex flex-col gap-3 mx-3">
            {filteredUsers.map((user) => (
              <Box elevation={2} component={Paper} sx={{
                px: 3, py: 1
              }}>
                <div div key={user._id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }} >
                  {userFilter === "gmail" ? <span>{user}</span> : <span>{user.username}</span>}

                  <div className="flex items-center">
                    {userFilter !== 'gmail' &&
                      <Switch
                        checkedChildren="Admin"
                        unCheckedChildren="User"
                        checked={user.type === "admin"}
                        onChange={(checked) => handleTypeChange(user._id, checked)}
                        style={{
                          background: user.type !== "admin" ? "rgba(0, 0, 0, 0.45)" : ""
                        }}
                      />
                    }
                    {userFilter === "gmail" && (
                      <DeleteOutlined style={{ color: red[400], marginLeft: '10px', fontSize: '18px', cursor: 'pointer' }} onClick={() => handleDelete(user)} />
                    )}
                  </div>
                </div>
              </Box>
            ))}
          </div>
        </div >

        <AddUserEmail isModalVisible={showAddUserEmailModal} setIsModalVisible={setShowAddUserEmailModal} handleRefresh={fetchData} />
        <AddLocalUser isModalVisible={showAddLocalUserModal} setIsModalVisible={setShowAddLocalUserModal} handleRefresh={fetchData} />
      </Modal >
    </>
  );
};

export default UserManagement;
