import React, { useEffect, useState } from "react";
import { Modal, Button, Input, message, Checkbox, Form } from "antd";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import CustomToast from "../CustomToast";

const AddUserEmail = ({ isModalVisible, setIsModalVisible, handleRefresh }) => {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [errors, setErrors] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [localLogin, setLocalLogin] = useState(false);
  const [error, setError] = useState("");

  const handleOk = async () => {
    const newErrors = {}
    if (!email || email.trim() === "") {
      if (localLogin) {
        newErrors.email = "Please enter a valid email";
      }
      else {
        message.error("Email field can't be empty");
        return;
      }
    }

    if (localLogin) {
      if (!password.trim()) {
        newErrors.password = "Password is required."
      }
      if (!confirmPassword.trim()) {
        newErrors.confirmPassword = "Confirm Password is required."
      }

      if (password !== confirmPassword) {
        newErrors.matchPassword = "Both the password doesn't match"

      }

    }
    setErrors(newErrors)

    if (Object.keys(newErrors).length === 0) {
      if (email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
          if (localLogin) {
            newErrors.email = "Please enter a valid email";
          }
          else {
            message.error("Please enter a valid email");
            return;
          }
        }
      }
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        };

        await axios.post(
          "/add-email",
          { email },
          { headers }
        );
        // message.success("Email added successfully");
        if (!localLogin) {
          toast.success("Email added successfully");
        }
        if (localLogin) {
          handleSignupLocalLogin();
        }
        setEmail("");
        handleRefresh();
        setIsModalVisible(false);
      } catch (error) {
        message.error("Failed to add email");
        toast.error("Failed to add email");
        setIsModalVisible(false);
      }
    }

  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEmail("");
    setUserName("")
    setPassword("")
    setConfirmPassword("")
    setErrors('')
  };

  const onChangeLocalLogin = (e) => {
    console.log('checked = ', e.target.checked);
    setLocalLogin(e.target.checked);
    if (e.target.checked === false) {
      setErrors("")
      setUserName("")
      setPassword('')
      setConfirmPassword('')
    }
  };


  const handleSignupLocalLogin = async () => {


    try {
      const response = await axios.post(`/auth/signup`, {
        username: email,
        email,
        password,
      });

      toast.success("User Added");
      setUserName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      if (error.response && error.response.status === 409) {

        setError(error.response.data.message);
      }
      toast.error("SignUp Error");
      console.error("Signup Error:", error);
    }

  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        title={"Add Gmail Account"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Checkbox checked={localLogin} onChange={onChangeLocalLogin}>
              Add Local Login
            </Checkbox>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <div>Email</div>
        <Form.Item validateStatus={errors.email ? 'error' : ''}
          help={errors.email || null}
          style={{ marginBottom: errors.email ? '24px' : '0px' }}

        >
          <Input
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>

        {localLogin &&
          <>
            <div>Username</div>
            <Form.Item validateStatus={errors.userName ? 'error' : ''}
              help={errors.userName || null}
              style={{ marginBottom: errors.userName ? '24px' : '0px' }}

            >
              <Input
                type="text"
                value={email}
                disabled={localLogin}
              />
            </Form.Item>
            <div>Password</div>

            <div>
              <Form.Item validateStatus={errors.password ? 'error' : ''}
                help={errors.password || null}
                style={{ marginBottom: errors.password ? '24px' : '0px' }}
              >
                <div className="flex flex-row items-center gap-2">
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Choose a password"
                  />
                  <span onClick={() => setShowPassword((prevState) => !prevState)}>
                    {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                  </span>
                </div>
              </Form.Item>
            </div>

            <div>Confirm Password</div>
            <div>
              <Form.Item validateStatus={errors.confirmPassword ? 'error' : errors.matchPassword ? 'error' : ''}
                help={errors.confirmPassword || errors.matchPassword || null}
                style={{ marginBottom: errors.confirmPassword ? '24px' : '0px' }}
              >
                <div className="flex flex-row items-center gap-2">
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your password"
                  />
                  <span
                    onClick={() => setShowConfirmPassword((prevState) => !prevState)}
                  >
                    {showConfirmPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                  </span>
                </div>
              </Form.Item>
            </div>
          </>
        }
      </Modal>
    </>
  );
};


export default AddUserEmail;
