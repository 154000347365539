import "./Updations.css";
import React, { useState } from "react";
import { Button, Modal, Checkbox, Input, Form } from "antd";
import axios from "axios";

function AddAssignee({ showToast }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        // API call to add lab
        axios
          .post(
            "/add-assignee-option",
            {
              assigneeOption: values.Assigneeoption,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                // Add any other headers you need here
              },
            }
          )
          .then((response) => {
            
            showToast("Assignee added successfully", "success");
            setOpen(false);
          })
          .catch((error) => {
            console.error("Error adding lab:", error);
          })
          .finally(() => {
            setConfirmLoading(false);
          });
      })
      .catch((info) => {
        
      });
  };

  const handleCancel = () => {
    
    setOpen(false);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Add Assignee Option
      </button>
      <Modal
        title="Add Assignee"
        visible={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ checked: false }}
        >
          <Form.Item
            name="Assigneeoption"
            label="Assignee Name"
            rules={[
              { required: true, message: "Please input the Assignee name!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddAssignee;
