import "./Updations.css";
import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Form } from "antd";
import {
  TextField,
  Autocomplete,
  Button as MuiButton,
  List,
  ListItem,
  IconButton,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";

function EditLabs({ showToast }) {
  const initialValues = {
    name: "",
    ml_amount: "",
    item_number: "",
    test_name: "",
    shopify_vendor_name: "",
    shopify_product_id: "",
    display_name: "",
  };
  const [Open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedLab, setSelectedLab] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [labs, setLabs] = useState([]);
  const [fields, setFields] = useState(initialValues);
  const [selectedTube, setSelectedTube] = useState(null);
  const [tubes, setTubes] = useState([]);

  const handleChangeTextField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    updateLab();
  };
  const deleteTube = (index) => {
    const newTubes = tubes.filter((_, i) => i !== index);
    setTubes(newTubes);
  };

  const fetchLabs = async () => {
    axios
      .get("/allLabs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setLabs(response.data.allLabs);
      })
      .catch((error) => {
        console.error("There was an error fetching the lab tests", error);
      });
  };

  const updateLab = async () => {
    
    
    axios
      .patch(
        `/labs/${selectedLab?._id}`,
        { ...fields, tube_type: tubes },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      )
      .then((response) => {
        showToast("Saved", "success");
        setOpen(false);
      })
      .catch((error) => {
        showToast("Something went wrong");
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };
  const fetchVendors = () => {
    axios
      .get("/vendorlab", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Add any other headers you need here
        },
      })
      .then((response) => {
        setVendors(response.data.vendors);
      })
      .catch((error) => {
        console.error("There was an error fetching the lab tests", error);
      });
  };
  const handleTubeChange = (event) => {
    setSelectedTube(event.target.value);
  };
  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
  };

  useEffect(() => {
    fetchVendors();
  }, [selectedLab]);

  useEffect(() => {
    fetchLabs();
  }, [Open]);

  const handleCancel = () => {
    
    setOpen(false);
    setConfirmLoading(false);
  };
  const addTube = () => {
    
    if (!selectedTube || !fields.ml_amount) {
      return;
    }
    setTubes([
      ...tubes,
      { name: selectedTube.name, ml_amount: fields.ml_amount },
    ]);
    setFields({ ...fields, name: "", ml_amount: "" });
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Edit Lab
      </button>
      <Modal
        title="Edit Lab"
        visible={Open} // Change open to visible
        onOk={handleOk}
        okText={"Update"}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form
          name="patientForm"
          initialValues={{ remember: true }}
          onFinish={handleOk} // You can change this to your form submit function
        >
          <div className="labsBody">
            <Autocomplete
              options={labs}
              value={selectedLab}
              getOptionLabel={(option) => option?.test_name || ""}
              getOptionKey={(option) => option._id}
              onChange={(event, newValue) => {
                setSelectedLab(newValue);
                setTubes(newValue?.tube_type || []);
                setFields({ ...initialValues, ...newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Lab" variant="outlined" />
              )}
            />
            <TextField
              label="Item Number"
              name="item_number"
              value={fields.item_number}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Test Name"
              name="test_name"
              value={fields.test_name}
              disabled
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Display Name"
              name="display_name"
              value={fields.display_name}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Shopify Vendor Name"
              name="shopify_vendor_name"
              value={fields.shopify_vendor_name}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Shopify Product ID"
              name="shopify_product_id"
              value={fields.shopify_product_id}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <FormControl>
              <InputLabel id="vendor-select-label">Vendor</InputLabel>
              <Select
                labelId="vendor-select-label"
                label="Vendor"
                value={selectedVendor}
                onChange={handleVendorChange}
                fullWidth
                margin="dense"
              >
                {vendors.map((vendor, index) => (
                  <MenuItem key={index} value={vendor}>
                    {vendor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedVendor && (
              <React.Fragment>
                <FormControl>
                  <InputLabel id="vendor-select-label">Tube</InputLabel>
                  <Select
                    labelId="vendor-select-label"
                    label="Vendor"
                    value={selectedTube}
                    onChange={handleTubeChange}
                    fullWidth
                    margin="dense"
                  >
                    {selectedVendor?.tube_types?.map((tube, index) => (
                      <MenuItem key={index} value={tube}>
                        {tube.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Limit (ml)"
                  name="ml_amount"
                  type="number"
                  value={fields.ml_amount}
                  onChange={handleChangeTextField}
                  margin="dense"
                  fullWidth
                />
                <MuiButton variant="contained" onClick={addTube}>
                  Add Tube
                </MuiButton>
              </React.Fragment>
            )}
            <List>
              {tubes?.map((tube, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteTube(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={tube.name}
                    secondary={`${tube.ml_amount} ml`}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default EditLabs;
