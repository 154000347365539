import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./navbar.css";
import LogoImage from "../Assets/SUNRIDGELogo 1.png";
import arrowImage from "../Assets/arrowImage.png";
// Import both default and active state icons
import pillImage from "../Assets/pillImage.png";
import activePillImage from "../Assets/activepill.png";
import sunImage from "../Assets/disableSun.png";
import activeSunImage from "../Assets/sun.png";
import contactsImage from "../Assets/contacts.png"
// ... import other images similarly
import copyImage from "../Assets/copy.png";
import activecopyImage from "../Assets/activeprep.png";
import patientFormImage from "../Assets/patientform.png";
import summaryImage from "../Assets/summary.png";
import infoImage from "../Assets/info.png";
import userCogImage from "../Assets/usercog.png";
import activeuserCogImage from "../Assets/activeuserCog.png";
import roomImage from "../Assets/Room.png";
import activeroomImage from "../Assets/activeroom.png";
import signOutImage from "../Assets/sign-out.png";
import activelabImage from "../Assets/activeLab.png";
import labImage from "../Assets/lab.png";
import activefrontdeskImage from "../Assets/activeFrontDesk.png";
import frontdeskImage from "../Assets/frontdesk.png";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

function Navbar({ onToggle }) {
  const [isOpen, setIsOpen] = useState(false);

  const initialActiveLink = localStorage.getItem("activeLink") || "day";
  const [activeLink, setActiveLink] = useState(initialActiveLink);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname) {
      const foundNavLink = navLinks.find(
        (navLink) => navLink.path === `/${location.pathname.split("/")[1]}`
      );
      if (foundNavLink) {
        handleLinkClick(foundNavLink.name);
      }
    }
  }, [location.pathname]);
  const handleLinkClick = (link) => {
    setActiveLink(link);
    localStorage.setItem("activeLink", link);
  };

  const isActive = (link) => {
    return activeLink === link;
  };

  const getIcon = (link, active) => {
    if (active) {
      switch (link) {
        case "treatment":
          return activePillImage;
        case "day":
          return activeSunImage;
        case "prep":
          return activecopyImage;
        case "admin":
          return activeuserCogImage;
        case "room":
          return activeroomImage;
        case "lab":
          return activelabImage;
        case "front desk":
          return activefrontdeskImage;
        case "export data":
          return activecopyImage;
        case "patient form":
          return patientFormImage;
        case "patient summary":
          return summaryImage;
        case "filters":
          return infoImage;
        // ... other cases for active icons
        default:
          return "";
      }
    } else {
      switch (link) {
        case "treatment":
          return pillImage;
        case "day":
          return sunImage;
        case "prep":
          return copyImage;
        case "admin":
          return userCogImage;
        case "room":
          return roomImage;
        case "lab":
          return labImage;
        case "front desk":
          return frontdeskImage;
        case "export data":
          return activecopyImage;
        case "patient form":
          return patientFormImage;
        case "patient summary":
          return summaryImage;
        case "filters":
          return infoImage;
        case "contacts":
          return contactsImage
        // ... other cases for default icons
        default:
          return "";
      }
    }
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    onToggle(!isOpen);
  };
  const userType = localStorage.getItem("user");

  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear("user");
    localStorage.clear("sessionToken");
    navigate("/login");
    window.location.reload();
  };
  const navLinks = [
    { name: "day", path: "/" },
    { name: "prep", path: "/auditview" },
    { name: "treatment", path: "/treatmentviewdisplay" },
    { name: "admin", path: "/updations" },
    { name: "room", path: "/roomview" },
    { name: "lab", path: "/labtracker" },
    { name: "front desk", path: "/frontdesk" },
    { name: "export data", path: "/exportdata" },
    { name: "patient form", path: "/patientform" },
    { name: "patient summary", path: "/patientsummary" },
    { name: "filters", path: "/filters" },
    {
      name: "contacts",
      path: "https://contacts.sunridgemedical.com/",
      external: true,
    },
  ];

  return (
    <div className={`navbar ${isOpen ? "open" : "half-closed"}`}>
      <div className="toggle-button" onClick={toggleNavbar}>
        <img src={arrowImage} alt="Toggle" />
      </div>
      <div className="Navbar-items overflow-y-auto overflow-x-hidden">
        <div className="navbar-items-flex">
          <div>
            <div className="my-9">
              {isOpen && <img src={LogoImage} alt="Logo" className="mx-auto" />}
            </div>
            <div className="navbar-links">
              {navLinks.map(({ name, path }) => (
                <NavLink
                  key={name}
                  to={path}
                  className={({ isActive }) =>
                    `navbarlink-cotent-flex gap-0.25 ${
                      isActive ? "active" : ""
                    }`
                  }
                  onClick={() => handleLinkClick(name)}
                >
                  <div
                    className={`active-dive ${
                      isActive(name) ? "active-bar" : ""
                    }`}
                  ></div>
                  <div className="navbar-link-active-flex">
                    <img
                      src={getIcon(name, isActive(name))}
                      alt={`${name} View`}
                    />
                    {isOpen && (
                      <p className="capitalize">{`${capitalizeFirstLetter(
                        name
                      )} View`}</p>
                    )}
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
          <div className="navbar-signout-btn">
            <button className="pr-2" onClick={handleSignOut}>
              {isOpen && <span>Signout</span>}
              <img className="ml-1" src={signOutImage} alt="Sign Out" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default Navbar;
