export const dispensedItemsArray = [
  "Testosterone",
  "Sermorelin",
  "note2",
  "Weightloss Injections",
  "HCG Injection",
  "NAD Injections",
  "Mistletoe Injection Protocol",
  "Vitamin D Injection (oil)",
  "HCG Injection Protocol",
  "MIC (25/50/50) Injection ",
  "Glutathione Injection",
  "DHEA Injection",
  "B6 / Methyl-B12  (100/1 mg) Injection",
  "B6 / Methyl-B12 / Folic Acid  (100/1/5 mg) Injection",
  "ACE (Adrenal Cortical Extract:Solu-Cortef)",
  "Weight Loss Injection (R)",
  "Weight Loss Injection (S)",
  "BPC 157",
  "PT 141",
  "INJ B12 SUBQ",
  "GHK-Cu Copper Peptide",
];
