import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Form, Row, Col, Select, Tooltip } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function UpdateProcedure({ showToast }) {
  const [updateProcedureOpen, setUpdateProcedureOpen] = useState(false);
  const [confirmUpdateProcedureLoading, setConfirmUpdateProcedureLoading] =
    useState(false);
  const [procedures, setProcedures] = useState([]);
  const [selectedProcedureName, setSelectedProcedureName] = useState(null);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [inputFields, setInputFields] = useState([]);
  const [shopifyVariantIds, setShopifyVariantIds] = useState([]);
  const [shopifyProductId, setShopifyProductId] = useState("");

  const fetchProcedures = async () => {
    try {
      const response = await axios.get("/allProcedures", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });
      setProcedures(response.data.allProcedures);
    } catch (error) {
      console.error("Error fetching procedures:", error);
    }
  };

  const showUpdateProcedureModal = () => {
    fetchProcedures();
    setUpdateProcedureOpen(true);
  };

  const handleUpdateProcedureOk = async () => {
    setConfirmUpdateProcedureLoading(true);

    const updatedDosages = inputFields.filter(
      (field) => field.trim().length > 0
    );

    let updateData = {
      procedureName: selectedProcedureName,
      shopify_product_id: shopifyProductId,
    };
    if (selectedProcedure && selectedProcedure?.dosages?.length > 0) {
      updateData.newDosages = updatedDosages;
      updateData.shopify_variant_ids = shopifyVariantIds;
    }

    await updateProcedure(updateData);
    setUpdateProcedureOpen(false);
    setConfirmUpdateProcedureLoading(false);
  };

  const handleUpdateProcedureCancel = () => {
    setUpdateProcedureOpen(false);
  };

  const handleProcedureChange = (procedureName) => {
    setSelectedProcedureName(procedureName);
    const selectedProc = procedures.find(
      (proc) => proc.procedure_name === procedureName
    );
    setSelectedProcedure(selectedProc);
    setInputFields(selectedProc ? selectedProc.dosages : []);
    setShopifyProductId(selectedProc ? selectedProc.shopify_product_id : "");
    setShopifyVariantIds(selectedProc ? selectedProc.shopify_variant_ids : []);
  };

  const addInputField = () => {
    setInputFields([...inputFields, ""]);
    setShopifyVariantIds([...shopifyVariantIds, ""]);
  };

  const handleInputChange = (e, index, field) => {
    if (field === "dosages") {
      const updatedInputFields = [...inputFields];
      updatedInputFields[index] = e.target.value;
      setInputFields(updatedInputFields);
    } else if (field === "shopify_variant_ids") {
      const updatedVariantIds = [...shopifyVariantIds];
      updatedVariantIds[index] = e.target.value;
      setShopifyVariantIds(updatedVariantIds);
    }
  };

  const removeInputField = (index) => {
    const updatedInputFields = [...inputFields];
    const updatedVariantIds = [...shopifyVariantIds];
    updatedInputFields.splice(index, 1);
    updatedVariantIds.splice(index, 1);
    setInputFields(updatedInputFields);
    setShopifyVariantIds(updatedVariantIds);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = reorder(
      inputFields,
      result.source.index,
      result.destination.index
    );

    const updatedVariantIds = reorder(
      shopifyVariantIds,
      result.source.index,
      result.destination.index
    );

    setInputFields(items);
    setShopifyVariantIds(updatedVariantIds);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const updateProcedure = async (updateData) => {
    try {
      const response = await axios.put(
        "/update-procedure-dosages",
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      showToast("Procedure updated successfully", "success");
    } catch (error) {
      console.error("Error updating procedure:", error);
    }
  };

  return (
    <>
      <Button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showUpdateProcedureModal}
      >
        Update Procedure
      </Button>
      <Modal
        title="Update Procedure"
        visible={updateProcedureOpen}
        onOk={handleUpdateProcedureOk}
        confirmLoading={confirmUpdateProcedureLoading}
        onCancel={handleUpdateProcedureCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form name="updateProcedureForm">
          <Form.Item
            name="procedureSelection"
            rules={[{ required: true, message: "Please select a Procedure" }]}
          >
            <Select
              placeholder="Select a Procedure"
              onChange={handleProcedureChange}
              style={{ width: "100%" }}
            >
              {procedures.map((proc) => (
                <Select.Option
                  key={proc.procedure_name}
                  value={proc.procedure_name}
                >
                  {proc.procedure_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="dosage-container">
            {selectedProcedure && selectedProcedure?.dosages?.length > 0 && (
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#1976d2",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
                className="py-1 px-10 w-full md:w-auto"
                type="primary"
                onClick={addInputField}
              >
                Add Dosage
              </Button>
            )}
            <Tooltip title="Add Shopify Product Id">
              <Input
                value={shopifyProductId}
                onChange={(e) => setShopifyProductId(e.target.value)}
                style={{
                  marginLeft:
                    selectedProcedure && selectedProcedure?.dosages?.length > 0
                      ? "15px"
                      : "0px",
                  width:
                    selectedProcedure && selectedProcedure?.dosages?.length > 0
                      ? "60%"
                      : "100%",
                }}
              />
            </Tooltip>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="dosage-list"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {inputFields.map((field, index) => (
                      <Draggable
                        key={index}
                        draggableId={`draggable-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="dosage-field"
                          >
                            <Row gutter={16}>
                              <Col span={2}>
                                <DragIndicatorIcon />
                              </Col>
                              <Col span={8}>
                                <Input
                                  value={field}
                                  onChange={(e) =>
                                    handleInputChange(e, index, "dosages")
                                  }
                                  style={{ marginRight: "10px" }}
                                />
                              </Col>
                              <Col span={8}>
                                <Tooltip title="Add Shopify Variant Id">
                                  <Input
                                    value={shopifyVariantIds[index]}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        index,
                                        "shopify_variant_ids"
                                      )
                                    }
                                    style={{ marginRight: "10px" }}
                                  />
                                </Tooltip>
                              </Col>
                              <Col span={4}>
                                <Button
                                  className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                                  type="danger"
                                  onClick={() => removeInputField(index)}
                                >
                                  <DeleteTwoTone style={{ fontSize: "20px" }} />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateProcedure;
