import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Row, Col, DatePicker, Select, message, Switch } from "antd";
import jsPDF from "jspdf";
import { ToastContainer, toast as toastifyToast } from "react-toastify";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css";
import HistoryIcon from "@mui/icons-material/History";
import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import RemoveIcon from "@mui/icons-material/Remove";
import { DataContext } from "../../context/DataContext";
import Pagination from "@mui/material/Pagination"; // Import the Pagination component from MUI
import PatientDataTable from "../MaterialTable";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  TextField,
  DialogActions,
  MenuItem,
  InputLabel,
  FormControl,
  Select as MUISelect,
} from "@mui/material";
import dayjs from "dayjs";
import Loader from "../../Components/Loader";
import { BarLoader, BounceLoader } from "react-spinners";
import TreatmentTemplates from "./TreatmentTemplates";
import CustomToast from "../../Components/CustomToast";
const { Option } = Select;
const flexContainerStyle = {
  display: "flex",
  alignItems: "center", // Align items vertically
  gap: "16px", // Gap between items
  "padding-bottom": "20px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const TreatmentViewDisplay = () => {
  const [tableData, setTableData] = useState([]);
  const [patientID, setPatientID] = useState();
  const [customFrequency, setCustomFrequency] = useState({});
  const [isCustomFrequencySelected, setIsCustomFrequencySelected] = useState(
    {}
  );
  const [toast, setToast] = useState(null);
  const [template, setTemplate] = useState(null);
  const [selectedPatientSH, setSelectedPatientSH] = useState();
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [matchedSupplement, setMatchedSupplement] = useState({});
  const [activePatient, setActivePatient] = useState([]);
  const [checkboxState, setCheckboxState] = useState({
    Orders: false,
    "Active Labs": false,
    "Active Procedures": false,
    "Active Dispensed": false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [selectedSupplements, setSelectedSupplements] = useState({});
  const [historicalSupplements, setHistoricalSupplements] = useState([]);
  const [toDate, setToDate] = useState(null);
  const [loader, setloader] = useState(false);
  const [loader1, setloader1] = useState(false);
  const [printOptions, setPrintOptions] = useState({
    recommended: true,
    historical: true,
  });

  const navigate = useNavigate();
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [openVitalsModal, setOpenVitalsModal] = useState(false);
  const [vitalData, setVitalData] = useState(null);
  const [Supplements, setSupplements] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const pdfDocRef = useRef(null);
  const [openPreview, setOpenPreview] = useState(false);


  const patientDataTableRef = useRef();

  const {
    data: {
      labs: contextLabs,
      dispensed: contextDispensed,
      procedures: contextProcedures,
    },
  } = useContext(DataContext);
  const handleVitalsModalOpen = () => setOpenVitalsModal(true);
  const handleVitalsModalClose = () => setOpenVitalsModal(false);
  const [inactivePatients, setInactivePatients] = useState([]);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState({
    G6PD: false,
    Note: "",
    Allergies: "",
    DOB: null, // or new Date().toISOString() for current date
  });
  const [showInactivePatients, setShowInactivePatients] = useState(false);
  const showToast = (variant, message) => {
    setToast({ variant, message });

    setTimeout(() => {
      setToast(null);
    }, 5000);
  };
  const handleChange = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };

  const { patientId2 } = useParams(); // This gets the patientId from the URL

  //Patient Functionality
  const GetActivePatientProfiles = async () => {
    try {
      setloader(true);
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setActivePatient(response.data);
        if (!patientId2) {
          setTableData([]);
        }

        setloader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setloader(false);
      }
      if (error.response && error.response.status === 409) {
      }
      console.error("Signup Error:", error);
      setloader(false);
    }
  };
  const fetchPatientProfile = async (customerId) => {
    try {
      const response = await axios.get(
        `/patient-profiles/${customerId}`, // Replace with your actual base URL and endpoint
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      setSelectedPatientSH(response.data);
      // Handle the successful response, e.g., setting state or returning the data
      return response.data;
    } catch (error) {
      console.error("Error fetching patient profile:", error);
      // Handle the error appropriately in your application, e.g., showing an error message
      throw error;
    }
  };
  const fetchSupplementNames = async () => {
    try {
      const response = await axios.get(
        `/defaultSupplements`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      const supplements = response.data.map((supplement) => {
        if (!supplement.frequency || supplement.frequency.length === 0) {
          supplement.frequency = ["1 dosage twice a day"];
        }
        return supplement;
      });

      setSupplements(supplements);
    } catch (error) {
      console.error("Error fetching supplement names:", error);
      // Handle the error appropriately in your application, e.g., showing an error message
      throw error;
    }
  };

  const sortItemsWithSelectedIds = (selectedSupplements, supplements) => {
    return supplements.slice().sort((a, b) => {
      const isASelected = selectedSupplements.hasOwnProperty(a._id);
      const isBSelected = selectedSupplements.hasOwnProperty(b._id);

      // Place selected items (those with _id in selectedSupplements) on top
      if (isASelected && !isBSelected) return -1;
      if (!isASelected && isBSelected) return 1;

      // If both are either selected or not, maintain original order
      const originalIndexA = supplements.findIndex(item => item._id === a._id);
      const originalIndexB = supplements.findIndex(item => item._id === b._id);

      return originalIndexA - originalIndexB;
    });
  };
  const [updatedItems, setUpdatedItems] = useState([])
  const sortCheckedItems = (items) => {
    return items.slice().sort((a, b) => {
      if (a.checked === true && b.checked === false) return -1;
      if (a.checked === false && b.checked === true) return 1;
      const originalIndexA = Supplements.findIndex(item => item.name === a.name);
      const originalIndexB = Supplements.findIndex(item => item.name === b.name);
      return originalIndexA - originalIndexB;
    });
  };
  useEffect(() => {
    const initialSelectedSupplements = {};
    function updateArrayWithIds(array, ids) {
      return array.map(obj => {
        if (ids[obj._id]) {
          return { ...obj, frequencyOptions: ["1 dosage twice a day", ...obj.frequency], ...ids[obj._id] };
        } else {
          return { ...obj, checked: false, frequencyOptions: ["1 dosage twice a day", ...obj.frequency] };
        }
      });
    }
    const updatedArray = updateArrayWithIds(Supplements, selectedSupplements)
    const sortedItems = sortCheckedItems([...updatedArray])
    setUpdatedItems(sortedItems)
  }, [selectedSupplements, Supplements])

  useEffect(() => {
    const initialSelectedSupplements = {};
    setHistoricalSupplements(selectedPatientSH?.HistoricalSupplements);
    selectedPatientSH?.RecommendedSupplements.forEach((recSupp) => {
      const matchingSupplement = Supplements.find(
        (sup) => sup.supplement_name === recSupp.supplementName
      );
      if (matchingSupplement) {
        initialSelectedSupplements[matchingSupplement._id] = {
          checked: true,
          frequency: recSupp.frequency,
          inHistory: false,
        };
      }
    });

    selectedPatientSH?.HistoricalSupplements.forEach((histSupp) => {
      const matchingSupplement = Supplements.find(
        (sup) => sup.supplement_name === histSupp.supplementName
      );
      if (matchingSupplement) {
        initialSelectedSupplements[matchingSupplement._id] = {
          checked: false,
          frequency: histSupp.frequency,
          inHistory: true,
        };
      }
    });

    setSelectedSupplements(initialSelectedSupplements);
  }, [selectedPatientSH, Supplements]);

  const handleCheckboxChangeNew = (supId) => {
    setSelectedSupplements((prev) => ({
      ...prev,
      [supId]: prev[supId]
        ? { ...prev[supId], checked: !prev[supId].checked }
        : { checked: true, frequency: "1 dosage twice a day" },
    }));
  };



  const handleFrequencyChange = (supId, frequency) => {
    setSelectedSupplements((prev) => ({
      ...prev,
      [supId]: { ...prev[supId], frequency },
    }));
  };

  const handleSubmitSupplements = (e) => {
    const selectedData = Object.entries(selectedSupplements)
      .filter(([, value]) => value.checked)
      .map(([key, value]) => {
        const supplement = Supplements.find((sup) => sup._id === key);
        return {
          supplementName: supplement.supplement_name,
          frequency: value.frequency,
        };
      });

    const profileData = {
      patientId: selectedPatientId,
      RecommendedSupplements: selectedData,
    };

    axios
      .post(
        "/Historical-RecommendedSupplements",
        profileData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        message.success("Data submitted successfully!");
        handleCloseSupplements();
      })
      .catch((error) => {
        message.error("Failed to submit data!");
        console.error(error);
      });
  };

  const handleMoveToHistory = (supplementId) => {
    const updatedSelectedSupplements = { ...selectedSupplements };
    const supplement = updatedSelectedSupplements[supplementId];

    const newHistoricalSupplements = [
      ...historicalSupplements,
      {
        supplementName: Supplements.find((sup) => sup._id === supplementId)
          .supplement_name,
        frequency: supplement.frequency,
      },
    ];

    updatedSelectedSupplements[supplementId] = {
      ...supplement,
      checked: false,
      inHistory: true,
    };

    setSelectedSupplements(updatedSelectedSupplements);
    setHistoricalSupplements(newHistoricalSupplements);

    const profileData = {
      patientId: selectedPatientId,
      HistoricalSupplements: newHistoricalSupplements,
    };

    axios
      .post(
        "/Historical-RecommendedSupplements",
        profileData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        showToast("success", "Moved to history successfully!")
      })
      .catch((error) => {
        showToast("error", "Failed to move to history!")
        console.error(error);
      });
  };

  const [loadingSupplementId, setLoadingSupplementId] = useState(null);

  const handleRemoveFromHistory = (supplementId, supplementName) => {
    const updatedSelectedSupplements = { ...selectedSupplements };
    setLoadingSupplementId(supplementId);

    const supplement = updatedSelectedSupplements[supplementId];
    updatedSelectedSupplements[supplementId] = {
      ...updatedSelectedSupplements[supplementId],
      inHistory: false,
    };

    setSelectedSupplements(updatedSelectedSupplements);
    const matchedSupplements = [];
    selectedPatientSH?.HistoricalSupplements.forEach((histSupp) => {
      const matchingSupplement = Supplements.find(
        (sup) => sup.supplement_name === histSupp.supplementName
      );
      if (matchingSupplement) {
        matchedSupplements.push(matchingSupplement);
      }
    });

    const matchedSupplement = matchedSupplements.find(
      (supp) => supp.supplement_name === supplementName
    );
    setMatchedSupplement(matchedSupplement);
    const newHistoricalSupplements = [
      // ...historicalSupplements,
      {
        supplementName: Supplements.find((sup) => sup._id === supplementId)
          .supplement_name,
        frequency: supplement.frequency,
      },
    ];

    const profileData = {
      patientId: selectedPatientId,
      supplementsToMove: newHistoricalSupplements,
    };

    axios
      .post("/MoveHistoricalToRecommended", profileData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      })
      .then((response) => {
        axios
          .get(`/patient-profiles/${patientID}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          })
          .then((response) => {
            setSelectedPatientSH(response.data);
            showToast("success", "Removed from history successfully!")
            setLoadingSupplementId(null);
          })
          .catch((error) => {
            showToast("error", "Failed to remove from history!")
            console.error(error);
            setLoadingSupplementId(null);
          });
      })
      .catch((error) => {
        showToast("error", "Failed to remove from history!")
        console.error(error);
        setLoadingSupplementId(null);
      });
  };
  const handleDownloadPDF = () => {
    if (pdfDocRef.current) {
      pdfDocRef.current.save("Supplements_Data.pdf");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "letter",
    });

    pdfDocRef.current = doc;

    const renderHeader = (doc) => {
      doc.setFontSize(10);

      doc.text("PATIENT", 10, 10);
      doc.setFont(undefined, "bold");
      doc.setFontSize(12);
      doc.text(`${selectedPatientSH["Full Name"]}`, 10, 15);
      doc.setFontSize(10);
      doc.setFont(undefined, "normal");
      doc.text(
        `DOB: ${moment(selectedPatientSH["DOB"]).format("MM/DD/YYYY")}`,
        10,
        20
      );
      doc.text(
        `AGE: ${moment().diff(moment(selectedPatientSH["DOB"]), "years")} yrs`,
        10,
        25
      );
      doc.text(`PRN: ${selectedPatientSH["Customer ID"]}`, 10, 30);

      doc.text("FACILITY", 75, 10);
      doc.setFont(undefined, "bold");
      doc.text("Sunridge Medical", 75, 15);
      doc.setFontSize(10);
      doc.setFont(undefined, "normal");
      doc.text("T (480) 659-9135", 75, 20);
      doc.text("F (480) 659-9197", 75, 25);
      doc.text("14200 N Northsight Blvd 160", 75, 30);
      doc.text("Scottsdale, AZ 85260", 75, 35);
      doc.text(`Print Date: ${moment().format("MM/DD/YYYY")}`, 135, 10);
    };

    renderHeader(doc);

    let startY = 40; // Initial startY position for the tables

    const formatSupplements = (supplements, title) => {
      const tableColumn = ["Supplement Name", "Frequency"];
      const tableRows = [];

      supplements.forEach(([supplementId, supplementData]) => {
        const matchingSupplement = Supplements.find(
          (sup) => sup._id === supplementId
        );
        if (matchingSupplement) {
          tableRows.push([
            matchingSupplement.supplement_name,
            supplementData.frequency,
          ]);
        }
      });

      return { tableColumn, tableRows, title };
    };

    if (printOptions.recommended) {
      const recommendedSupplements = Object.entries(selectedSupplements).filter(
        ([, value]) => value.checked && !value.inHistory
      );
      const { tableColumn, tableRows, title } = formatSupplements(
        recommendedSupplements,
        "Recommended Supplements"
      );

      doc.text(title, 15, startY);
      startY += 5; // Increase startY position for the next table
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY,
        theme: "grid",
        headStyles: { fillColor: [220, 220, 220] },
      });
      startY = doc.lastAutoTable.finalY + 5; // Update startY for the next table
    }

    if (printOptions.historical) {
      const historicalSupplements = Object.entries(selectedSupplements).filter(
        ([, value]) => value.inHistory
      );
      const { tableColumn, tableRows, title } = formatSupplements(
        historicalSupplements,
        "Historical Supplements"
      );

      doc.text(title, 15, startY);
      startY += 5; // Increase startY position for the next table
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY,
        theme: "grid",
        headStyles: { fillColor: [220, 220, 220] },
      });
    }
    const pdfBlob = doc.output("blob");


    const url = URL.createObjectURL(pdfBlob);
    setPdfUrl(url);
    // doc.save("Supplements_Data.pdf");
    setOpenPreview(true);
  };

  useEffect(() => {
    if (patientId2) {
      setSelectedPatientId(patientId2);
      handlePatientSelect(patientId2);

      // Define an async function inside the useEffect
      const fetchAndSetPatientInfo = async () => {
        try {
          const patientInfo = await fetchPatientProfile(patientId2);

          setSelectedPatientDetails(patientInfo); // Assuming you want to store the fetched patient info
        } catch (error) {
          // Handle the error, e.g., by setting an error state or showing an error message
          console.error("Failed to fetch patient profile:", error);
        }
      };

      // Call the async function
      fetchAndSetPatientInfo();
    }
  }, [patientId2]);

  useEffect(() => {
    GetActivePatientProfiles();
    fetchSupplementNames();
  }, []);
  const handleFromDateChange = (date, dateString) => {
    setFromDate(dateString); // dateString is in the format 'YYYY-MM-DD'
  };

  useEffect(() => {
    // Ensure that both fromDate and toDate have values before running handlePatientSelect
    if (fromDate && toDate && patientID) {
      handlePatientSelect(patientID);
    }
  }, [fromDate, toDate]); // Add fromDate and toDate to the dependency array

  const handleToDateChange = (date, dateString) => {
    setToDate(dateString); // dateString is in the format 'YYYY-MM-DD'
  };
  const getInactivePatientProfiles = async () => {
    try {
      setloader(true);
      const response = await axios.get(
        `/patient-profiles/inActive-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setInactivePatients(response.data);
        setloader(false);
      }
    } catch (error) {
      console.error("Error fetching inactive patients:", error);
      setloader(false);
    }
  };

  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    setShowInactivePatients(isChecked);

    if (isChecked && inactivePatients.length === 0) {
      await getInactivePatientProfiles();
    }
  };

  const handleG6PDChange = (event) => {
    const newG6PDValue = event.target.checked;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, G6PD: newG6PDValue };
      // Update the patient profile after setting the new state
      updatePatientProfile(prevDetails._id, updatedDetails);
      return updatedDetails;
    });
  };

  const handleTxNotesChange = (event) => {
    const newTxNotes = event.target.value;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, Note: newTxNotes };
      updatePatientProfile(prevDetails._id, { Note: newTxNotes });
      return updatedDetails;
    });
  };

  const handleAllergiesChange = (event) => {
    const newAllergies = event.target.value;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, Allergies: newAllergies };
      updatePatientProfile(prevDetails._id, { Allergies: newAllergies });
      return updatedDetails;
    });
  };

  const handleDateChange = (date, dateString) => {
    const newDOB = date.toISOString();
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, DOB: newDOB };
      updatePatientProfile(prevDetails._id, { DOB: newDOB });
      return updatedDetails;
    });
  };

  const updatePatientProfile = async (patientId, updatedDetails) => {
    try {
      const response = await fetch(
        `/patient-profiles/${patientId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify(updatedDetails),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // Handle successful update here
    } catch (error) {
      console.error("Error updating patient profile:", error);
    }
  };

  // Update the handlePatientSelect function to accept a page number
  const handlePatientSelect = async (selectedValue, page = 1) => {
    if (selectedValue == "" || selectedValue == null) {
      toastifyToast.error("Please select Patient");
    } else {
      setPatientID(selectedValue);
      setCurrentPage(page); // Set the current page
      setloader1(true);
      setSelectedPatientDetails({
        G6PD: false,
        Note: "",
        Allergies: "",
        DOB: null,
      });
      // Construct the URL with query parameters for pagination and optional date filters
      let url = `/patient/new/${selectedValue}?page=${page}&pageSize=25`;
      if (fromDate && toDate) {
        url += `&fromDate=${fromDate}&toDate=${toDate}`;
      }

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });

        if (response.status === 200) {
          setloader1(false);

          setTableData(response.data.orders);
          setTotalPages(response.data.totalPages); // Assuming the API returns this

          // Define an async function inside the useEffect
          const fetchAndSetPatientInfo = async () => {
            try {
              const patientInfo = await fetchPatientProfile(selectedValue);

              setSelectedPatientDetails(patientInfo); // Assuming you want to store the fetched patient info
              setSelectedPatientId(patientInfo["Customer ID"]);
              setCheckedSupplements(
                patientInfo["RecommendedSupplements"].reduce(
                  (obj, supplement) => {
                    obj[supplement] = true;
                    return obj;
                  },
                  {}
                )
              );
            } catch (error) {
              // Handle the error, e.g., by setting an error state or showing an error message
              console.error("Failed to fetch patient profile:", error);
            }
          };
          const fetchVitalData = async () => {
            try {
              const response = await axios.get(
                `/patient/vitals/${selectedValue}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "sessionToken"
                    )}`,
                  },
                }
              );
              function sortByDateDescending(apiResponse) {
                return apiResponse.sort(
                  (a, b) => new Date(b.date) - new Date(a.date)
                );
              }
              setVitalData(sortByDateDescending(response.data));
            } catch (error) {
              console.error("Error fetching vital data", error);
            }
          };

          fetchVitalData();
          // Call the async function
          fetchAndSetPatientInfo();
        } else {
          toastifyToast.error("Failed to fetch data");
          setTableData([]);
          setloader1(false);
        }
      } catch (error) {
        // Handle different error statuses
        if (error.response) {
          toastifyToast.error(error.response.data.message || "An error occurred");
          setloader1(false);
        } else {
          console.error("Error fetching patient orders:", error);
          toastifyToast.error("Network error or server is down");
          setloader1(false);
        }
        setTableData([]);
      }
    }
  };

  function addNewOrderTemplate(obj) {
    if (template?._id) {
      const url = `/addTreatmentWithTemplate/${patientID}/${obj.type}/${template?._id}`;
      axios
        .post(
          url,
          {}, // This is where you'd put the request body. If there's no body, you can leave it as an empty object
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          handlePatientSelect(patientID);
          patientDataTableRef.current.handleHideEmptyColumns();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const url = `/patient/order/new/${patientID}/${obj.type}`;
      axios
        .post(
          url,
          { date: obj.date }, // This is where you'd put the request body. If there's no body, you can leave it as an empty object
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          handlePatientSelect(patientID);
          patientDataTableRef.current.handleHideEmptyColumns();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function addNewOrder(type) {
    const url = `/patient/order/new/${patientID}/${type.type}`;
    axios
      .post(
        url,
        {}, // This is where you'd put the request body. If there's no body, you can leave it as an empty object
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        handlePatientSelect(patientID);
        patientDataTableRef.current.handleHideEmptyColumns();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleOrderDelete = (deletedOrderId) => {
    const updatedOrders = tableData.filter(
      (order) => order._id !== deletedOrderId
    );
    setTableData(updatedOrders);
  };

  // Function to handle page change
  const handlePageChange = (event, value) => {
    handlePatientSelect(patientID, value);
  };

  const [openSupplements, setOpenSupplements] = useState(false);
  const [checkedSupplements, setCheckedSupplements] = useState({});

  const handleOpenSupplements = () => {
    if (selectedPatientId) {
      setOpenSupplements(true);
    } else {
      toastifyToast.info("Please select Patient");
    }
  };

  const handleCloseSupplements = () => {
    setSearchTerm("");
    setCustomFrequency({});
    setIsCustomFrequencySelected({});
    setOpenSupplements(false);
  };

  const handleCheckboxChangeSupplements = (event) => {
    setCheckedSupplements({
      ...checkedSupplements,
      [event.target.name]: event.target.checked,
    });
  };
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // const handleSubmitSupplements = () => {
  //   const selectedSupplements = Object.keys(checkedSupplements).filter(
  //     (name) => checkedSupplements[name]
  //   );
  //

  //   const url = `/updateSupplements/${patientID}`;
  //   axios
  //     .put(
  //       url,
  //       { supplements: selectedSupplements }, // replace selectedSupplements with your actual array of selected supplements
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  //   handleCloseSupplements();
  // };
  const addFrequency = async (supplement, value) => {

    const matchedSupplement = Supplements.find(supp => supp.supplement_name === supplement.supplement_name)

    try {
      const response = await axios.post(`/supplements/${matchedSupplement._id}/add-frequency`, {
        newFrequency: value
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
      });
      toastifyToast('Frequency added successfully', { type: 'success' });
    } catch (error) {
      toastifyToast(`Error adding frequency: ${error.response?.data?.message || error.message}`, { type: 'error' });
    }

  };

  const removeFrequency = async (supplement, value) => {

    const matchedSupplement = Supplements.find(supp => supp.supplement_name === supplement.supplement_name)


    try {
      const response = await axios.post(`/supplements/${matchedSupplement._id}/remove-frequency`, {
        frequencyToRemove: value
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
      });
      toastifyToast('Frequency removed successfully', { type: 'success' });
    } catch (error) {
      toastifyToast(`Error adding frequency: ${error.response?.data?.message || error.message}`, { type: 'error' });
    }
  };

  const filteredItems = updatedItems && updatedItems.filter((item) =>
    searchTerm && typeof searchTerm === 'string'
      ? item.name.toLowerCase().includes(searchTerm.toLowerCase())
      : true
  );

  const handleChangeSwitch = (index, checked) => {
    const updatedElements = [...updatedItems];
    updatedElements[index].checked = checked;
    setUpdatedItems(updatedElements);
    const supplement = updatedElements[index];
    if (checked && (supplement.frequency && customFrequency[supplement._id].trim() !== "")) {
      addFrequency(supplement, customFrequency[supplement._id]);
    } else if (!checked && (supplement.frequency && customFrequency[supplement._id].trim() !== "")) {
      removeFrequency(supplement, customFrequency[supplement._id]);
    }
  };

  return (
    <>
      <div className="p-4 md:p-8">
        {/* Loader */}
        {loader1 && <Loader />}

        <div className={styles.container}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          ></ToastContainer>

          {/* Main containers */}
          <div className={styles.patientInfo}>
            {/* Select patient */}
            <div
              className={`${styles.patientNameContainer} p-3 md:p-5 w-full h-min`}
            >
              <span className="text-lg md:text-xl font-medium">
                Patient Name
              </span>

              <hr className="mt-1 border-gray-100" />

              {/* Select patient name */}
              {loader && <BarLoader color="#2e59db" />}
              {!loader && (
                <Select
                  placeholder="Select a patient"
                  className=" mt-3 w-full"
                  onChange={handlePatientSelect}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={selectedPatientId} // Set the selected value here
                >
                  {activePatient.map((patient) => (
                    <Option key={patient._id} value={patient["Customer ID"]}>
                      {patient["Full Name"]}
                    </Option>
                  ))}
                  {showInactivePatients &&
                    inactivePatients.map((patient) => (
                      <Option key={patient._id} value={patient["Customer ID"]}>
                        {patient["Full Name"]}
                      </Option>
                    ))}
                </Select>
              )}

              {/* Filter in inactive patienst as well */}
              <div className="mt-3 md:mt-2 flex flex-row justify-start md:justify-end">
                <input
                  type="checkbox"
                  id="inactiveCheckbox"
                  checked={showInactivePatients}
                  onChange={handleCheckboxChange}
                  className="ml-1 mr-2 "
                />
                <label className="text-xs md:text-sm">
                  Show Inactive Patient
                </label>
              </div>
            </div>

            {/* Patient details Conatiner */}
            <div
              className={`${styles.patientDetailsContainer} p-3 md:p-5 w-full h-min`}
            >
              {/* header div */}
              <div className="flex flex-row justify-between">
                <span className="text-lg md:text-xl font-medium">
                  Patient Information
                </span>
                <button
                  variant="contained"
                  onClick={handleVitalsModalOpen}
                  className="py-1 px-3 flex self-end border md:inline-block"
                >
                  All Vitals
                </button>

                <button
                  variant="contained"
                  onClick={handleOpenSupplements}
                  className="py-1 px-3 flex self-end border md:inline-block"
                >
                  Supplements
                </button>
                <Dialog
                  open={openSupplements}
                  onClose={handleCloseSupplements}
                  maxWidth="lg"
                  fullWidth
                >
                  <Box
                    p={4}
                    display="flex"
                    flexDirection="column"
                    height="80vh"
                  >
                    <div className="flex justify-between">
                      {" "}
                      <DialogTitle>
                        Add Supplements to Patient Treatment Plan
                      </DialogTitle>
                      <div className="flex justify-center items-center">
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={printOptions.recommended}
                                onChange={() =>
                                  setPrintOptions((prev) => ({
                                    ...prev,
                                    recommended: !prev.recommended,
                                  }))
                                }
                              />
                            }
                            label="Active"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={printOptions.historical}
                                onChange={() =>
                                  setPrintOptions((prev) => ({
                                    ...prev,
                                    historical: !prev.historical,
                                  }))
                                }
                              />
                            }
                            label="Historical"
                          />
                          <Tooltip
                            title={
                              !printOptions.recommended &&
                                !printOptions.historical
                                ? "Check Active or Historical option to Print"
                                : "Print"
                            }
                          >
                            <span>
                              <IconButton
                                disabled={
                                  !printOptions.recommended &&
                                  !printOptions.historical
                                }
                                onClick={() => handleGeneratePDF()}
                              >
                                <PrintIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <div>
                            <Dialog
                              open={openPreview}
                              onClose={() => { setOpenPreview(false) }}
                              maxWidth="lg"
                              fullWidth
                              PaperProps={{ style: { height: "100%", padding: "10px 20px" } }}
                            >
                              <DialogTitle>
                                PDF Preview
                                <button className="py-2 px-4 text-sm bg-blue-500 hover:bg-gray-600 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" onClick={handleDownloadPDF} style={{ float: "right" }}>
                                  Download PDF
                                </button>
                              </DialogTitle>
                              <DialogContent dividers style={{ height: "calc(100% - 64px)" }}>
                                {pdfUrl && (
                                  <iframe
                                    src={pdfUrl}
                                    width="100%"
                                    height="100%"
                                    title="PDF Preview"
                                    style={{ border: "none" }}
                                  />
                                )}
                              </DialogContent>
                            </Dialog>
                          </div>
                        </div>
                      </div>
                    </div>

                    <DialogContent
                      style={{
                        flexGrow: 1,
                        overflowY: "scroll",
                        padding: "1%",
                      }}
                    >
                      <TextField
                        label="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      />
                      <Grid container spacing={2}>
                        {filteredItems.map((sup, index) => (
                          <Grid item xs={12} key={index}>
                            <div className="flex items-center">
                              <Checkbox
                                checked={selectedSupplements[sup._id]?.checked || false}
                                disabled={sup?.inHistory || loadingSupplementId === sup._id}
                                onChange={() => handleCheckboxChangeNew(sup._id)}
                              />

                              {sup.supplement_name}
                              {selectedSupplements[sup._id]?.checked &&
                                sup.frequencyOptions?.length > 0 && (
                                  <>
                                    <FormControl
                                      style={{
                                        marginLeft: "10px",
                                        minWidth: "200px",
                                      }}
                                    >
                                      <MUISelect
                                        value={
                                          isCustomFrequencySelected[sup._id] ? "Custom" : selectedSupplements[sup._id]
                                            ?.frequency || ""
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (value === "custom") {
                                            setIsCustomFrequencySelected(
                                              (prev) => ({
                                                ...prev,
                                                [sup._id]: true,
                                              })
                                            );
                                            setCustomFrequency((prev) => ({
                                              ...prev,
                                              [sup._id]: "",
                                            }));
                                          } else {
                                            setIsCustomFrequencySelected(
                                              (prev) => ({
                                                ...prev,
                                                [sup._id]: false,
                                              })
                                            );
                                            handleFrequencyChange(
                                              sup._id,
                                              value
                                            );
                                          }
                                        }}
                                        displayEmpty
                                        renderValue={(selected) =>
                                          selected ? (
                                            selected
                                          ) : (
                                            <em>
                                              Select Recommended Supplement
                                              Frequency
                                            </em>
                                          )
                                        }
                                        style={{
                                          padding: "8px 12px",
                                          height: "40px",
                                        }}
                                      >
                                        {sup?.frequencyOptions?.length > 0 && sup.frequencyOptions.map(
                                          (freq, freqIndex) => (
                                            <MenuItem
                                              key={freqIndex}
                                              value={freq}
                                            >
                                              {freq}
                                            </MenuItem>
                                          )
                                        )}
                                        <MenuItem value="custom">
                                          Custom
                                        </MenuItem>
                                      </MUISelect>
                                    </FormControl>
                                    {isCustomFrequencySelected[sup._id] && (
                                      <>
                                        <TextField
                                          value={customFrequency[sup._id]}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setCustomFrequency((prev) => ({
                                              ...prev,
                                              [sup._id]: value,
                                            }));
                                            handleFrequencyChange(
                                              sup._id,
                                              value
                                            );
                                          }}
                                          onBlur={(event) => {
                                            if (sup.checked) {
                                              addFrequency(sup, event.target.value)
                                            }
                                          }}
                                          placeholder="Enter custom frequency"
                                          style={{
                                            marginLeft: "10px",
                                            width: "200px",
                                          }}
                                          InputProps={{
                                            style: {
                                              height: "40px",
                                            },
                                          }}
                                          inputProps={{
                                            style: {
                                              padding: "8px 12px",
                                            },
                                          }}
                                        />
                                        <Switch
                                          checked={sup.checked}
                                          className="bg-gray-300 ml-4 min-w-[100px]"
                                          onChange={(checked) => handleChangeSwitch(index, checked)}
                                          checkedChildren="Permanent"
                                          unCheckedChildren="Temporary"
                                        />


                                      </>
                                    )}

                                    {selectedSupplements[sup._id]
                                      ?.frequency &&
                                      !selectedSupplements[sup._id]
                                        ?.inHistory && (
                                        <IconButton
                                          onClick={() =>
                                            handleMoveToHistory(sup._id)
                                          }
                                          color="secondary"
                                          title="Move to History"
                                        >
                                          <HistoryIcon />
                                          <AddIcon
                                            style={{
                                              position: "absolute",
                                              top: 4,
                                              right: 0,
                                              fontSize: "0.5em",
                                              color: "green",
                                            }}
                                          />
                                        </IconButton>
                                      )}
                                  </>
                                )}
                              {selectedSupplements[sup._id]?.inHistory && (
                                <IconButton
                                  onClick={() =>
                                    handleRemoveFromHistory(
                                      sup._id,
                                      sup.supplement_name
                                    )
                                  }
                                  color="primary"
                                  title="Remove from History"
                                >
                                  <HistoryIcon />
                                  <RemoveIcon
                                    style={{
                                      position: "absolute",
                                      top: 4,
                                      right: 0,
                                      fontSize: "0.5em",
                                      color: "red",
                                    }}
                                  />
                                </IconButton>
                              )}
                              {loadingSupplementId === sup._id && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "24px",
                                    height: "24px",
                                    ml: 5,
                                  }}
                                >
                                  <CircularProgress size={24} />
                                </Box>
                              )}
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </DialogContent>

                    <DialogActions>
                      <div className="flex justify-between w-full">
                        <div className="flex justify-between items-center">
                          <div className="text-red-500">Note:</div>
                          <div className="ml-[5px]">
                            <IconButton
                              color="secondary"
                              title="Move to History"
                            >
                              <HistoryIcon />
                              <AddIcon
                                style={{
                                  position: "absolute",
                                  top: 4,
                                  right: 0,
                                  fontSize: "0.5em",
                                  color: "green",
                                }}
                              />
                            </IconButton>
                            Add to History
                          </div>
                          <div className="ml-[5px]">
                            <IconButton
                              color="primary"
                              title="Remove from History"
                            >
                              <HistoryIcon />
                              <RemoveIcon
                                style={{
                                  position: "absolute",
                                  top: 4,
                                  right: 0,
                                  fontSize: "0.5em",
                                  color: "red",
                                }}
                              />
                            </IconButton>
                            Remove from History
                          </div>
                        </div>
                        <div>
                          <Button
                            onClick={handleCloseSupplements}
                            sx={{
                              backgroundColor: "#3b82f6",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#3498db",
                              },
                            }}
                          >
                            Close
                          </Button>
                          <Button
                            onClick={handleSubmitSupplements}
                            sx={{
                              backgroundColor: "#3b82f6",
                              color: "white",
                              marginLeft: "10px",
                              "&:hover": {
                                backgroundColor: "#3498db",
                              },
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </DialogActions>
                  </Box>
                  {toast && (
                    <CustomToast
                      message={toast.message}
                      variant={toast.variant}
                      customStyles={{ bottom: "80px" }}
                    />
                  )}
                </Dialog>
              </div>

              <hr className="mt-1 border-gray-100" />

              {/* Grid */}
              <div className="grid grid-cols-1 md:grid-cols-5 grid-rows-2 gap-2">
                {/* tab 1: r1c1 */}
                <div
                  className={`${styles.editableField} flex flex-row gap-4 mt-3 col-span-2 `}
                >
                  <span className="mt-1 md:mt-2 text-xs md:text-sm">
                    Date of Birth:
                  </span>
                  <DatePicker
                    className="text-xs md:text-sm"
                    key={selectedPatientDetails.DOB}
                    format="MM-DD-YYYY"
                    value={
                      selectedPatientDetails.DOB
                        ? dayjs(selectedPatientDetails.DOB)
                        : undefined
                    }
                    onChange={handleDateChange}
                  />
                </div>
                {/* tab 2: r1c2 */}
                <div
                  className={`flex flex-row gap-4 mt-1 md:mt-3 md:ml-2 col-span-3 `}
                >
                  <span className="mt-1 md:mt-2 text-xs md:text-sm">
                    TX Notes:
                  </span>
                  <input
                    className="border w-60 md:3/4 rounded-md text-xs md:text-sm"
                    type="text"
                    defaultValue={selectedPatientDetails.Note || ""}
                    onBlur={handleTxNotesChange}
                  />
                </div>
                {/* tab 3: r2c1 */}
                <div className="flex flex-row gap-4 mt-3 col-span-3">
                  <span className="mt-1 md:mt-2 text-xs md:text-sm">
                    Allergies:
                  </span>
                  <input
                    className="border w-3/4 rounded-md text-xs md:text-sm px-3"
                    type="text"
                    defaultValue={selectedPatientDetails.Allergies}
                    onBlur={handleAllergiesChange} // Define this function to handle the change
                  />
                </div>
                {/* tab 4: r2c2 */}
                <div className="flex flex-row md:gap-4 gap-6 mt-3 col-span-1 p-2 sm:p-3  ">
                  <span className=" text-xs md:text-sm">G6PD:</span>
                  <input
                    className={`${styles.checkbox}`}
                    type="checkbox"
                    checked={selectedPatientDetails.G6PD || false}
                    onChange={handleG6PDChange} // Define this function to handle the change
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.tableContainer}>
            {/* Treatment view container */}
            <span className="text-2xl md:text-xl font-bold text-gray-800 tracking-wide">
              Treatment View
            </span>

            <div className="button-container gap-2 mt-0 md:mt-2 flex flex-wrap">
              <button
                className="py-2 px-4 text-sm bg-blue-500 hover:bg-gray-600 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={() => addNewOrderTemplate({ type: "Order" })}
              >
                Add New Order
              </button>

              {/* <button
                className="py-2 px-4 text-sm bg-green-500 hover:bg-gray-600 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                onClick={() => addNewOrderTemplate({ type: "Treatment" })}
              >
                Add New Treatment
              </button> */}
              <TreatmentTemplates
                setTemplate={setTemplate}
                addNewOrderTemplate={addNewOrderTemplate}
                addNewOrder={addNewOrder}
              />
              <button
                className="py-2 px-4 text-sm bg-gray-400 hover:bg-gray-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
                onClick={() => handlePatientSelect(patientID)}
              >
                Refresh
              </button>
            </div>

            {/* To and from date containers */}
            <div className="flex flex-row mb-4">
              <div className="mt-1">
                <span className="text-md md:text-lg font-bold text-black letter-gap-class">
                  From:
                </span>
                <DatePicker
                  format="MM-DD-YYYY"
                  onChange={handleFromDateChange}
                  value={fromDate ? moment(fromDate) : null}
                  style={{ marginLeft: "8px" }} // Add some space between the label and the date picker
                />
              </div>
              <div className="mt-1 ml-0 md:ml-4">
                <span className="text-md md:text-lg font-bold  letter-gap-class text-black">
                  To:
                </span>
                <DatePicker
                  format="MM-DD-YYYY"
                  onChange={handleToDateChange}
                  value={toDate ? moment(toDate) : null}
                  style={{ marginLeft: "8px" }} // Add some space between the label and the date picker
                />
              </div>
              <div className="ml-0 md:ml-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Orders"
                      checked={checkboxState.Orders}
                      onChange={handleChange}
                    />
                  }
                  label="Orders"
                />
              </div>
              <div className="ml-0 md:ml-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Active Labs"
                      checked={checkboxState["Active Labs"]}
                      onChange={handleChange}
                    />
                  }
                  label="Active Labs"
                />
              </div>
              <div className="ml-0 md:ml-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Active Procedures"
                      checked={checkboxState["Active Procedures"]}
                      onChange={handleChange}
                    />
                  }
                  label="Active Procedures"
                />
              </div>
              <div className="ml-0 md:ml-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Active Dispensed"
                      checked={checkboxState["Active Dispensed"]}
                      onChange={handleChange}
                    />
                  }
                  label="Active Dispensed"
                />
              </div>
            </div>

            {/* Patient info table */}
            <PatientDataTable
              data={tableData}
              patientID={patientID}
              contextD={contextDispensed}
              contextP={contextProcedures}
              onDeleteOrder={handleOrderDelete}
              setTableData={setTableData}
              handlePatientSelect={handlePatientSelect}
              filters={checkboxState}
              setLoader={setloader1}
            />
            {vitalData && (
              <Modal open={openVitalsModal} onClose={handleVitalsModalClose}>
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Latest Vital Information
                  </Typography>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 750 }} aria-label="vital data table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Order Date</TableCell>
                          <TableCell align="right">Time</TableCell>
                          <TableCell align="right">Blood Pressure</TableCell>
                          <TableCell align="right">Heart Rate</TableCell>
                          <TableCell align="right">SPO2</TableCell>
                          <TableCell align="right">Temperature</TableCell>
                          <TableCell align="right">Weight</TableCell>
                          {/* Add other vital headers here */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {vitalData.map((order) =>
                          order.vitals && order.vitals.length > 0 ? (
                            order.vitals.map((vital, index) => (
                              <TableRow key={`${order._id}-${index}`}>
                                <TableCell component="th" scope="row">
                                  {index === 0
                                    ? new Date(order.date).toLocaleDateString()
                                    : ""}
                                </TableCell>
                                <TableCell align="right">
                                  {new Date(vital.time).toLocaleTimeString()}
                                </TableCell>
                                <TableCell align="right">{vital.BP}</TableCell>
                                <TableCell align="right">{vital.HR}</TableCell>
                                <TableCell align="right">
                                  {vital.SPO2}
                                </TableCell>
                                <TableCell align="right">{vital.T}</TableCell>
                                <TableCell align="right">{vital.W}</TableCell>
                                {/* Add other vital cells here */}
                              </TableRow>
                            ))
                          ) : (
                            <TableRow key={order._id}>
                              <TableCell component="th" scope="row" colSpan={7}>
                                No vital data available for this order.
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Modal>
            )}

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
            <div
              style={{
                marginLeft: "50%",
                marginRight: "50%",
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TreatmentViewDisplay;
