import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import DragAndDropList from "./DragAndDropList";
import axios from "axios";

export const SortProcedures = ({ showToast }) => {
  const [Open, setOpen] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [procedures, setProcedures] = useState([]);
  const fetchProcedures = () => {
    axios
      .get("/allProcedures", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        
        setProcedures(response.data.allProcedures);
      })
      .catch((error) => {
        console.error("There was an error fetching the lab tests", error);
      });
  };

  const saveProcedureIndexes = () => {
    axios
      .post(
        "/saveProcedureIndexes",
        {
          procedures: procedures,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      )
      .then((response) => {
        showToast("Saved", "success");
      })
      .catch((error) => {
        
        showToast("Something went wrong", "error");
      })
      .finally(() => {
        setConfirmLoading(false);
        setOpen(false);
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setConfirmLoading(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    saveProcedureIndexes();
  };

  useEffect(() => {
    fetchProcedures();
  }, []);
  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Sort Procedures
      </button>
      <Modal
        title="Sort Procedures"
        open={Open}
        onOk={handleOk}
        okText={"Save"}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        styles={{
          body: {
            maxHeight: "700px",
            overflowY: "scroll",
          },
        }}
      >
        <DragAndDropList
          data={procedures}
          setParentItems={setProcedures}
          primaryKey={"procedure_name"}
        />
      </Modal>
    </>
  );
};
