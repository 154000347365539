import "./Updations.css";
import "./AddPatient";
import "./UpdatePatient";
import Header from "../Header";
import { Button } from "antd";
import AddPatient from "./AddPatient";
import UpdatePatient from "./UpdatePatient";
import DeletePatient from "./DeletePatient";
import RestorePatientData from "./RestorePatientData";
import AddProcedure from "./AddProcedure";
import UpdateProcedure from "./UpdateProcedure";
import DeleteProcedure from "./DeleteProcedure";

import AddDispenses from "./AddDispenses";
import UpdateDispense from "./UpdateDispense";
import DeleteDispense from "./DeleteDispence";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import AddLabs from "./AddLabs";
import EditLabs from "./EditLabs";
import DeleteLabs from "./DeleteLabs";

import AddVendorLab from "./AddVendorLabs";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { BarLoader, BounceLoader } from "react-spinners";
import UserManagement from "./userManagement";
import AddRoom from "./AddRoom";
import DeleteRoom from "./DeleteRoom";
import AddAssignee from "./AddAssignee";
import DeleteAssignee from "./DeleteAssignee";
// import AddUserEmail from "./addUserEmail";
// import DeleteUserEmail from "./deleteUserEmail";
// import AddLocalUser from "./addLocalUser";
import EditVendorLabs from "./EditVendorLabs";
import TemplateManagement from "./TemplateManagement";
import { SortLabs } from "./SortLabs";
import { SortDispenses } from "./SortDispenses";
import { SortProcedures } from "./SortProcedures";
import AddCalendar from "./AddCalendar";
import UpdateCalendar from "./UpdateCalendar";
import AddSupplements from "./AddSuplements";
import UpdateSupplements from "./UpdateSupplement";
import ManageSupplements from "./ManageSupplements";
import DeleteSupplement from "./DeleteSupplement";
import { SortSupplements } from "./SortSupplements";
import ManageTreatmentTemplates from "./ManageTreatmentTemplates";
import UpdateTreatmentTemplate from "./UpdateTreatmentTemplate";

function Updations() {
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const currentUserType = localStorage.getItem("user");
    setUserType(currentUserType);
  }, []);

  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function fetchPatients() {
      setLoader(true);
      const activePatients = await fetch(
        "/patient-profiles/active",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      ).then((res) => res.json());
      const inactivePatients = await fetch(
        "/patient-profiles/inActive-list",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      ).then((res) => res.json());
      setLoader(false);
      setPatients([...activePatients, ...inactivePatients]);
    }
    fetchPatients();
  }, []);

  const syncPatients = () => {
    axios
      .get("/shopify/patients/sync", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        // Handle success
        toast("Patient sync has started", { type: "success" });
      })
      .catch((error) => {
        // Handle error
        toast("An error occurred", { type: "error" });
      });
  };

  const showToast = (message, type) => {
    toast(message, { type });
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="AdminPortal-body">
        {userType == "admin" && (
          <div style={{ display: "inline-flex", padding: "2px" }}>
            <UserManagement />
            <Button
              onClick={syncPatients}
              className="sync-button" // Add your own CSS class for styling
            >
              Sync Patients
            </Button>
            {/* <AddUserEmail /> 
            <DeleteUserEmail />
            <AddLocalUser /> */}
          </div>
        )}

        <div className="UpdationBlock pr-10">
          <div className="flex flex-row flex-grow">
            {/* first block: color */}
            <div
              className="colorBox"
              style={{ backgroundColor: "#8CD663" }}
            ></div>
            {/* second block: name */}
            <div className="w-full my-auto">
              <span className="pl-12 blockTwo">Patient Management</span>
            </div>
          </div>
          {/* third block: buttons  */}
          <div className="patient-view-flexBox my-auto">
            <RestorePatientData patients={patients} showToast={showToast} />

            <AddPatient showToast={showToast} />
            <div style={{ marginTop: "15px" }}>
              {loader && <BarLoader color="#3698d6" />}
            </div>
            {!loader && (
              <UpdatePatient patients={patients} showToast={showToast} />
            )}
            {!loader && (
              <DeletePatient patients={patients} showToast={showToast} />
            )}
          </div>
        </div>

        {userType === "admin" && (
          <>
            <div className="UpdationBlock pr-10">
              <div className="flex flex-row flex-grow">
                {/* first block: color */}
                <div
                  className="colorBox"
                  style={{ backgroundColor: "#8154FF" }}
                ></div>
                {/* second block: name */}
                <div className="w-full my-auto">
                  <span className="pl-12 blockTwo">Procedures</span>
                </div>
              </div>
              <div className="patient-view-flexBox my-auto">
                <AddProcedure showToast={showToast} />
                <UpdateProcedure showToast={showToast} />
                <DeleteProcedure showToast={showToast} />
                <SortProcedures showToast={showToast} />
              </div>
            </div>

            <div className="UpdationBlock pr-10">
              <div className="flex flex-row flex-grow">
                {/* first block: color */}
                <div
                  className="colorBox"
                  style={{ backgroundColor: "#ffff66" }}
                ></div>
                {/* second block: name */}
                <div className="w-full my-auto">
                  <span className="pl-12 blockTwo">Calendars</span>
                </div>
              </div>
              <div className="patient-view-flexBox my-auto">
                <AddCalendar showToast={showToast} />
                <UpdateCalendar showToast={showToast} />
              </div>
            </div>

            <div className="UpdationBlock pr-10">
              <div className="flex flex-row flex-grow">
                {/* first block: color */}
                <div
                  className="colorBox"
                  style={{ backgroundColor: "#FFB300" }}
                ></div>
                {/* second block: name */}
                <div className="w-full my-auto">
                  <span className="pl-12 blockTwo">Dispenses</span>
                </div>
              </div>
              <div className="patient-view-flexBox  my-auto">
                <AddDispenses showToast={showToast} />
                <UpdateDispense showToast={showToast} />
                <DeleteDispense showToast={showToast} />
                <SortDispenses showToast={showToast} />
              </div>
            </div>

            <div className="UpdationBlock pr-10">
              <div className="flex flex-row flex-grow">
                {/* first block: color */}
                <div
                  className="colorBox"
                  style={{ backgroundColor: "#8154FF" }}
                ></div>
                {/* second block: name */}
                <div className="w-full my-auto">
                  <span className="pl-12 blockTwo">Supplements</span>
                </div>
              </div>
              <div className="patient-view-flexBox  my-auto">
                <AddSupplements showToast={showToast} />
                <UpdateSupplements showToast={showToast} />
                <ManageSupplements />
                <DeleteSupplement showToast={showToast} />
                <SortSupplements showToast={showToast} />
              </div>
            </div>

            <div className="UpdationBlock pr-10">
              <div className="flex flex-row flex-grow">
                {/* first block: color */}
                <div
                  className="colorBox"
                  style={{ backgroundColor: "#F8D7DA" }}
                ></div>
                {/* second block: name */}
                <div className="w-full my-auto">
                  <span className="pl-12 blockTwo">Labs</span>
                </div>
              </div>
              <div className="patient-view-flexBox my-auto">
                <AddLabs showToast={showToast} />
                <EditLabs showToast={showToast} />
                <DeleteLabs showToast={showToast} />
                <SortLabs showToast={showToast} />
              </div>
            </div>
            <div className="UpdationBlock pr-10">
              <div className="flex flex-row flex-grow">
                {/* first block: color */}
                <div
                  className="colorBox"
                  style={{ backgroundColor: "#FFB300" }}
                ></div>
                {/* second block: name */}
                <div className="w-full my-auto">
                  <span className="pl-12 blockTwo">Treatment Templates</span>
                </div>
              </div>
              <div className="patient-view-flexBox my-auto">
                <ManageTreatmentTemplates showToast={showToast} />
                {/* <UpdateTreatmentTemplate showToast={showToast} />  */}
              </div>
            </div>
            <div className="UpdationBlock pr-10">
              <div className="flex flex-row flex-grow">
                {/* first block: color */}
                <div
                  className="colorBox"
                  style={{ backgroundColor: "#8CD663" }}
                ></div>
                {/* second block: name */}
                <div className="w-full my-auto">
                  <span className="pl-12 blockTwo">Vendor Labs</span>
                </div>
              </div>
              <div className="patient-view-flexBox  my-auto">
                <AddVendorLab showToast={showToast} />
                <EditVendorLabs showToast={showToast} />
              </div>
            </div>
            <div className="UpdationBlock pr-10">
              <div className="flex flex-row flex-grow">
                {/* first block: color */}
                <div
                  className="colorBox"
                  style={{ backgroundColor: "#8154FF" }}
                ></div>
                {/* second block: name */}
                <div className="w-full my-auto">
                  <span className="pl-12 blockTwo">Rooms</span>
                </div>
              </div>
              <div className="patient-view-flexBox my-auto">
                <AddRoom showToast={showToast} />
                <DeleteRoom showToast={showToast} />
              </div>
            </div>
            <div className="UpdationBlock pr-10">
              <div className="flex flex-row flex-grow">
                {/* first block: color */}
                <div
                  className="colorBox"
                  style={{ backgroundColor: "#FFB300" }}
                ></div>
                {/* second block: name */}
                <div className="w-full my-auto">
                  <span className="pl-12 blockTwo">Assignee</span>
                </div>
              </div>
              <div className="patient-view-flexBox my-auto">
                <AddAssignee showToast={showToast} />
                <DeleteAssignee showToast={showToast} />
              </div>
            </div>
          </>
        )}
        <div className="UpdationBlock pr-10">
          <div className="flex flex-row flex-grow">
            {/* first block: color */}
            <div
              className="colorBox"
              style={{ backgroundColor: "#F8D7DA" }}
            ></div>
            {/* second block: name */}
            <div className="w-full my-auto">
              <span className="pl-12 blockTwo">Templates</span>
            </div>
          </div>
          <div className="patient-view-flexBox my-auto">
            <TemplateManagement showToast={showToast} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Updations;
