import React, { useState, useEffect, useCallback } from "react";
import {
  Input,
  Button,
  Modal,
  Typography,
  Space,
  message,
  Divider,
  Spin,
} from "antd";
import { Button as MuiButton } from "@mui/material";
import {
  SendOutlined,
  MessageOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import classNames from "classnames";
import UpdatePatient from "./UpdatePatient";
import styles from "./Messages.module.css";

const { TextArea } = Input;
const { Text, Title } = Typography;

const formatDateTime = (dateTime) => {
  const date = new Date(dateTime);

  const dayName = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
    date
  );
  const dateFormatted = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const timeFormatted = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return `${dayName}, ${dateFormatted} ${timeFormatted}`;
};

const Messages = ({ patient }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [recipientNumber, setRecipientNumber] = useState(() => {
    const defaultPhone = patient.phoneNumbers.find((phone) => phone.isDefault);
    return defaultPhone ? defaultPhone.number : "";
  });
  const [isSending, setIsSending] = useState(false);


  useEffect(() => {
    
    const defaultPhone = patient.phoneNumbers.find((phone) => phone.isDefault);
    setRecipientNumber(defaultPhone ? defaultPhone.number : "");
    setMessages(patient.messages)
  }, [patient]);

  const fetchConversation = useCallback(async () => {
    if (!recipientNumber) return;

    try {
      const response = await axios.get(
        `/get-conversation`,
        {
          params: { phoneNumber: recipientNumber },
        }
      );
      if (response.data && response.data.messages) {
        const sortedMessages = response.data.messages.sort((a, b) => 
          new Date(a.dateTime) - new Date(b.dateTime)
        );
        setMessages(sortedMessages);
      }
    } catch (error) {
      console.error("Error fetching conversation:", error);
      message.error("Failed to fetch conversation");
    }
  }, [recipientNumber]);

  useEffect(() => {
    if (isModalVisible) {
      fetchConversation();
      const intervalId = setInterval(fetchConversation, 5000);
      return () => clearInterval(intervalId);
    }
  }, [isModalVisible, fetchConversation]);

  const handleSendMessage = async () => {
    if (messageText.trim() === "") {
      message.warning("Please enter a message.");
      return;
    }
    if (!recipientNumber) {
      message.warning("Please enter a phone number first.");
      return;
    }
    setIsSending(true);
    try {
      await axios.post("/send-sms", {
        to: recipientNumber,
        text: messageText,
      });

      setMessageText("");
      message.success("Message sent successfully");
      fetchConversation();
    } catch (error) {
      console.error("Error sending SMS:", error);
      message.error("Failed to send message");
    } finally {
      setIsSending(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={styles["messages-container"]}>
      <Space
        direction="vertical"
        size="small"
        style={{ display: "flex", gap: "1rem" }}
      >
        {!recipientNumber && (
          <Input
            size="small"
            placeholder="Enter phone number"
            value={recipientNumber}
            onChange={(e) => setRecipientNumber(e.target.value)}
            prefix={<MessageOutlined />}
            className={styles["phone-input"]}
          />
        )}
        <TextArea
          rows={2}
          size="small"
          placeholder="Type your message..."
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          className={styles["message-input"]}
        />
        <Space>
          <Button
            size="small"
            onClick={showModal}
            className={classNames(styles["messages-view-button"], "ant-btn")}
          >
            Conversation
          </Button>
          <MuiButton
            variant="contained"
            color="success"
            onClick={handleSendMessage}
            disabled={isSending}
            size="small"
            style={{
              minWidth: "80px",
              padding: "4px 8px",
              fontSize: "12px",
            }}
          >
            {isSending ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ color: "white", fontSize: "14px" }}
                    spin
                  />
                }
              />
            ) : (
              <>
                <SendOutlined style={{ marginRight: 4, fontSize: "14px" }} />
                Send
              </>
            )}
          </MuiButton>
        </Space>
      </Space>

      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
        bodyStyle={{ padding: 0 }}
      >
        <div
          style={{
            padding: "16px 24px",
            borderBottom: "1px solid #f0f0f0",
            width: "100%",
          }}
        >
          <Title level={4} style={{ marginBottom: 8 }}>
            Conversation with {patient["Full Name"]}
          </Title>

          <Divider type="horizontal" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Text type="primary">Phone: {recipientNumber}</Text>
            <UpdatePatient patient={patient} />
          </div>
        </div>
        <div
          style={{
            height: 400,
            overflowY: "auto",
            padding: "16px 24px",
            backgroundColor: "#f5f5f5",
          }}
        >
          {messages.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: item.direction === "inbound" ? "flex-start" : "flex-end",
                marginBottom: "16px",
              }}
            >
              <div
                style={{
                  maxWidth: "70%",
                  backgroundColor: item.direction === "inbound" ? "#ffffff" : "#dcf8c6",
                  borderRadius: "12px",
                  padding: "10px 14px",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text strong style={{ fontSize: "0.9em", marginBottom: "4px", display: "block" }}>
                  {item.direction === "inbound" ? "Received" : "Sent"} ({item.type})
                </Text>
                <Text>{item.message}</Text>
                <Text type="secondary" style={{ fontSize: "0.75em", display: "block", marginTop: "4px" }}>
                  {formatDateTime(item.dateTime)}
                </Text>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            padding: "24px",
            borderTop: "1px solid #e0e0e0",
            backgroundColor: "#ffffff",
            boxShadow: "0 -2px 10px rgba(0,0,0,0.05)",
          }}
        >
          <div
            style={{
              display: "flex",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <TextArea
              rows={2}
              placeholder="Type your message..."
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              style={{
                flexGrow: 1,
                marginRight: "12px",
                borderRadius: "8px",
                resize: "vertical",
                minHeight: "50px",
                fontFamily: "Arial, sans-serif",
                fontSize: "16px",
                padding: "12px",
              }}
            />
            <MuiButton
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
              disabled={isSending}
              style={{
                height: "50px",
                alignSelf: "flex-end",
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: "bold",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
              }}
            >
              {isSending ? (
                <Spin
                  indicator={
                    <LoadingOutlined style={{ color: "white" }} spin />
                  }
                />
              ) : (
                <>
                  <SendOutlined style={{ marginRight: "8px" }} />
                  Send
                </>
              )}
            </MuiButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Messages;