import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox, Select, List, message } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import styles from './styles.module.css';

const TemplateStructureModal = ({ isVisible, onClose }) => {
    const [structure, setStructure] = useState([]);
    const [customTemplates, setCustomTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isVisible) {
            fetchStructure();
            fetchCustomTemplates();
        }
    }, [isVisible]);

    const fetchStructure = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/template-structure', {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                },
            });
            setStructure(response.data.structure);
        } catch (error) {
            message.error('Error fetching template structure');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCustomTemplates = async () => {
        try {
            const response = await axios.get('/api/templates/custom', {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                },
            });
            const availableTemplates = response.data.templates.filter(
                template => !structure.some(structItem => structItem.name === template.name)
            );
            setCustomTemplates(availableTemplates);
        } catch (error) {
            message.error('Error fetching custom templates');
        }
    };

    const updateStructure = async () => {
        try {
            await axios.put('/api/template-structure', { structure }, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                },
            });
            message.success('Template structure updated successfully');
        } catch (error) {
            message.error('Error updating template structure');
        } finally {
            onClose();
        }
    };

    const handleCheckChange = (checked, item) => {
        const updatedStructure = structure.map(s => {
            if (s.type === item.type && s.name === item.name) {
                return { ...s, enabled: checked };
            }
            return s;
        });
        setStructure(updatedStructure);
    };

    const handleSelectCustomTemplate = (name) => {
        const updatedStructure = [...structure, { type: 'custom', name, enabled: true }];
        setStructure(updatedStructure);
        fetchCustomTemplates(); // Re-fetch custom templates to update the dropdown list
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(structure);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setStructure(items);
    };

    return (
        <Modal
            title={<div className={styles.modalTitle}>Configure Template Structure</div>}
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            className={styles.modalContainer}
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={styles.listContainer}>
                            {structure.map((item, index) => (
                                <Draggable key={item.name || item.type} draggableId={item.name || item.type} index={index}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.listItem}>
                                            <Checkbox
                                                checked={item.enabled}
                                                onChange={(e) => handleCheckChange(e.target.checked, item)}
                                                className={styles.checkbox}
                                            >
                                                {item.name || item.type}
                                            </Checkbox>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Select
                className={styles.customTemplateSelect}
                placeholder="Add custom template"
                onChange={handleSelectCustomTemplate}
                options={customTemplates.map(template => ({ label: template.name, value: template.name }))}
            />
            <div className={styles.footer}>
                <Button className={`${styles.footerButton} ${styles.cancelButton}`} onClick={onClose}>
                    Cancel
                </Button>,
                <Button className={`${styles.footerButton} ${styles.applyButton}`} onClick={updateStructure}>
                    Apply
                </Button>
            </div>
        </Modal>
    );
};

export default TemplateStructureModal;
