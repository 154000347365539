import React, { useState, useEffect } from "react";
import styles from "./ExportData.module.css";
import * as XLSX from "xlsx";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Switch } from "antd";
import "react-toastify/dist/ReactToastify.css";

const ExportData = () => {
  const [exportEnabled, setExportEnabled] = useState(false);
  const [patientProfileToggle, setPatientProfileToggle] = useState(false);
  const [patientProceduresToggle, setPatientProceduresToggle] = useState(false);
  const [patientDispensedToggle, setPatientDispensedToggle] = useState(false);
  const [patientLabsToggle, setPatientLabsToggle] = useState(false);
  const [apisToCall, setApisToCall] = useState([]);

  const [procedureFields, setProcedureFields] = useState({
    procedure_name: false,
    dosages: false,
    note: false,
    checked: false,
    shopify_product_id: false,
    shopify_variant_ids: false,
    index: false,
  });

  const [dispensedFields, setDispensedFields] = useState({
    name: false,
    dosages: false,
    note: false,
    quantity: false,
    shopify_product_id: false,
    shopify_variant_ids: false,
    index: false,
  });

  const [labFields, setLabFields] = useState({
    test_name: false,
    checked: false,
    item_number: false,
    tube_type: false,
    shopify_vendor_name: false,
    shopify_product_id: false,
    display_name: false,
    index: false,
  });

  const [profileFields, setProfileFields] = useState({
    "Customer ID": false,
    "Full Name": false,
    "First Name": false,
    "Last Name": false,
    Email: false,
    Address1: false,
    Address2: false,
    City: false,
    Province: false,
    "Province Code": false,
    Country: false,
    "Country Code": false,
    Zip: false,
    Phone: false,
    Note: false,
    Status: false,
    G6PD: false,
    TxNotes: false,
    Allergies: false,
    DOB: false,
    RecommendedSupplements: false,
  });

  // Update APIs to call based on toggles
  useEffect(() => {
    const newApis = [];
    if (patientProfileToggle) {
      newApis.push("/patient-profiles");
    }
    if (patientProceduresToggle) {
      newApis.push("/allProcedures");
    }
    if (patientDispensedToggle) {
      newApis.push("/allDispensed");
    }
    if (patientLabsToggle) {
      newApis.push("/allLabs");
    }
    setApisToCall(newApis);
  }, [
    patientProfileToggle,
    patientProceduresToggle,
    patientDispensedToggle,
    patientLabsToggle,
  ]);

  const fetchDataAndExportToExcel = async () => {
    if (apisToCall.length === 0) {
      toast.error("No data to be exported. Enable the toggle.");
      return;
    }

    const wb = XLSX.utils.book_new();

    for (const apiUrl of apisToCall) {
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });

        let data = response.data;
        
        let sheetName = "";

        // Determine the sheet name and data based on API URL
        if (apiUrl.includes("allProcedures")) {
          data = data.allProcedures.map((item) => {
            // Include additional fields based on toggles
            const procedureData = {
              ...(procedureFields.procedure_name && {
                procedure_name: item.procedure_name,
              }),
              ...(procedureFields.dosages && { dosages: item.dosages }),
              ...(procedureFields.note && { note: item.note }),
              ...(procedureFields.checked && { checked: item.checked }),
              ...(procedureFields.shopify_product_id && {
                shopify_product_id: item.shopify_product_id,
              }),
              ...(procedureFields.shopify_variant_ids && {
                shopify_variant_ids: item.shopify_variant_ids,
              }),
              ...(procedureFields.index && { index: item.index }),
            };
            return procedureData;
          });
          sheetName = "Procedures";
        } else if (apiUrl.includes("patient-profiles")) {
          data = data.map((item) => {
            // Include additional fields based on toggles
            const profileData = {
              ...(profileFields["Customer ID"] && {
                "Customer ID": item["Customer ID"],
              }),
              ...(profileFields["Full Name"] && {
                "Full Name": item["Full Name"],
              }),
              ...(profileFields["First Name"] && {
                "First Name": item["First Name"],
              }),
              ...(profileFields["Last Name"] && {
                "Last Name": item["Last Name"],
              }),
              ...(profileFields.Email && { Email: item.Email }),
              ...(profileFields.Address1 && { Address1: item.Address1 }),
              ...(profileFields.Address2 && { Address2: item.Address2 }),
              ...(profileFields.City && { City: item.City }),
              ...(profileFields.Province && { Province: item.Province }),
              ...(profileFields["Province Code"] && {
                "Province Code": item["Province Code"],
              }),
              ...(profileFields.Country && { Country: item.Country }),
              ...(profileFields["Country Code"] && {
                "Country Code": item["Country Code"],
              }),
              ...(profileFields.Zip && { Zip: item.Zip }),
              ...(profileFields.Phone && { Phone: item.Phone }),
              ...(profileFields.Note && { Note: item.Note }),
              ...(profileFields.Status && { Status: item.Status }),
              ...(profileFields.G6PD && { G6PD: item.G6PD }),
              ...(profileFields.TxNotes && { TxNotes: item.TxNotes }),
              ...(profileFields.Allergies && { Allergies: item.Allergies }),
              ...(profileFields.DOB && { DOB: item.DOB }),
              ...(profileFields.RecommendedSupplements && {
                RecommendedSupplements: item.RecommendedSupplements,
              }),
            };
            return profileData;
          });
          sheetName = "Profiles";
        } else if (apiUrl.includes("allDispensed")) {
          data = data.allDispensed.map((item) => {
            const dispensedData = {
              ...(dispensedFields.name && {
                name: item.name,
              }),
              ...(dispensedFields.dosages && { dosages: item.dosages }),
              ...(dispensedFields.note && { note: item.note }),
              ...(dispensedFields.quantity && {
                quantity: item.quantity,
              }),
              ...(dispensedFields.shopify_product_id && {
                shopify_product_id: item.shopify_product_id,
              }),
              ...(dispensedFields.shopify_variant_ids && {
                shopify_variant_ids: item.shopify_variant_ids,
              }),
              ...(dispensedFields.index && { index: item.index }),
            };
            return dispensedData;
          });
          sheetName = "Dispensed Items";
        } else if (apiUrl.includes("allLabs")) {
          data = data.allLabs.map((item) => {
            // Include additional fields based on toggles
            const labData = {
              ...(labFields.test_name && { test_name: item.test_name }),
              ...(labFields.checked && { checked: item.checked }),
              ...(labFields.item_number && { item_number: item.item_number }),
              ...(labFields.tube_type && { tube_type: item.tube_type }),
              ...(labFields.shopify_vendor_name && {
                shopify_vendor_name: item.shopify_vendor_name,
              }),
              ...(labFields.shopify_product_id && {
                shopify_product_id: item.shopify_product_id,
              }),
              ...(labFields.display_name && {
                display_name: item.display_name,
              }),
              ...(labFields.index && { index: item.index }),
            };
            return labData;
          });
          sheetName = "Labs";
        }

        // Create a sheet with the data and add it to the workbook
        if (data && Array.isArray(data)) {
          const ws = XLSX.utils.json_to_sheet(data);
          XLSX.utils.book_append_sheet(wb, ws, sheetName);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(`Error fetching data: ${error.message}`);
        return;
      }
    }

    // Write the workbook to a file
    if (wb.SheetNames.length > 0) {
      XLSX.writeFile(wb, "SunridgeHospitalData.xlsx");
    } else {
      toast.warning("No data available for export.");
    }
  };

  const handleProcedureFieldToggle = (fieldName) => {
    setProcedureFields((prevFields) => ({
      ...prevFields,
      [fieldName]: !prevFields[fieldName],
    }));
  };

  const handleDispensedFieldToggle = (fieldName) => {
    setDispensedFields((prevFields) => ({
      ...prevFields,
      [fieldName]: !prevFields[fieldName],
    }));
  };

  const handleLabFieldToggle = (fieldName) => {
    setLabFields((prevFields) => ({
      ...prevFields,
      [fieldName]: !prevFields[fieldName],
    }));
  };

  const handleProfileFieldToggle = (fieldName) => {
    setProfileFields((prevFields) => ({
      ...prevFields,
      [fieldName]: !prevFields[fieldName],
    }));
  };

  return (
    <>
      {/* Header */}
      <div className={`${styles.headerFlexbox} mb-20`}>
        <div>
          <h3 className={`${styles.exportHeading}`}>Export Data</h3>
        </div>
        {/* Button to implement export data */}
        <div>
          <button
            onClick={fetchDataAndExportToExcel}
            className="btn-color py-2 px-6"
          >
            Export
          </button>
        </div>
      </div>
      {/* Export Options */}
      <div>
        {/* Option #1: Profiles */}
        <div className={`${styles.UpdationBlock} mx-auto items-center pr-10`}>
          <div
            className={styles.colorBox}
            style={{ backgroundColor: "#8CD663" }}
          ></div>
          <div className=" pl-12 w-full my-auto">
            <span className={styles.blockTwo}>Patient Profiles</span>
          </div>
          <div>
            <Switch
              className="bg-gray-300 mr-2"
              checked={patientProfileToggle}
              onChange={(checked) => setPatientProfileToggle(checked)}
            />
          </div>

          {patientProfileToggle && (
            <div className="mt-4 flex flex-wrap">
              {Object.keys(profileFields).map((field) => (
                <div className="mb-1" key={field}>
                  <Switch
                    checkedChildren={field}
                    unCheckedChildren={field}
                    className="bg-gray-300 mr-2"
                    checked={profileFields[field]}
                    onChange={() => handleProfileFieldToggle(field)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Option #3: Procedures */}
        <div className={`${styles.UpdationBlock} mx-auto items-center pr-10`}>
          <div
            className={styles.colorBox}
            style={{ backgroundColor: "#8154FF" }}
          ></div>
          <div className=" pl-12 w-full my-auto">
            <span className={styles.blockTwo}>Patient Procedures</span>
          </div>
          <div>
            <Switch
              className="bg-gray-300 mr-2"
              checked={patientProceduresToggle}
              onChange={(checked) => setPatientProceduresToggle(checked)}
            />
          </div>
          {patientProceduresToggle && (
            <div className="mt-4 flex flex-wrap">
              {Object.keys(procedureFields).map((field) => (
                <div className="mb-1" key={field}>
                  <Switch
                    checkedChildren={field
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                    unCheckedChildren={field
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                    className="bg-gray-300 mr-2"
                    checked={procedureFields[field]}
                    onChange={() => handleProcedureFieldToggle(field)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {/* Option #3: Dispensed Items */}
        <div className={`${styles.UpdationBlock} mx-auto items-center pr-10`}>
          <div
            className={styles.colorBox}
            style={{ backgroundColor: "#FFB300" }}
          ></div>
          <div className=" pl-12 w-full my-auto">
            <span className={styles.blockTwo}>Patient Dispensed Items</span>
          </div>
          <div>
            <Switch
              className="bg-gray-300 mr-2"
              checked={patientDispensedToggle}
              onChange={(checked) => setPatientDispensedToggle(checked)}
            />
          </div>
          {patientDispensedToggle && (
            <div className="mt-4 flex flex-wrap">
              {Object.keys(dispensedFields).map((field) => (
                <div className="mb-1" key={field}>
                  <Switch
                    checkedChildren={field
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                    unCheckedChildren={field
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                    className="bg-gray-300 mr-2"
                    checked={profileFields[field]}
                    onChange={() => handleDispensedFieldToggle(field)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {/* Option #: Labs */}
        <div className={`${styles.UpdationBlock} mx-auto items-center pr-10`}>
          <div
            className={styles.colorBox}
            style={{ backgroundColor: "#F8D7DA" }}
          ></div>
          <div className=" pl-12 w-full my-auto">
            <span className={styles.blockTwo}>Patient Labs</span>
          </div>
          <div>
            <Switch
              className="bg-gray-300 mr-2"
              checked={patientLabsToggle}
              onChange={(checked) => setPatientLabsToggle(checked)}
            />
          </div>
          {patientLabsToggle && (
            <div className="mt-4 flex flex-wrap">
              {Object.keys(labFields).map((field) => (
                <div className="mb-1" key={field}>
                  <Switch
                    checkedChildren={field
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                    unCheckedChildren={field
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                    className="bg-gray-300 mr-2"
                    checked={profileFields[field]}
                    onChange={() => handleLabFieldToggle(field)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ExportData;
