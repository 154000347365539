import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import "../../antSelect.css";
import "./auditview.css"; // Custom CSS file for styling
import { Row, Col, DatePicker, Select, Modal, message, Switch, Tooltip } from "antd";
import { Table, Button, Checkbox, Spin, Input, Radio } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import VitalsModal from "../VitalsModal";
import { CircularProgress, Grid, LinearProgress, Button as MuiButton, Paper } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import rightArrow from "../../Assets/arrowRight.png";
import leftArrow from "../../Assets/LeftArrow.png";
import { Box, MenuItem, Skeleton, TextField, Typography } from "@mui/material";
import { Select as MuiSelect, Checkbox as MuiCheckbox } from "@mui/material";
import styles from "./styles.module.css";
import dayjs from "dayjs";
import moment from "moment-timezone";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { toast as toastifyToast, ToastContainer } from "react-toastify";
import SingleTreatmentView from "../SingleTreatmentView";
import TreatmentNotes from "../TreatmentNotes";
import MultipleTreatmentsView from "../MultipleTreatmentsView";
import Title from "antd/es/typography/Title";
import RoomSection from "../RoomSection";
import Item from "antd/es/list/Item";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Provider } from '../../context/DataContext'
import _ from 'lodash'
import { CalendarMonth } from "@mui/icons-material";
import Calendar from "react-calendar";

const { TextArea } = Input;


const AuditView = ({ room, from }) => {
  const { patientId, orderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [showNameInitials, setShowNameInitials] = useState(false);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [showByRoom, setShowByRoom] = useState(true);
  const [patientLoading, setPatientLoading] = useState(false);
  const [activePatient, setActivePatient] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [distinctRooms, setDistinctRooms] = useState([]);
  const [originalAuditData, setOriginalAuditData] = useState([]);
  const [treatmentID, settreatmentID] = useState();
  const [patientID, setPatientID] = useState();
  const [lock, setLock] = useState(false);
  const [ivNote, setIVNote] = useState(""); // New state for IV Note
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [allTreatments, setAllTreatments] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showPatientsButtonSkeleton, setShowPatientsButtonSkeleton] = useState(false);
  const [showRefreshingLoader, setShowRefreshingLoader] = useState(false);
  const [treatmentsLimit, setTreatmentsLimit] = useState(0);
  const [previousTreatmentsSkip, setPreviousTreatmentsSkip] = useState(0);
  const [latestTreatmentsSkip, setLatestTreatmentsSkip] = useState(0);
  // const previousTreatmentsLimitRef = useRef(0);
  // const latestTreatmentsLimitRef = useRef(0);
  const [previousSelectedPatientId, setPreviousSelectedPatientId] =
    useState(null);
  const [patientItem, setpatientItem] = useState();
  const [ivVitaminC, setIvVitaminC] = useState(false); // State for IV Vitamin C checkbox
  const [selectedDate, setSelectedDate] = useState();
  const [currentPatient, setCurrentPatient] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isTreatmentNoteEditing = useRef(false);
  const [treatmentUpdate, setTreatmentUpdate] = useState(false);
  const [buttonsDataLoading, setButtonsDataLoading] = useState(false);
  const [vitalsModalOpen, setVitalsModalOpen] = useState(false);
  const [currentVitalsItems, setCurrentVitalsItems] = useState([]);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [menuRoom, setMenuRoom] = useState(null);
  const [open, setOpen] = useState(false);
  const [inactive, setInactive] = useState(false);
  // const [previousAuditData, setPreviousAuditData] = useState(null);
  const previousAuditData = useRef(null)
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });

  const calendarRef = useRef(null);

  const iconRef = useRef(null);


  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);
  const handleIconClick = () => {
    if (iconRef.current) {
      const iconBounds = iconRef.current.getBoundingClientRect();

      setCalendarPosition({
        top: iconBounds.bottom + window.scrollY - 5,
        left: iconBounds.left + window.scrollX - 500,
      });
    }
    setShowCalendar(!showCalendar);
  };


  console.log("SELECTED Patient....", selectedPatient)
  let inactiveTimer;

  const handleLockToggle = () => {
    setLock(!lock);
    if (!lock) {
      // localStorage.setItem('SelectedRooms', selectedRooms)
      // localStorage.setItem('showNameInitials', showNameInitials)
      // localStorage.setItem('selectedPatient_id', selectedPatient._id)
      // localStorage.setItem('selectedPatient_patientId', selectedPatient.PatientId)
      if (!selectedPatient) {
        localStorage.setItem('prepViewFilterDetails', JSON.stringify({ selectedRooms: selectedRooms, selectedAssignees: selectedAssignees, showNameInitials: showNameInitials, hideCompleted: hideCompleted, showByRoom: showByRoom }))
      }
      if (selectedPatient) {
        localStorage.setItem('prepViewFilterDetails', JSON.stringify({ selectedRooms: selectedRooms, selectedAssignees: selectedAssignees, showNameInitials: showNameInitials, hideCompleted: hideCompleted, showByRoom: showByRoom, selectedPatient_id: selectedPatient?._id, selectedPatient_patientId: selectedPatient?.PatientId, date: selectedDate }))
      }



      // handleSelectPatient(updatedPatient._id, updatedPatient.PatientId);

    }
    if (lock) {
      // localStorage.removeItem('SelectedRooms')
      // localStorage.removeItem('showNameInitials')
      // localStorage.removeItem('selectedPatient_id')
      // localStorage.removeItem('selectedPatient_patientId')
      localStorage.removeItem('prepViewFilterDetails')
    }
  };

  // Reset the inactive timer whenever there is user activity
  const resetTimer = () => {
    clearTimeout(inactiveTimer);
    setInactive(false); // Reset inactive state to false
    inactiveTimer = setTimeout(() => setInactive(true), 30000); // 30 seconds
  };

  useEffect(() => {
    resetTimer();
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keypress", resetTimer);
    };
  }, []);

  // Function to check if all procedures are prepared
  const areAllProceduresPrepared = () => {
    const allProceduresPrepared = filterProcedures().every(
      (proc) => preparedChecks.procedures[proc.procedure_name]
    );
    return allProceduresPrepared;
  };

  // Function to check if all dispensed items are prepared
  const areAllDispensedPrepared = () => {
    const allDispensedItemsPrepared = filterDispensedItems().every(
      (disp) => preparedChecks.dispensed[disp.name]
    );
    return allDispensedItemsPrepared;
  };

  // Function to check if all dispensed items are prepared
  const areAllLabsPrepared = () => {
    const allLabsItemsPrepared = filterLabs().every(
      (lab) => preparedChecks.labs[lab.test_name]
    );
    return allLabsItemsPrepared;
  };

  //labs
  const [auditChecks, setAuditChecks] = useState({
    dispensed: {},
    procedures: {},
    labs: {},
  });

  //labs
  const [preparedChecks, setPreparedChecks] = useState({
    dispensed: {},
    procedures: {},
    labs: {},
  });

  //labs
  const [administeredChecks, setAdministeredChecks] = useState({
    dispensed: {},
    procedures: {},
    labs: {},
  });

  const [compedChecks, setCompedChecks] = useState({
    dispensed: {},
    procedures: {},
  });

  const [todaysTreatment, setTodaysTreatment] = useState({
    dispensed: {},
    procedures: {},
  });

  const [selectedPatientDetails, setSelectedPatientDetails] = useState({
    G6PD: false,
    Note: "",
    Allergies: "",
    DOB: null, // or new Date().toISOString() for current date
  });


  const navigateDate = (offset) => {
    let newDate = null;
    const prepViewFilterDetails = JSON.parse(localStorage.getItem('prepViewFilterDetails'));

    const parsedSelectedDate = moment(selectedDate);


    newDate = parsedSelectedDate.tz("America/Phoenix").add(offset, "days");


    if (newDate.isValid()) {
      if (prepViewFilterDetails?.date == undefined && prepViewFilterDetails?.date == null) {
        setSelectedPatient(null);
      }
      setAuditData([]);
      setDistinctRooms([]);
      // setSelectedPatient(null);
      setSelectedDate(newDate.toISOString());

      isTreatmentNoteEditing.current = false;
    } else {
      console.error('Invalid date generated:', newDate);
    }


  };

  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }

  const handleCellChange = (patientId, columnName, newValue, category) => {
    // console.log("ColumnName:", columnName);
    // console.log("NewValue:", newValue);
    // console.log("Category:", category);
    let payload;
    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
      };
    }

    // Send the update request using axios
    axios
      .put(
        `/patient/order/update/${treatmentID}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json", // Specify the content type of your request
          },
        }
      )
      .then((response) => {
        console.log("Success:", response.data);
        if (columnName === 'assignees') {
          setRoomsData(prevState =>
            prevState.map(roomData =>
              roomData.patients.some(patient => patient._id === treatmentID)
                ? {
                  ...roomData,
                  patients: roomData.patients.map(patient =>
                    patient._id === treatmentID ? { ...patient, assignees: response.data.assignees } : patient
                  )
                }
                : roomData
            )
          );
        }
        if (columnName === 'room') {
          setRoomsData((prevState) => {
            let patientToMove;
            let roomExists = false;

            const updatedRooms = prevState.map((roomGroup) => {
              const updatedPatients = roomGroup.patients.filter((patient) => {
                if (patient.PatientId === patientID) {
                  patientToMove = { ...patient, room: newValue };
                  return false;
                }
                return true;
              });

              if (roomGroup.room === newValue) {
                roomExists = true;
              }

              return { ...roomGroup, patients: updatedPatients };
            });

            const newRooms = roomExists
              ? updatedRooms.map((roomGroup) => {
                if (roomGroup.room === newValue && patientToMove) {
                  return {
                    ...roomGroup,
                    patients: [...roomGroup.patients, patientToMove],
                  };
                }
                return roomGroup;
              })
              : [...updatedRooms, { room: newValue, patients: [patientToMove] }];

            const sortedRooms = newRooms.sort((a, b) => {
              return roomsOrder.indexOf(a.room) - roomsOrder.indexOf(b.room);
            });

            return sortedRooms;
          });
        }
        setTreatmentUpdate(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [assigneeOptions, setassigneeOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [treatmentAssignees, settreatmentAssignees] = useState([]);
  const [treatmentRoom, settreatmentRoom] = useState("");

  useEffect(() => {
    if (from === "roomview") {
      // Get data from local storage when the component mounts
      const savedRooms = localStorage.getItem("selectedRooms");
      if (savedRooms) {
        setSelectedRooms(JSON.parse(savedRooms));
      }
    }
  }, [from]);

  useEffect(() => {
    axios
      .get("/get-Assignee-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      }) // Replace with your actual API endpoint
      .then((response) => {
        setassigneeOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
        // Handle the error appropriately in your application
      });

    const fetchRoomOptions = async () => {
      //Get available rooms
      axios
        .get("/get-room-options", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }) // Replace with your actual API endpoint
        .then((response) => {
          console.log("response.data[0].name", response.data[0].name);
          setRoomOptions(response.data[0].name);

        })
        .catch((error) => {
          console.error("There was an error fetching the room options:", error);
          // Handle the error appropriately in your application
        });
    };

    fetchRoomOptions();
  }, []);

  const handleIvVitaminCChange = (event) => {
    setIvVitaminC(event.target.checked);
    // Optionally, add logic to handle this change (e.g., update backend)
  };

  const handleIVNoteChange = () => {
    // You can also include logic to update this note in the backend if needed

    // Construct the payload for the API call
    const payload = {
      treatment_id: treatmentID, // assuming id is the treatment id
      note: ivNote,
    };

    // API call to update backend
    (async () => {
      try {
        const response = await axios.put(
          "/iv_prep/updateByTreatment",
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        toastifyToast("iv Prep updated successfully", { type: "success" });
      } catch (error) {
        console.error("Error updating backend:", error);
      }
    })();
  };

  useEffect(() => {
    const prepViewFilterDetails = JSON.parse(localStorage.getItem('prepViewFilterDetails'))
    if (prepViewFilterDetails !== undefined && prepViewFilterDetails !== null) {
      if (prepViewFilterDetails?.date) {
        const datePart = moment(prepViewFilterDetails?.date).format('YYYY-MM-DD');
        const date = moment(datePart).toDate();
        setSelectedDate(date)
      }
      else {
        setSelectedDate(moment().format('YYYY-MM-DD'))
      }
      setLock(true)
      setShowNameInitials(prepViewFilterDetails.showNameInitials)
      setHideCompleted(prepViewFilterDetails.hideCompleted)
      setShowByRoom(prepViewFilterDetails.showByRoom)
      setSelectedRooms(prepViewFilterDetails.selectedRooms)
      setSelectedAssignees(prepViewFilterDetails.selectedAssignees)
    }
  }, [])

  // Function to select a patient based on provided patientId and orderId
  const checkAndSelectPatient = async () => {
    const prepViewFilterDetails = JSON.parse(localStorage.getItem('prepViewFilterDetails'))

    if (patientId && orderId) {
      try {
        const response = await axios.get(
          `/ordered/${patientId}/${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        const datePart = response.data[0].date.split("T")[0];
        const date = moment(datePart).toDate();
        setSelectedDate(date);
        setAllTreatments([]);
        fetchTreatments(orderId, patientId, datePart);
        if (isTreatmentNoteEditing.current) {
          toastifyToast.error(
            "That note is loading. Please wait before switching."
          );
          return;
        }
        setPreviousTreatmentsSkip(0);
        setLatestTreatmentsSkip(0);
        handleSelectPatient(orderId, patientId);
        setInitialDataLoaded(true);
      } catch (error) {
        console.error("Error fetching treatments:", error);
      } finally {
        setIsTreatmentNoteEditing(true);
      }
    } else {
      if (prepViewFilterDetails?.date === undefined || prepViewFilterDetails?.date === null) {
        setSelectedDate(new Date());
        setInitialDataLoaded(true);
      }
    }
  };

  useEffect(() => {
    checkAndSelectPatient();
  }, [patientId, orderId]);

  useEffect(() => {
    const prepViewFilterDetails = JSON.parse(localStorage.getItem('prepViewFilterDetails'))

    if (auditData.length > 0 && selectedPatient) {
      // Find the selected patient in the new data
      const updatedPatient = auditData.find(
        (p) => p._id === selectedPatient?._id
      );
      console.log("Updated patient:", updatedPatient, selectedPatient);
      if (updatedPatient) {
        handleSelectPatient(updatedPatient._id, updatedPatient.PatientId);
        settreatmentID(updatedPatient._id);
        setPatientID(updatedPatient.PatientId);
        fetchAndSetPreparedChecks(updatedPatient._id); // Refresh the prepared checks
        fetchPatientDetails(updatedPatient.PatientId); // Refresh the patient details
      } else {
        if (prepViewFilterDetails == undefined && prepViewFilterDetails == null) {

          setSelectedPatient(null);
          settreatmentID(null);
          setPatientID(null);
        }
      }
    } else {
      if (prepViewFilterDetails == undefined && prepViewFilterDetails == null) {
        setSelectedPatient(null);
        settreatmentID(null);
        setPatientID(null);
      }
    }

  }, [auditData]);

  useEffect(() => {
    console.log("currentPatient", currentPatient);
    if (currentPatient && !currentPatient?.ConsentForm) {
      setOpen(true);
    }
  }, [currentPatient]);

  useEffect(() => {
    fetchData(selectedRooms, selectedAssignees);
  }, [selectedRooms, selectedAssignees, hideCompleted]);


  const fetchData = async (rooms, assignees) => {
    setShowPatientsButtonSkeleton(true)

    setShowRefreshingLoader(true)

    // Updated to use 'America/Phoenix' timezone
    // console.log("selectedDate",selectedDate)

    let formattedDate = null;
    const prepViewFilterDetails = JSON.parse(localStorage.getItem('prepViewFilterDetails'))

    let matchedDate = null;
    if (prepViewFilterDetails && prepViewFilterDetails.date) {
      matchedDate = prepViewFilterDetails?.date.split('T')[0] === moment(selectedDate).format('YYYY-MM-DD')
    }

    if ((prepViewFilterDetails?.date !== undefined && prepViewFilterDetails?.date !== null) && matchedDate) {
      // formattedDate = moment.utc(prepViewFilterDetails.date).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
      formattedDate = moment(prepViewFilterDetails.date).format("YYYY-MM-DD")
    } else {
      formattedDate = moment(selectedDate).format("YYYY-MM-DD")
    }
    // const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

    setButtonsDataLoading(false);
    try {
      let url;
      if ((assignees.length === 0) && (rooms.length === 0)) {
        url = `/ordered/audit/${formattedDate}`
      }
      // if (from === "roomview" && room) {
      //   url = `/room-ordered/${formattedDate}/${room}`;
      // }

      else {
        if (rooms.length > 0) {
          // url = `/multiple-rooms-ordered/${formattedDate}/${selectedRooms.join(
          //   ","
          // )}`;
          url = `/orders/${formattedDate}?rooms=${selectedRooms.join(
            ","
          )}`;
        }
        if (assignees.length > 0 && assignees[0] !== "Unassigned") {
          url = `/orders/${formattedDate}?assignees=${selectedAssignees.join(
            ","
          )}`;
        }
        if ((rooms.length > 0 && rooms[0] !== "All Rooms") && (assignees.length > 0 && assignees[0] !== "Unassigned")) {
          url = `/orders/${formattedDate}?rooms=${selectedRooms.join(
            ","
          )}&assignees=${selectedAssignees.join(",")}`;
        }
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });

      const newData = response.data;
      if (JSON.stringify(previousAuditData.current) !== JSON.stringify(newData)) {
        previousAuditData.current = newData;
        const roomsOrder = [
          'Room 1',
          'Room 2',
          'Room 3',
          'Room 4',
          'Community 1',
          'Community 2',
          'Education Room'
        ];
        if (hideCompleted) {
          const completed = response.data.filter(item => item.status !== 'complete')
          setAuditData(completed)
          const distinctRoomsfiltered = [...new Set(completed.map(item => item.room))];
          const filteredArray = distinctRoomsfiltered.sort((a, b) => roomsOrder.indexOf(a) - roomsOrder.indexOf(b));
          setDistinctRooms(filteredArray);
          setShowPatientsButtonSkeleton(false)
          setShowRefreshingLoader(false)
        }
        else {
          setAuditData(response.data)
          const distinctRoomsfiltered = [...new Set(response.data.map(item => item.room))];
          const filteredArray = distinctRoomsfiltered.sort((a, b) => roomsOrder.indexOf(a) - roomsOrder.indexOf(b));
          setDistinctRooms(filteredArray);
          setShowPatientsButtonSkeleton(false)
          setShowRefreshingLoader(false)
        }
        setButtonsDataLoading(true);
      }
      setShowRefreshingLoader(false)
      setShowPatientsButtonSkeleton(false)
    } catch (error) {
      console.error("Error fetching treatments:", error);
      setAuditData([]);
      previousAuditData.current = [];
      setDistinctRooms([]);
      setShowPatientsButtonSkeleton(false)
      setShowRefreshingLoader(false)
    } finally {
    }
  };

  useEffect(() => {
    const prepViewFilterDetails = JSON.parse(localStorage.getItem('prepViewFilterDetails'))
    if (initialDataLoaded) {
      // Fetch data only if the initial load is complete
      console.log("i am executing 0");
      fetchData(selectedRooms, selectedAssignees);

      if (prepViewFilterDetails?.date !== undefined && prepViewFilterDetails?.date !== null) {
        fetchPatient(prepViewFilterDetails.selectedPatient_patientId)
        handleClickPatient(prepViewFilterDetails.selectedPatient_id, prepViewFilterDetails.selectedPatient_patientId);
      }
    }
    if (prepViewFilterDetails?.date !== undefined && prepViewFilterDetails?.date !== null) {
      fetchPatient(prepViewFilterDetails.selectedPatient_patientId)
      handleClickPatient(prepViewFilterDetails.selectedPatient_id, prepViewFilterDetails.selectedPatient_patientId);
    }

  }, [selectedDate, initialDataLoaded, selectedRooms, hideCompleted]);

  // Hidden as By Room and Completed can now be applied simultaneously
  // useEffect(() => {
  //   if (hideCompleted === true) {
  //     setShowByRoom(false)
  //   }
  // }, [hideCompleted])
  // useEffect(() => {
  //   if (showByRoom === true) {
  //     setHideCompleted(false)
  //   }

  // }, [showByRoom])

  useEffect(() => {
    const filteredData = auditData.filter((item) => item.status !== 'complete')
    if (hideCompleted) {
      setAuditData(filteredData)
    }
    if (!hideCompleted && previousAuditData.current !== null) {
      setAuditData(previousAuditData.current)
    }

  }, [hideCompleted])
  useEffect(() => {
    // Assuming formattedDate is defined and available in this scope

    // Setting up the interval
    const interval2 = setInterval(() => {
      console.log("i am executing 1", selectedRooms, selectedAssignees);
      fetchData(selectedRooms, selectedAssignees);
    }, 15000);

    // Clearing interval on component unmount
    return () => clearInterval(interval2);
  }, [selectedDate, selectedRooms, selectedAssignees, hideCompleted]); // Empty dependency array ensures this runs once on mount and then on unmount

  const [inactivePatients, setInactivePatients] = useState([]);

  const handleDispensedAllClick = async () => {
    const displayedDispensedItems = filterDispensedItems();

    setAdministeredChecks((prevChecks) => {
      const newAdministeredChecks = { ...prevChecks };
      newAdministeredChecks.dispensed = { ...prevChecks.dispensed };

      displayedDispensedItems.forEach((item) => {
        newAdministeredChecks.dispensed[item.name] = true;
      });

      return newAdministeredChecks;
    });

    setPreparedChecks((prevChecks) => {
      const newPreparedChecks = { ...prevChecks };
      newPreparedChecks.dispensed = { ...prevChecks.dispensed };

      displayedDispensedItems.forEach((item) => {
        newPreparedChecks.dispensed[item.name] = true;
      });

      return newPreparedChecks;
    });

    const updates = displayedDispensedItems.map((item) => ({
      name: item.name,
      status: true,
      itemType: "Administered",
      comped: compedChecks.dispensed[item.name] || false,
    }));

    const payload = {
      treatment_id: treatmentID,
      dispensed: updates,
    };

    try {
      await axios.put(
        "/iv_prep/updateByTreatment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast("All dispensed items marked as administered successfully", {
        type: "success",
      });
    } catch (error) {
      console.error("Error updating dispensed items:", error);
      toastifyToast("Error updating dispensed items", { type: "error" });
    }
  };

  const GetActivePatientProfiles = async () => {
    try {
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setActivePatient(response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log(error.response.data.message);
      }
      if (error.response && error.response.status === 409) {
        console.log(error.response.data.message);
      }
      console.error("Signup Error:", error);
    } finally {
    }
  };

  // const fetchAndSetPreparedChecks = async (treatmentID) => {
  //   try {
  //     const response = await axios.get(
  //       `/iv_prep/treatment/${treatmentID}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  //         },
  //       }
  //     );
  //     const ivPrepData = response.data;

  //     const newPreparedChecks = { dispensed: {}, procedures: {} };
  //     const newAdministeredChecks = { dispensed: {}, procedures: {} };
  //     const newTodaysTreatment = { dispensed: {}, procedures: {} };

  //     // Filter items to be displayed from the selected patient
  //     const displayedProcedures = filterProcedures();
  //     const displayedDispensed = filterDispensedItems();

  //     // Update state for procedures
  //     for (const proc of displayedProcedures) {
  //       let existingProcedure = ivPrepData.procedures.find(
  //         (p) => p.name === proc.procedure_name
  //       );
  //       if (!existingProcedure) {
  //         await axios.put(
  //           "/iv_prep/updateTodaysTreatment",
  //           {
  //             treatment_id: treatmentID,
  //             item: { name: proc.procedure_name, todaysTreatment: true },
  //             type: "procedures",
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  //             },
  //           }
  //         );
  //         existingProcedure = {
  //           name: proc.procedure_name,
  //           status: false,
  //           todaysTreatment: true,
  //           itemType: "Prepared",
  //         };
  //       }
  //       newPreparedChecks.procedures[proc.procedure_name] =
  //         existingProcedure.status;
  //       newAdministeredChecks.procedures[proc.procedure_name] =
  //         existingProcedure.status &&
  //         existingProcedure.itemType === "Administered";
  //       newTodaysTreatment.procedures[proc.procedure_name] =
  //         existingProcedure.todaysTreatment;
  //     }

  //     for (const disp of displayedDispensed) {
  //       let existingDispensed = ivPrepData.dispensed.find(
  //         (d) => d.name === disp.name
  //       );
  //       if (!existingDispensed) {
  //         await axios.put(
  //           "/iv_prep/updateTodaysTreatment",
  //           {
  //             treatment_id: treatmentID,
  //             item: { name: disp.name, todaysTreatment: true },
  //             type: "dispensed",
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  //             },
  //           }
  //         );
  //         existingDispensed = {
  //           name: disp.name,
  //           status: false,
  //           todaysTreatment: true,
  //           itemType: "Prepared",
  //         };
  //       }
  //       newPreparedChecks.dispensed[disp.name] = existingDispensed.status;
  //       newAdministeredChecks.dispensed[disp.name] =
  //         existingDispensed.status &&
  //         existingDispensed.itemType === "Administered";
  //       newTodaysTreatment.dispensed[disp.name] =
  //         existingDispensed.todaysTreatment;
  //     }

  //     setPreparedChecks(newPreparedChecks);
  //     setAdministeredChecks(newAdministeredChecks);
  //     setTodaysTreatment(newTodaysTreatment);
  //   } catch (error) {
  //     console.error("Error fetching IV preparation data:", error);
  //   }
  // };

  const handleOpenVitalsModal = async (patientId) => {
    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setCurrentVitalsItems(response.data.vitals);
      setVitalsModalOpen(true);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const fetchAndSetPreparedChecks = async (treatmentID) => {
    const setRoomandAssignees = async () => {
      try {
        const response = await axios.get(
          `/orderget/${treatmentID}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        // console.log("Fetching room and assignees");
        console.log(response.data);

        settreatmentRoom(response.data.room);
        settreatmentAssignees(response.data.assignees);
      } catch (error) {
        console.error("There was an error fetching the order:", error);
      }
    };

    setRoomandAssignees();
    try {
      const response = await axios.get(
        `/iv_prep/treatment/${treatmentID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      const ivPrepData = response.data;

      // Main treatment mein konsa data checked hua wa hai
      const displayedProcedures = filterProcedures();
      const displayedDispensed = filterDispensedItems();
      const displayedLabs = filterLabs();

      // Prepare updates for backend
      let updatePayload = { procedures: [], dispensed: [], labs: [] };

      //Compare and add the missing data to iv prep
      displayedLabs.forEach((filteredLabs) => {
        if (
          !ivPrepData.labs.some((lab) => lab.name === filteredLabs.test_name)
        ) {
          const newLab = {
            name: filteredLabs.procedure_name,
            status: false,
            itemType: "Prepared",
          };
          ivPrepData.labs.push(newLab);
          updatePayload.labs.push(newLab);
        }
      });

      // Compare and adds the missing data to ivy prep
      displayedProcedures.forEach((filteredProc) => {
        let isComped =
          filteredProc.procedure_name === "Red Light Bed" ||
          filteredProc.procedure_name === "Hydrogen In";

        if (
          !ivPrepData.procedures.some(
            (proc) => proc.name === filteredProc.procedure_name
          )
        ) {
          const newProc = {
            name: filteredProc.procedure_name,
            status: false,
            todaysTreatment: true,
            itemType: "Prepared",
            comped: isComped,
          };
          ivPrepData.procedures.push(newProc);
          updatePayload.procedures.push(newProc);
        }
      });

      // Compare and update dispensed items
      displayedDispensed.forEach((filteredDisp) => {
        if (
          !ivPrepData.dispensed.some((disp) => disp.name === filteredDisp.name)
        ) {
          const newDisp = {
            name: filteredDisp.name,
            status: false,
            todaysTreatment: true,
            itemType: "Prepared",
            comped: false,
          };
          ivPrepData.dispensed.push(newDisp);
          updatePayload.dispensed.push(newDisp);
        }
      });

      // Update backend if there are new items to add
      if (
        updatePayload.procedures.length > 0 ||
        updatePayload.dispensed.length > 0 ||
        updatePayload.labs.length > 0
      ) {
        await axios.put(
          `/iv_prep/updateByTreatment`,
          {
            treatment_id: treatmentID,
            procedures: updatePayload.procedures,
            dispensed: updatePayload.dispensed,
            labs: updatePayload.labs,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
      }

      const newPreparedChecks = { dispensed: {}, procedures: {}, labs: {} }; //same
      const newAdministeredChecks = { dispensed: {}, procedures: {}, labs: {} }; //same
      const newTodaysTreatment = { dispensed: {}, procedures: {} };
      const newCompedChecks = { dispensed: {}, procedures: {} };

      ivPrepData.dispensed.forEach((item) => {
        newPreparedChecks.dispensed[item.name] = item.status;
        newAdministeredChecks.dispensed[item.name] =
          item.status && item.itemType === "Administered";
        newTodaysTreatment.dispensed[item.name] = item.todaysTreatment;
        newCompedChecks.dispensed[item.name] = item.comped;
      });

      ivPrepData.labs.forEach((item) => {
        newPreparedChecks.labs[item.name] = item.status;
        newAdministeredChecks.labs[item.name] =
          item.status && item.itemType === "Administered";
      });

      ivPrepData.procedures.forEach((item) => {
        newPreparedChecks.procedures[item.name] = item.status;
        newAdministeredChecks.procedures[item.name] =
          item.status && item.itemType === "Administered";
        newTodaysTreatment.procedures[item.name] = item.todaysTreatment;
        newCompedChecks.procedures[item.name] = item.comped;
      });

      //update to add labs
      setPreparedChecks(newPreparedChecks);
      setAdministeredChecks(newAdministeredChecks);
      setTodaysTreatment(newTodaysTreatment);
      setCompedChecks(newCompedChecks);
      setIVNote(ivPrepData.note);
    } catch (error) {
      console.error("Error fetching IV preparation data:", error);
    }
  };

  // ... [rest of your code remains the same]

  const handleIVPrepChecked = async () => {
    if (!treatmentID) return; // Ensure treatmentID is available

    // Check if all items are marked as Prepared
    const allProceduresPrepared = filterProcedures().every(
      (proc) => preparedChecks.procedures[proc.procedure_name]
    );
    const allDispensedItemsPrepared = filterDispensedItems().every(
      (disp) => preparedChecks.dispensed[disp.name]
    );
    let payload;

    if (!allProceduresPrepared || !allDispensedItemsPrepared) {
      payload = { iv_prep: "" };
    } else if (allProceduresPrepared && allDispensedItemsPrepared) {
      payload = { iv_prep: "complete" };
    }

    try {
      // Existing functionality to mark IV Prep as completed
      const response = await axios.put(
        `/treatments/updateIV_Prep/${treatmentID}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        // Additional logic here, such as refreshing data or updating the UI
      } else {
        toastifyToast.error("Error marking IV Prep as complete");
      }
    } catch (error) {
      console.error("Error updating IV Prep status:", error);
      // toastifyToast.error("Error updating IV Prep status");
    }
  };

  // ... [rest of your code remains the same]

  const fetchPatientDetails = async (patientId) => {
    try {
      const response = await axios.get(
        `/patient-profiles/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setSelectedPatientDetails(response.data);
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  const fetchPatient = async (patientId) => {
    try {
      const response = await axios.get(
        `/patient-profiles/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setCurrentPatient(response.data);
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };
  useEffect(() => {
    if (selectedPatient) {
      fetchPatientDetails(selectedPatient.PatientId);
      fetchAndSetPreparedChecks(selectedPatient["_id"]);
    }
  }, [selectedPatient]);

  // const handleRefresh = async () => {
  //   const date = moment(selectedDate).format("YYYY-MM-DD");

  //   try {
  //     const [
  //       previousTreatmentsResponse,
  //       latestTreatmentsResponse,
  //       currentDateTreatmentResponse,
  //     ] = await Promise.all([
  //       axios.get(
  //         `/api/patient/${selectedPatient?.PatientId}/previous-treatments/${date}/${treatmentID}?skip=0&limit=${previousTreatmentsSkip}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  //           },
  //         }
  //       ),
  //       axios.get(
  //         `/api/patient/${selectedPatient?.PatientId}/latest-treatments/${date}/${treatmentID}?skip=0&limit=${latestTreatmentsSkip}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  //           },
  //         }
  //       ),
  //       axios.get(
  //         `/api/patient/${selectedPatient?.PatientId}/current-date-treatment/${date}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  //           },
  //         }
  //       ),
  //     ]);

  //     const previousTreatments = previousTreatmentsResponse.data.treatments;
  //     const latestTreatments = latestTreatmentsResponse.data.treatments;
  //     const currentDateTreatments =
  //       currentDateTreatmentResponse.data.treatments.filter(
  //         (item) => item._id === treatmentID
  //       );
  //     let allTreatments = [];
  //     if (previousTreatmentsSkip == 0 && latestTreatmentsSkip > 0) {
  //       allTreatments = [...latestTreatments, ...currentDateTreatments];
  //     }
  //     if (latestTreatmentsSkip == 0 && previousTreatmentsSkip > 0) {
  //       allTreatments = [...currentDateTreatments, ...previousTreatments];
  //     }
  //     if (latestTreatmentsSkip == 0 && previousTreatmentsSkip == 0) {
  //       allTreatments = [...currentDateTreatments];
  //     } else {
  //       allTreatments = [
  //         ...latestTreatments,
  //         ...currentDateTreatments,
  //         ...previousTreatments,
  //       ];
  //     }

  //     setAllTreatments(allTreatments);

  //     setShowSkeleton(false);
  //   } catch (error) {
  //     setShowSkeleton(false);
  //     console.error("Error refreshing treatments:", error);
  //   }
  // };

  const handleRefresh = async () => {
    const date = moment.utc(selectedDate).format("YYYY-MM-DD");

    try {
      const promises = [];

      // Add previous treatments request if skip is not 0
      if (previousTreatmentsSkip !== 0) {
        promises.push(
          axios.get(
            `/api/patient/${selectedPatient?.PatientId}/previous-treatments/${date}/${treatmentID}?skip=0&limit=${previousTreatmentsSkip}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
        );
      } else {
        promises.push(Promise.resolve({ data: { treatments: [] } }));
      }

      // Add latest treatments request if skip is not 0
      if (latestTreatmentsSkip !== 0) {
        promises.push(
          axios.get(
            `/api/patient/${selectedPatient?.PatientId}/latest-treatments/${date}/${treatmentID}?skip=0&limit=${latestTreatmentsSkip}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
        );
      } else {
        promises.push(Promise.resolve({ data: { treatments: [] } }));
      }

      // Add current date treatment request unconditionally
      promises.push(
        axios.get(
          `/api/patient/${selectedPatient?.PatientId}/current-date-treatment/${date}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
      );

      const [
        previousTreatmentsResponse,
        latestTreatmentsResponse,
        currentDateTreatmentResponse,
      ] = await Promise.all(promises);

      const previousTreatments = previousTreatmentsResponse.data.treatments;
      const latestTreatments = latestTreatmentsResponse.data.treatments;
      const currentDateTreatments =
        currentDateTreatmentResponse.data.treatments.filter(
          (item) => item._id === treatmentID
        );

      // let allTreatments = [];
      // if (previousTreatmentsSkip === 0 && latestTreatmentsSkip > 0) {
      //   allTreatments = [...latestTreatments, ...currentDateTreatments];
      // }
      // if (latestTreatmentsSkip === 0 && previousTreatmentsSkip > 0) {
      //   allTreatments = [...currentDateTreatments, ...previousTreatments];
      // }
      // if (latestTreatmentsSkip === 0 && previousTreatmentsSkip === 0) {
      //   allTreatments = [...currentDateTreatments];
      // } else {
      let allTreatments = [
        ...latestTreatments,
        ...currentDateTreatments,
        ...previousTreatments,
      ];
      // }

      setAllTreatments(allTreatments);
      setShowSkeleton(false);
    } catch (error) {
      setShowSkeleton(false);
      console.error("Error refreshing treatments:", error);
    }
  };

  const fetchPreviousTreatments = async () => {
    setShowSkeleton(true);
    const newSkip = previousTreatmentsSkip;
    setPreviousTreatmentsSkip(newSkip);

    const date = moment.utc(selectedDate).format("YYYY-MM-DD");

    try {
      const treatments = await axios.get(
        `/api/patient/${selectedPatient?.PatientId}/previous-treatments/${date}/${treatmentID}?skip=${newSkip}&limit=3`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (treatments.data.treatments.length > 0) {
        const previousTreatments = treatments.data.treatments;

        setAllTreatments([...allTreatments, ...previousTreatments]);
        setShowSkeleton(false);
        setPreviousTreatmentsSkip(previousTreatmentsSkip + 3);
        // previousTreatmentsLimitRef.current = newSkip + 4;
      } else {
        toastifyToast.error("There are no Previous Treatements to show");
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.error("Error fetching previous treatments:", error);
    }
  };

  const fetchLatestTreatments = async () => {
    setShowSkeleton(true);
    const newSkip = latestTreatmentsSkip;
    setLatestTreatmentsSkip(newSkip);

    const date = moment.utc(selectedDate).format("YYYY-MM-DD");

    try {
      const treatments = await axios.get(
        `/api/patient/${selectedPatient?.PatientId}/latest-treatments/${date}/${treatmentID}?skip=${newSkip}&limit=3`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (treatments.data.treatments.length > 0) {
        setAllTreatments([...treatments.data.treatments, ...allTreatments]);
        setShowSkeleton(false);
        setLatestTreatmentsSkip(latestTreatmentsSkip + 3);
        // latestTreatmentsLimitRef.current = newSkip + 4;
      } else {
        toastifyToast.error("There are no Latest Treatements to show");
        setShowSkeleton(false);
      }
    } catch (error) {
      setShowSkeleton(false);
      console.error("Error fetching latest treatments:", error);
    }
  };
  const handleHideAllOtherTreatments = () => {
    setLatestTreatmentsSkip(0);
    setPreviousTreatmentsSkip(0);

    const filteredTreatment = allTreatments.find(
      (element) =>
        element.date.split("T")[0] === moment.utc(selectedDate).format("YYYY-MM-DD")
    );
    setAllTreatments([filteredTreatment]);
  };
  const handleClickPatient = async (orderID, patientID) => {
    setAllTreatments([]);
    const prepViewFilterDetails = JSON.parse(localStorage.getItem('prepViewFilterDetails'))
    const matchedDate = moment(prepViewFilterDetails?.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')
    if (prepViewFilterDetails?.date !== undefined && prepViewFilterDetails?.date !== null) {
      if (matchedDate) {
        fetchTreatments(orderID, patientID);
      }
      else {
        fetchTreatments(orderID, patientID);
      }
    } else {
      fetchTreatments(orderID, patientID);
    }

    // fetchTreatments(orderID, patientID);
    if (isTreatmentNoteEditing.current) {
      toastifyToast.error(
        "That note is loading. Please wait before switching."
      );
      return;
    }
    setPreviousTreatmentsSkip(0);
    setLatestTreatmentsSkip(0);
    await handleSelectPatient(orderID, patientID);
    isTreatmentNoteEditing.current = true;
  };

  const fetchTreatments = async (orderId, patientId, date) => {
    let formattedDate = date ? moment.utc(date).format("YYYY-MM-DD") : moment(selectedDate).format("YYYY-MM-DD");
    const prepViewFilterDetails = JSON.parse(localStorage.getItem('prepViewFilterDetails'))
    if (prepViewFilterDetails?.selectedPatient_patientId === patientId) {
      formattedDate = moment(prepViewFilterDetails.date).format('YYYY-MM-DD')
    }



    try {
      const response = await axios.get(
        `/api/patient/${patientId}/current-date-treatment/${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );


      const match = response.data.treatments.filter(
        (item) => item._id === orderId
      );
      // if (prepViewFilterDetails.)
      setAllTreatments(match);
    } catch (error) {
      console.error("Error fetching treatments:", error);
    }
  };

  const handleSelectPatient = async (orderID, patientID) => {
    // latestTreatmentsLimitRef.current = 0;
    // previousTreatmentsLimitRef.current = 0;
    if (isTreatmentNoteEditing.current === true) {
      toastifyToast.error(
        "That note is loading. Please wait before switching."
      );
    } else {
      const response = await axios.get(
        `/ordered/${patientID}/${orderID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      console.log("SElECted Patient data: ", response.data);

      setSelectedPatient(response.data[0]);
      console.log("SELECTED PATIENT DATA IS...", response.data[0])
      settreatmentID(response.data[0]["_id"]);
      setPatientID(response.data[0]["PatientId"]);
    }
  };

  const setIsTreatmentNoteEditing = (isTreatmentNoteEditingVal) => {
    isTreatmentNoteEditing.current = isTreatmentNoteEditingVal;
  };

  // const handleSubmit = async () => {
  //   if (!selectedPatient) return;

  //   // Log checked prepared items
  //   console.log(
  //     "Prepared Dispensed Items:",
  //     Object.keys(preparedChecks.dispensed).filter(
  //       (id) => preparedChecks.dispensed[id]
  //     )
  //   );
  //   console.log(
  //     "Prepared Procedures Items:",
  //     Object.keys(preparedChecks.procedures).filter(
  //       (id) => preparedChecks.procedures[id]
  //     )
  //   );

  //   try {
  //     const payload = {
  //       orderId: selectedPatient._id, // Assuming this is the order ID
  //       auditChecks: auditChecks,
  //       status: "complete",
  //     };

  //     const response = await axios.post(
  //       "/audit/update",
  //       payload,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
  //         },
  //       }
  //     );

  //     toastifyToast("Audit updated successfully", { type: "success" });
  //     console.log("Audit Update Response:", response.data);
  //     // Additional logic post-update, like refreshing data or showing success message
  //   } catch (error) {
  //     console.error("Error updating audit status:", error);
  //   }
  // };

  const handleTodaysTreatmentCheckboxChange = async (
    type,
    id,
    name,
    checked
  ) => {
    // Update Todays Treatment Checks
    setTodaysTreatment((prevChecks) => ({
      ...prevChecks,
      [type]: {
        ...prevChecks[type],
        [name]: checked,
      },
    }));

    // Prepare payload for both Administered and Prepared
    const updatedItem = {
      name: name,
      todaysTreatment: checked,
    };

    // Call backend to update
    try {
      const payload = {
        treatment_id: treatmentID,
        item: updatedItem,
        type: type,
      };

      await axios.put(
        "/iv_prep/updateTodaysTreatment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      toastifyToast(`Today's Treatment status updated`, { type: "success" });
    } catch (error) {
      console.error("Error updating Today's Treatment status:", error);
    }
  };

  const handleAdministeredCheckboxChange = async (type, id, name, checked) => {
    // Update Administered Checks
    setAdministeredChecks((prevChecks) => ({
      ...prevChecks,
      [type]: {
        ...prevChecks[type],
        [name]: checked, // Use name instead of id for consistency
      },
    }));

    // Keep the 'comped' value unchanged
    const isComped = compedChecks[type][name] || false;

    // Update Prepared Checks (since Administered implies Prepared)
    setPreparedChecks((prevChecks) => ({
      ...prevChecks,
      [type]: {
        ...prevChecks[type],
        [name]: checked, // Use name instead of id for consistency
      },
    }));

    // Prepare payload for both Administered and Prepared
    const updatedItem = {
      name: name,
      status: true,
      itemType: checked ? "Administered" : "Prepared",
      comped: isComped, // Include comped status
    };

    const payload = {
      treatment_id: treatmentID,
      [type]: [updatedItem],
    };

    // Call backend to update
    try {
      const response = await axios.put(
        "/iv_prep/updateByTreatment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast(`Item updated successfully`, { type: "success" });
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleDrawnCheckboxChange = async (type, id, name, checked) => {
    // Update Administered and Prepared Checks
    setAdministeredChecks((prevChecks) => ({
      ...prevChecks,
      [type]: {
        ...prevChecks[type],
        [name]: checked,
      },
    }));

    setPreparedChecks((prevChecks) => ({
      ...prevChecks,
      [type]: {
        ...prevChecks[type],
        [name]: checked,
      },
    }));

    // Prepare payload
    const updatedItem = {
      name: name,
      status: checked,
      itemType: checked ? "Administered" : "Prepared",
    };

    // Call backend to update
    try {
      await axios.put(
        "/iv_prep/updateByTreatment",
        {
          treatment_id: treatmentID,
          labs: [updatedItem],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast(`Lab status updated successfully`, { type: "success" });
    } catch (error) {
      console.error("Error updating lab status:", error);
    }
  };

  // prepared checkbox labs
  const handlePreparedLabsCheckboxChange = async (type, name, checked) => {
    // Update state for Prepared items
    setPreparedChecks((prevChecks) => {
      const updatedChecks = {
        ...prevChecks,
        [type]: {
          ...prevChecks[type],
          [name]: checked,
        },
      };

      return updatedChecks;
    });

    // Prepare payload for Prepared item
    const updatedItem = {
      name: name,
      status: checked,
      itemType: "Prepared",
    };

    const payload = {
      treatment_id: treatmentID,
      [type]: [updatedItem],
    };

    // Call backend to update
    try {
      await axios.put(
        "/iv_prep/updateByTreatment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast(`Prepared item updated successfully`, { type: "success" });
    } catch (error) {
      console.error("Error updating prepared item:", error);
    }
  };


  useEffect(() => {
    handleIVPrepChecked();
  }, [preparedChecks]); // Dependency on preparedChecks state

  const handlePreparedCheckboxChange = async (type, name, checked) => {
    // Update state for Prepared items
    setPreparedChecks((prevChecks) => {
      const updatedChecks = {
        ...prevChecks,
        [type]: {
          ...prevChecks[type],
          [name]: checked,
        },
      };

      // Call backend update function
      updatePreparedStatusInBackend(type, name, checked);
      return updatedChecks;
    });
  };

  const updatePreparedStatusInBackend = async (type, name, checked) => {
    const updatedItem = {
      name: name,
      status: checked,
      itemType: "Prepared",
      comped: compedChecks[type][name] || false, // Preserve comped status
    };

    const payload = {
      treatment_id: treatmentID,
      [type]: [updatedItem],
    };

    try {
      await axios.put(
        "/iv_prep/updateByTreatment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast(`Prepared item updated successfully`, { type: "success" });
    } catch (error) {
      console.error("Error updating prepared item:", error);
    }
  };

  const handleAdministerAll = async () => {
    const displayedProcedures = filterProcedures();

    // Update the local state for Administered and Prepared Checks
    setAdministeredChecks((currentAdministeredChecks) => {
      const newAdministeredChecks = { ...currentAdministeredChecks };
      newAdministeredChecks.procedures = newAdministeredChecks.procedures || {};

      displayedProcedures.forEach((proc) => {
        newAdministeredChecks.procedures[proc.procedure_name] = true; // Set each procedure as administered
      });

      return newAdministeredChecks;
    });

    setPreparedChecks((currentPreparedChecks) => {
      const newPreparedChecks = { ...currentPreparedChecks };
      newPreparedChecks.procedures = newPreparedChecks.procedures || {};

      displayedProcedures.forEach((proc) => {
        newPreparedChecks.procedures[proc.procedure_name] = true; // Also set each procedure as prepared
      });

      return newPreparedChecks;
    });

    // Prepare the payload for the backend update
    const updatedProcedures = displayedProcedures.map((proc) => ({
      name: proc.procedure_name,
      status: true,
      itemType: "Administered",
      comped: compedChecks.procedures[proc.procedure_name] || false, // Preserve comped status
    }));

    // Update the backend
    await updateBackend(treatmentID, updatedProcedures, []);
  };

  const handlePrepareAllDispensed = async () => {
    const displayedDispensed = filterDispensedItems();

    // Update the local state for Prepared Checks
    setPreparedChecks((currentChecks) => {
      const newChecks = { ...currentChecks };
      newChecks.dispensed = newChecks.dispensed || {};
      displayedDispensed.forEach((disp) => {
        newChecks.dispensed[disp.name] = true; // Set each dispensed item as prepared
      });
      return newChecks;
    });

    // Construct the payload for the backend update
    const updatedDispensed = displayedDispensed.map((disp) => ({
      name: disp.name,
      status: true,
      itemType: administeredChecks.dispensed[disp.name]
        ? "Administered"
        : "Prepared", // Preserve administered status
      comped: compedChecks.dispensed[disp.name] || false, // Preserve comped status
    }));

    // Update the backend
    await updateBackend(treatmentID, [], updatedDispensed, []);
  };

  const handlePrepareAllLab = async () => {
    const displayedLabs = filterLabs();

    // administeredChecks.labs[record.test_name

    // Update the local state for Prepared Checks
    setPreparedChecks((currentChecks) => {
      const newChecks = { ...currentChecks };
      newChecks.labs = newChecks.labs || {};
      displayedLabs.forEach((lab) => {
        newChecks.labs[lab.test_name] = true; // Set each lab as prepared
      });
      return newChecks; // Return the updated state
    });

    // Prepare the payload for the backend update
    const updatedLabs = displayedLabs.map((lab) => ({
      name: lab.test_name,
      status: true,
      itemType: administeredChecks.labs[lab.test_name]
        ? "Administered"
        : "Prepared", // Preserve administered status
    }));

    // Update the backend
    await updateBackend(treatmentID, [], [], updatedLabs);
  };

  const handleAllLabsProcessed = async () => {
    const displayedLabs = filterLabs();

    // Update the local state for Prepared Checks
    const newPreparedChecks = { ...preparedChecks };
    newPreparedChecks.labs = {};
    displayedLabs.forEach((lab) => {
      newPreparedChecks.labs[lab.test_name] = true; // Set each lab as prepared
    });
    setPreparedChecks(newPreparedChecks);

    // Update the local state for Processed For Pickup Checks
    const newAdministeredChecks = { ...administeredChecks };
    newAdministeredChecks.labs = {};
    displayedLabs.forEach((lab) => {
      newAdministeredChecks.labs[lab.test_name] = true; // Set each lab as processed for pickup
    });
    setAdministeredChecks(newAdministeredChecks);

    // Prepare the payload for the backend update
    const updatedLabs = displayedLabs.map((lab) => ({
      name: lab.test_name,
      status: true,
      itemType: "Administered", // Since all labs are now prepared
    }));

    // Update the backend
    await updateBackend(treatmentID, [], [], updatedLabs);
  };

  const areAllLabsProcessed = () => {
    const displayedLabs = filterLabs();
    const allLabsProcessed = displayedLabs.every(
      (lab) => administeredChecks.labs[lab.test_name]
    );
    return allLabsProcessed;
  };

  const handlePrepareAllProcedure = async () => {
    const displayedProcedures = filterProcedures();

    // Update the local state for Prepared Checks
    setPreparedChecks((currentChecks) => {
      const newChecks = { ...currentChecks };
      newChecks.procedures = newChecks.procedures || {};

      displayedProcedures.forEach((proc) => {
        newChecks.procedures[proc.procedure_name] = true; // Set each procedure as prepared
      });

      return newChecks; // Return the updated state
    });

    // Prepare the payload for the backend update
    const updatedProcedures = displayedProcedures.map((proc) => ({
      name: proc.procedure_name,
      status: true,
      itemType: administeredChecks.procedures[proc.procedure_name]
        ? "Administered"
        : "Prepared", // Preserve administered status
      comped: compedChecks.procedures[proc.procedure_name] || false, // Preserve comped status
    }));

    // Update the backend
    await updateBackend(treatmentID, updatedProcedures, []);
  };

  const updateBackend = async (
    treatmentID,
    procedures = [],
    dispensed = [],
    labs = []
  ) => {
    const payload = {
      treatment_id: treatmentID,
      procedures,
      dispensed,
      labs,
    };

    try {
      await axios.put(
        "/iv_prep/updateByTreatment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast("Update successful", { type: "success" });
    } catch (error) {
      console.error("Error updating backend:", error);
      toastifyToast("Error updating backend", { type: "error" });
    }
  };

  const randomColor = () => {
    const lightColors = [
      "#ffadad",
      "#ffd6a5",
      "#fdffb6",
      "#caffbf",
      "#9bf6ff",
      "#a0c4ff",
      "#bdb2ff",
      "#ffc6ff",
    ];
    return lightColors[Math.floor(Math.random() * lightColors.length)];
  };

  const handleCompedCheckboxChange = async (type, name, checked) => {
    // Update local state
    setCompedChecks((prevChecks) => ({
      ...prevChecks,
      [type]: {
        ...prevChecks[type],
        [name]: checked,
      },
    }));

    // Prepare payload for backend
    const updatedItem = {
      name: name,
      comped: checked,
    };

    const payload = {
      treatment_id: treatmentID,
      [type]: [updatedItem],
    };

    // Call backend to update
    try {
      await axios.put(
        "/iv_prep/updateCompedStatus",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast(`Comped status updated successfully`, { type: "success" });
      handleRefresh();
    } catch (error) {
      console.error("Error updating comped status:", error);
    }
  };

  const dispensedColumns = [
    {
      title: "Dispensed Item",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <span style={{ fontWeight: "500" }}>
          {record.name}{" "}
          {record.selectedField ? `(${record.selectedField})` : ""}
        </span>
      ),
    },
    {
      title: "Prepared",
      key: "prepared",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={preparedChecks.dispensed[record.name] || false}
            onChange={(e) =>
              handlePreparedCheckboxChange(
                "dispensed",
                record.name,
                e.target.checked
              )
            }
          />
        </div>
      ),
    },

    {
      title: "Today's Treatments",
      key: "checkedtreatment",
      render: (_, record) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={true}
                readOnly
              // onChange={(e) =>
              //   handleTodaysTreatmentCheckboxChange(
              //     "dispensed",
              //     record._id,
              //     record.name,
              //     e.target.checked
              //   )
              // }
              />
              {"note" in record && (
                <TextArea
                  defaultValue={record.note || ""}
                  onBlur={(e) =>
                    handleNoteChange("dispensed", record._id, e.target.value)
                  }
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  style={{ marginLeft: 8 }}
                />
              )}
            </div>
          </>
        );
      },
    },

    {
      title: "Dispensed",
      key: "checkedDispensed",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={administeredChecks.dispensed[record.name] || false}
            onChange={(e) =>
              handleAdministeredCheckboxChange(
                "dispensed",
                record._id,
                record.name,
                e.target.checked
              )
            }
          />
        </div>
      ),
    },
    {
      title: "Comped",
      key: "comped",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={compedChecks.dispensed[record.name] || false}
            onChange={(e) =>
              handleCompedCheckboxChange(
                "dispensed",
                record.name,
                e.target.checked
              )
            }
          />
        </div>
      ),
    },
  ];

  const proceduresColumns = [
    {
      title: "Procedure",
      dataIndex: "procedure_name",
      key: "procedure_name",
      render: (_, record) => (
        <span style={{ fontWeight: "500", fontfamily: "Raleway, sans-serif" }}>
          {record.procedure_name}{" "}
          {record.selectedField ? `(${record.selectedField})` : ""}
        </span>
      ),
    },
    {
      title: "Prepared",
      key: "prepared",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={preparedChecks.procedures[record.procedure_name] || false}
            onChange={(e) =>
              handlePreparedCheckboxChange(
                "procedures",
                record.procedure_name,
                e.target.checked
              )
            }
          />
        </div>
      ),
    },

    {
      title: "Today's Treatments",
      key: "checkedtreatment",
      render: (_, record) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Checkbox
                checked={true}
                readOnly
              // onChange={(e) =>
              //   handleTodaysTreatmentCheckboxChange(
              //     "procedures",
              //     record._id,
              //     record.procedure_name,
              //     e.target.checked
              //   )
              // }
              />
              {"note" in record && (
                <TextArea
                  defaultValue={record.note || ""}
                  onBlur={(e) =>
                    handleNoteChange("procedures", record._id, e.target.value)
                  }
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  style={{ marginLeft: 8, minWidth: "100px" }}
                />
              )}
            </div>
          </>
        );
      },
    },

    {
      title: "Administered",
      key: "checkedAdministered",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={
              administeredChecks.procedures[record.procedure_name] || false
            }
            onChange={(e) =>
              handleAdministeredCheckboxChange(
                "procedures",
                record._id,
                record.procedure_name,
                e.target.checked
              )
            }
          />
        </div>
      ),
    },
    {
      title: "Comped",
      key: "comped",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={compedChecks.procedures[record.procedure_name] || false}
            onChange={(e) =>
              handleCompedCheckboxChange(
                "procedures",
                record.procedure_name,
                e.target.checked
              )
            }
          />
        </div>
      ),
    },
  ];

  const labsColumns = [
    {
      title: "Lab",
      dataIndex: "test_name",
      key: "test_name",

      render: (_, record) => (
        <span style={{ fontWeight: "500", fontfamily: "Raleway, sans-serif" }}>
          {record.test_name}{" "}
          {record.selectedField ? `(${record.selectedField})` : ""}
        </span>
      ),
    },
    {
      title: "Drawn",
      key: "prepared",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={preparedChecks.labs[record.test_name] || false}
            onChange={(e) =>
              handlePreparedLabsCheckboxChange(
                "labs",
                record.test_name,
                e.target.checked
              )
            }
          />
        </div>
      ),
    },

    {
      title: "Processed For Pickup",
      key: "checkedDrawn",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={administeredChecks.labs[record.test_name] || false}
            onChange={(e) =>
              handleDrawnCheckboxChange(
                "labs",
                record._id,
                record.test_name,
                e.target.checked
              )
            }
          />
        </div>
      ),
    },
  ];

  const handleNoteChange = async (type, id, note) => {
    try {
      const payload = { note };
      let response;

      if (type === "procedures") {
        response = await axios.put(
          `/ordered/updateProcedureNote/${treatmentID}/${id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
      } else if (type === "dispensed") {
        response = await axios.put(
          `/ordered/updateDispensedNote/${treatmentID}/${id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
      } else if (type === "labs") {
        response = await axios.put(
          `/ordered/updateLabNote/${treatmentID}/${id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
      }

      toastifyToast("Note updated successfully", { type: "success" });
    } catch (error) {
      console.error("Error updating note:", error);
      toastifyToast("Error updating note", { type: "error" });
    }
  };

  const handleReviewChange = async (orderId) => {
    try {
      const isReviewed = true;
      await axios.put(
        `/patient/order/update-review/${treatmentID}`,
        { review: isReviewed },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast.success("Review status updated successfully");
      toggleModal();
    } catch (error) {
      toastifyToast.error("Error updating review status");
      console.error("Error:", error);
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const filterDispensedItems = () => {
    return selectedPatient.userdispensed.filter(
      (item) =>
        (item.checked && (!item.dosages || item.dosages.length === 0)) ||
        (item.dosages && item.dosages.length > 0 && !!item.selectedField)
    );
  };

  const filterProcedures = () => {
    return selectedPatient.userProcedures.filter(
      (item) =>
        (item.checked && (!item.dosages || item.dosages.length === 0)) ||
        (item.dosages && item.dosages.length > 0 && !!item.selectedField)
    );
  };

  // Work for labs
  const filterLabs = () => {
    if (selectedPatient && selectedPatient.userLabs) {
      return selectedPatient.userLabs.filter((item) => item.checked);
    }
    return [];
  };

  const getSelectedLabNames = () => {
    if (selectedPatient && selectedPatient.userLabs) {
      return selectedPatient.userLabs
        .filter((lab) => lab.checked)
        .map((lab) => lab.test_name)
        .join(", ");
    }
    return "";
  };

  const [tubesSummary, setTubesSummary] = useState({});
  const tubeColors = {
    "Gel-Barrier": "#C25C22",
    "Lavender Top (EDTA) Whole Blood": "#9900FF", // Assuming 'dark lavender' as a hex color
    "Yellow-top (ACD-A)": "#F1C232", // Assuming 'darker yellow' as a hex color
    "Futhan Collection Kit": "#C25C22",
    "Blue-top (sodium citrate)": "#68BDBD",
    "Lavender Top (EDTA) Serum Separator": "#674EA7", // Assuming 'dark lavender' as a hex color
    // Add other tube types and their colors here
  };

  useEffect(() => {
    getTubeInfo();
  }, [selectedPatient]); // Run when selectedPatient changes

  const getTubeInfo = async () => {
    const treatment_id = selectedPatient["_id"]; // Ensure selectedPatient is defined and has _id

    try {
      const response = await axios.get(
        `/getLabTubesInfo/${treatment_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setTubesSummary(response.data.TubesSummary);
    } catch (error) {
      console.error("Error fetching tube info:", error);
      // Handle the error appropriately
    }
  };
  const updatePatientProfile = async (patientId, updatedDetails) => {
    try {
      const response = await fetch(
        `/patient-profiles/${patientId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify(updatedDetails),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      toastifyToast("Patient profile updated successfully", {
        type: "success",
      });
      const data = await response.json();
      // Handle successful update here
    } catch (error) {
      console.error("Error updating patient profile:", error);
    }
  };

  const handleSexChange = (newSex) => {
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, sex: newSex };
      updatePatientProfile(prevDetails._id, { sex: newSex });
      return updatedDetails;
    });
  };

  const handleG6PDChange = (event) => {
    const newG6PDValue = event.target.checked;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, G6PD: newG6PDValue };
      // Update the patient profile after setting the new state
      updatePatientProfile(prevDetails._id, updatedDetails);
      return updatedDetails;
    });
  };

  const handleConsentFormChange = (event) => {
    const consentFormValue = event.target.checked;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, ConsentForm: consentFormValue };
      // Update the patient profile after setting the new state
      updatePatientProfile(prevDetails._id, updatedDetails);
      return updatedDetails;
    });
  };

  const handleTxNotesChange = (event) => {
    const newTxNotes = event.target.value;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, Note: newTxNotes };
      updatePatientProfile(prevDetails._id, { Note: newTxNotes });
      return updatedDetails;
    });
  };

  const handleAllergiesChange = (event) => {
    const newAllergies = event.target.value;
    setSelectedPatientDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, Allergies: newAllergies };
      updatePatientProfile(prevDetails._id, { Allergies: newAllergies });
      return updatedDetails;
    });
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      const newDOB = date.toISOString();
      setSelectedDate(newDOB);
      setSelectedPatientDetails((prevDetails) => {
        const updatedDetails = { ...prevDetails, DOB: newDOB };
        updatePatientProfile(prevDetails._id, { DOB: newDOB });
        return updatedDetails;
      });
      setShowCalendar(!showCalendar)
    }
  };

  const handleChange = (value) => {
    console.log("Here Rooms:", value);
    let newSelectedRooms;
    if (value.includes("All Rooms") && value.length > 1) {
      newSelectedRooms = value.filter((room) => room !== "All Rooms");
    } else {
      newSelectedRooms = value;
    }

    setSelectedRooms(newSelectedRooms);

    if (from === "roomview") {
      // Save data to local storage whenever selectedRooms changes
      localStorage.setItem("selectedRooms", JSON.stringify(newSelectedRooms));
    }
  };
  const handleChangeAssignees = (value) => {
    let newSelectedAssignees;
    if (value.includes("Unassigned") && value.length > 1) {
      newSelectedAssignees = value.filter((assignee) => assignee !== "Unassigned");
    } else {
      newSelectedAssignees = value;
    }

    setSelectedAssignees(newSelectedAssignees);

    if (from === "roomview") {
      // Save data to local storage whenever selectedRooms changes
      localStorage.setItem("selectedAssignees", JSON.stringify(newSelectedAssignees));
    }
  };

  const [roomsData, setRoomsData] = useState(distinctRooms.map(room => ({
    room,
    patients: auditData.filter(item => item.room === room)
  })));

  const roomsOrder = [
    'unassigned',
    'pickup',
    'Community 1',
    'Community 2',
    'Room 1',
    'Room 2',
    'Room 3',
    'Room 4',
    'Education Room'
  ];

  const sortByRoomOrder = (a, b) => roomsOrder.indexOf(a.room) - roomsOrder.indexOf(b.room);

  useEffect(() => {
    const unassignedPatients = auditData.filter(item => (item.room === "" || item.room === "unassigned") && item.status !== "pickup");
    const assignedPatients = auditData.filter(item => (item.room !== "" && item.room !== "unassigned") && item.status !== "pickup");
    console.warn("********* assigned patients", assignedPatients)
    const assignedRoomsData = distinctRooms.filter(room => (room !== "" && room !== "unassigned")).map(room => ({
      room,
      patients: assignedPatients.filter(item => item.room === room)
    }));
    const pickupFiltered = auditData.filter(item => item.status === 'pickup')
    console.warn("********* assigned rooms data", distinctRooms, assignedRoomsData)
    console.warn("********* pickup filter", pickupFiltered)
    if (pickupFiltered.length > 0) {
      assignedRoomsData.push({
        room: "Pickup",
        patients: pickupFiltered
      });
    }
    if (unassignedPatients.length > 0) {
      assignedRoomsData.push({
        room: "unassigned",
        patients: unassignedPatients
      });
    }

    console.warn("********* assigned rooms data", distinctRooms, assignedRoomsData)

    assignedRoomsData.sort(sortByRoomOrder);
    setRoomsData(assignedRoomsData);
  }, [distinctRooms])





  useEffect(() => {
    console.warn("Rooms Data", roomsData)
  }, [roomsData])



  const onDragEnd = (result) => {
    const { source, destination } = result;
    // if (!destination ||
    //   (destination.droppableId === source.droppableId && destination.index === source.index)) {
    //   return;
    // }
    if (!destination) return;
    console.warn("******* on drag end", source, destination)
    const sourceRoomIndex = roomsData.findIndex(room => room.room === source.droppableId);
    const destinationRoomIndex = roomsData.findIndex(room => room.room === destination.droppableId);

    if (sourceRoomIndex === -1 || destinationRoomIndex === -1) return;
    if (source.droppableId === destination.droppableId) return;

    const sourceRoom = roomsData[sourceRoomIndex];
    const destinationRoom = roomsData[destinationRoomIndex];
    const [movedPatient] = sourceRoom.patients.splice(source.index, 1);
    destinationRoom.patients.splice(destination.index, 0, movedPatient);
    console.warn("*********** Source Room: ", sourceRoom, " Destination Room: ", destinationRoom, "moved Paitent", movedPatient)
    let payload;
    if (destination.droppableId === 'Pickup') {
      payload = {
        category: 'patientInfo',
        identifier: {
          key: "name",
          value: 'r',
        },
        updates: {
          status: 'pickup',
        },
      };
    }
    else {
      payload = {
        category: 'patientInfo',
        identifier: {
          key: "name",
          value: 'r',
        },
        updates: {
          room: destinationRoom.room,
        },
      };
    }

    axios
      .put(
        `/patient/order/update/${movedPatient._id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Success:", response.data);
        setTreatmentUpdate(true);
        toastifyToast.success("Room Updated Successfully")
        setRoomsData((prevState) => {
          let updatedRoomsData = prevState.map((room, index) => {
            if (index === sourceRoomIndex) return sourceRoom;
            if (index === destinationRoomIndex) return destinationRoom;
            return room;
          });

          let patientToMove;

          const updatedRooms = updatedRoomsData.map((roomGroup) => {
            const updatedPatients = roomGroup.patients.filter((patient) => {
              if (patient.PatientId === patientId) {
                patientToMove = { ...patient, room: room };
                return false;
              }
              return true;
            });
            return { ...roomGroup, patients: updatedPatients };
          });

          const newRooms = updatedRooms.map((roomGroup) => {
            if (roomGroup.room === room && patientToMove) {
              return {
                ...roomGroup,
                patients: [...roomGroup.patients, patientToMove],
              };
            }
            return roomGroup;
          });

          const sortedRooms = newRooms.sort((a, b) => {
            return roomsOrder.indexOf(a.room) - roomsOrder.indexOf(b.room);
          });

          return sortedRooms;
        });
        fetchPatient(movedPatient._id);
        handleClickPatient(movedPatient._id, movedPatient.PatientId);
      })
      .catch((error) => {
        console.error("Error:", error);
        toastifyToast.error("Error Updating Room")
      });
    // setRoomsData(roomsData.map((room, index) => {
    //   if (index === sourceRoomIndex) return sourceRoom;
    //   if (index === destinationRoomIndex) return destinationRoom;
    //   return room;
    // }));

  };

  useEffect(() => {
    console.warn("Rooms Data from /n useEffect", roomsData)
  }, [roomsData])
  return (
    <div className="audit-view">
      {loading || patientLoading ? (
        <div className="loading-container">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className={`${styles.prepviewHeader} mb-8 flex items-center`}>
            {from !== "roomview" && <h1 style={{ lineHeight: '40px' }}>Prep View</h1>}
            <div className="flex items-center">
              <div className="flex items-center gap-3 w-full">

                {!showByRoom ? (<div className="flex flex-col items-center gap-2 my-2 shadow-md py-1 px-2 bg-white rounded-lg w-fit">
                  <div className="flex gap-2 w-auto">
                    <div className="flex flex-row justify-between items-center w-[70px]">
                      {" "}
                      <Typography sx={{ fontSize: "0.55rem" }}>Scheduled</Typography>
                      <div className="bg-[#89cff0] p-2.5 rounded-md"></div>
                    </div>
                    <div className="flex flex-row justify-between items-center w-[70px]">
                      {" "}
                      <Typography sx={{ fontSize: "0.55rem" }}>Arrived</Typography>
                      <div className="bg-[orange] p-2.5 rounded-md"></div>
                    </div>
                  </div>
                  <div className="flex gap-2 w-auto">
                    <div className="flex flex-row justify-between items-center w-[70px]">
                      {" "}
                      <Typography sx={{ fontSize: "0.55rem" }}>Pickup</Typography>
                      <div className="bg-[#f6c4f8] p-2.5 rounded-md"></div>
                    </div>
                    <div className="flex flex-row justify-between items-center w-[70px]">
                      {" "}
                      <Typography sx={{ fontSize: "0.55rem" }}>Completed</Typography>
                      <div className="bg-[#00a36c] p-2.5 rounded-md"></div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center gap-3 w-[70px]">
                    {" "}
                    <Typography sx={{ fontSize: "0.55rem" }}>IV Prep Completed</Typography>
                    <div className="bg-[#B7E127] p-2.5 rounded-md"></div>
                  </div>
                </div>) : (<div className="flex flex-row items-center gap-3 my-2 shadow-md py-1 px-2 bg-white rounded-lg ">
                  <div className="flex flex-row items-center gap-3">
                    {" "}
                    <Typography sx={{ fontSize: "0.55rem" }}>Completed</Typography>
                    <div className="bg-[#00a36c] p-2.5 rounded-md"></div>
                  </div>

                  <div className="flex flex-row items-center gap-3 w-[110px]">
                    {" "}
                    <Typography sx={{ fontSize: "0.55rem" }}>IV Prep Completed</Typography>
                    <div className="bg-[#B7E127] p-2.5 rounded-md"></div>
                  </div>
                  <div className="flex flex-row items-center gap-3">
                    {" "}
                    <Typography sx={{ fontSize: "0.55rem" }}>Pickup</Typography>
                    <div className="bg-[#f6c4f8] p-2.5 rounded-md"></div>
                  </div>
                </div>)
                }

                <Select
                  className="min-w-[150px]"
                  mode="multiple"
                  allowClear
                  // placeholder="Select Rooms"
                  placeholder="All Rooms"
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  value={selectedRooms}
                  defaultValue="All Rooms"
                  disabled={lock}
                >
                  {/* <Select.Option key="All Rooms" value="All Rooms">
                    All Rooms
                  </Select.Option> */}
                  {roomOptions.map((room) => (
                    <Select.Option key={room} value={room}>
                      {room}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  className="min-w-[150px]"
                  mode="multiple"
                  allowClear
                  placeholder="Unassigned"
                  // placeholder="Select Assignees"
                  style={{ width: "100%" }}
                  onChange={handleChangeAssignees}
                  value={selectedAssignees}
                  defaultValue="unassigned"
                  disabled={lock}
                >
                  {/* <Select.Option key="unassigned" value="unassigned">
                    Unassigned
                  </Select.Option> */}
                  {assigneeOptions.map((assignee) => (
                    <Select.Option key={assignee} value={assignee}>
                      {assignee}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Button
                title={lock ? "Unlock Rooms" : "Lock Rooms"}
                className="ml-[5px] min-w-[32px]"
                onClick={handleLockToggle}
                style={lock && { borderColor: '#4096ff' }}
                icon={lock ? <LockOutlined style={{ color: '#4096ff' }} /> : <UnlockOutlined />}
              ></Button>
              <div >
                {/* <Switch
                  checked={showNameInitials}
                  className="bg-gray-300 ml-4 min-w-[100px]"
                  disabled={lock}
                  onChange={() => {
                    setShowNameInitials(!showNameInitials)
                  }}
                  checkedChildren="Show Initials"
                  unCheckedChildren="Show Initials"
                /> */}
                <Checkbox className="flex flex-row items-center ml-4 min-w-[100px]" checked={showNameInitials} disabled={lock} onChange={() => {
                  setShowNameInitials(!showNameInitials)
                }}>
                  <Typography sx={{ fontSize: 16, width: 'max-content' }}>
                    Initials Only
                  </Typography>
                </Checkbox>
              </div>
              <div >
                {/* <Switch
                  checked={showNameInitials}
                  className="bg-gray-300 ml-4 min-w-[100px]"
                  disabled={lock}
                  onChange={() => {
                    setShowNameInitials(!showNameInitials)
                  }}
                  checkedChildren="Show Initials"
                  unCheckedChildren="Show Initials"
                /> */}
                <Checkbox className="flex flex-row items-center ml-4 min-w-[100px]" checked={hideCompleted} disabled={lock} onChange={() => {
                  setHideCompleted(!hideCompleted)

                }}>
                  <Typography sx={{ fontSize: 16, width: 'max-content' }}>
                    Hide Completed
                  </Typography>
                </Checkbox>
              </div>
              <div >
                <Checkbox className="flex flex-row items-center ml-4 min-w-[100px]" checked={showByRoom} disabled={lock} onChange={() => {
                  setShowByRoom(!showByRoom)
                }}>
                  <Typography sx={{ fontSize: 16, width: 'max-content' }}>
                    By Room
                  </Typography>
                </Checkbox>
              </div>
            </div>

            <div className={styles.dateDisplay_flexPrep}>
              <div className="flex flex-row gap-2 items-center">
                <Tooltip title={"Show Calendar"}>
                  <CalendarMonth
                    ref={iconRef}
                    style={{
                      color: "#757575",
                      cursor: "pointer",

                      fontSize: 30,
                    }}
                    onClick={() => {
                      handleIconClick();
                      setShowCalendar(!showCalendar);
                    }}
                  />
                </Tooltip>

                <div className={styles.dateSelectAndDisplay}>
                  <IconButton onClick={() => navigateDate(-1)}>
                    <img src={leftArrow} alt="image" />
                  </IconButton>
                  <span className={styles.dateDisplay}>
                    {moment(selectedDate).format("dddd MMMM Do, YYYY")}
                  </span>
                  <IconButton onClick={() => navigateDate(1)}>
                    <img src={rightArrow} alt="image" />
                  </IconButton>
                </div>
              </div>
              <div>{buttonsDataLoading && <Spin />}</div>
            </div>
          </div>
          {showPatientsButtonSkeleton && previousAuditData.current == null ?
            <div className="patient-buttons-container">
              <Skeleton variant="rounded" width={210} height={60} />
              <Skeleton variant="rounded" width={210} height={60} />
              <Skeleton variant="rounded" width={210} height={60} />
            </div>
            :
            <>
              {showCalendar && (
                <div
                  ref={calendarRef}
                  style={{
                    position: "absolute",
                    top: `${calendarPosition.top}px`,
                    left: `${calendarPosition.left}px`,
                    zIndex: 1,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Calendar
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </div>
              )}
              {auditData.length === 0 ? (
                <>
                  <div className="patient-buttons-container">
                    {from === "roomview" && room ? (
                      <p>No Treatments for today for selected room</p>
                    ) : (
                      <p>No Treatments for today</p>
                    )}
                  </div>
                </>

              ) : (
                < >
                  {showByRoom ?

                    <div className="flex flex-col gap-2">
                      <>

                        <DragDropContext onDragEnd={onDragEnd}>
                          <Grid container justifyContent={'center'}>
                            {roomsData.filter(item => item.room === 'unassigned' || item.room === '').map((data) => {
                              const filteredPatients = hideCompleted ? data.patients.filter(patient => patient.status !== 'complete') : data.patients;
                              return data.patients.length > 0 && <Droppable droppableId={data.room} key={data.room} direction="vertical">
                                {(provided) => (
                                  <Grid item
                                    xs={12}
                                    key={data.room}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      position: 'relative'
                                    }}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    {/* {showRefreshingLoader && <Box sx={{ position: 'absolute', top: 6, right: 6 }}>
                                      <CircularProgress size={25} sx={{ color: 'gray' }} />

                                    </Box>} */}
                                    {showRefreshingLoader && <Box sx={{ position: 'absolute', top: 0, right: 0, width: '100%' }}>
                                      {/* <CircularProgress size={25} sx={{ color: 'gray' }} /> */}
                                      <LinearProgress sx={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} />

                                    </Box>}

                                    <RoomSection room={data.room} patients={filteredPatients} showNameInitials={showNameInitials} fetchPatient={fetchPatient} handleClickPatient={handleClickPatient} inactive={inactive} selectedRooms={selectedRooms} roomsData={roomsData} setRoomsData={setRoomsData} toastifyToast={toastifyToast} menuRoom={menuRoom} setMenuRoom={setMenuRoom} />
                                    <div
                                      style={{
                                        height: 0,
                                        margin: 0,
                                        padding: 0,
                                        visibility: 'hidden'
                                      }}
                                    >
                                      {provided.placeholder}
                                    </div>
                                  </Grid>
                                )}
                              </Droppable>
                            }

                            )}
                          </Grid>


                          <Grid container spacing={2} justifyContent={'center'}>
                            {roomsData?.filter(item => item.room !== 'unassigned' && item.room !== '').map((data) => {
                              const filteredPatients = hideCompleted ? data.patients.filter(patient => patient.status !== 'complete') : data.patients;

                              return data.patients.length > 0 && <Droppable droppableId={data.room} key={data.room} direction="vertical">
                                {(provided) => (
                                  <Grid item
                                    xs={(roomsData.some(item => item['room'] === 'unassigned')) ? (roomsData.length - 1 === 8 ? 1.5 : roomsData.length - 1 === 7 ? 1.7 : roomsData.length - 1 === 6 ? 2 : roomsData.length - 1 === 5 ? 2.4 : roomsData.length - 1 === 4 ? 3 : roomsData.length - 1 === 3 ? 4 : roomsData.length - 1 === 2 ? 4 : 4) : (roomsData.length === 8 ? 1.5 : roomsData.length === 7 ? 1.7 : roomsData.length === 6 ? 2 : roomsData.length === 5 ? 2.4 : roomsData.length === 4 ? 3 : roomsData.length === 3 ? 4 : roomsData.length === 2 ? 4 : 4)}
                                    // xs={1.5} 
                                    key={data.room}
                                    style={{ display: 'flex', flexDirection: 'column' }} ref={provided.innerRef} {...provided.droppableProps}>
                                    <RoomSection room={data.room} patients={filteredPatients} showNameInitials={showNameInitials} fetchPatient={fetchPatient} handleClickPatient={handleClickPatient} inactive={inactive} selectedRooms={selectedRooms} roomsData={roomsData} setRoomsData={setRoomsData} toastifyToast={toastifyToast} menuRoom={menuRoom} setMenuRoom={setMenuRoom} />
                                    <div
                                      style={{
                                        height: 0,
                                        margin: 0,
                                        padding: 0,
                                        visibility: 'hidden',
                                      }}
                                    >
                                      {provided.placeholder}
                                    </div>
                                  </Grid>
                                )}
                              </Droppable>
                            })}

                          </Grid>

                        </DragDropContext>
                      </>
                    </div>
                    :
                    <div className="patient-buttons-container relative">
                      {showRefreshingLoader && <Box sx={{ position: 'absolute', top: 0, right: 0, width: '100%' }}>
                        {/* <CircularProgress size={25} sx={{ color: 'gray' }} /> */}
                        <LinearProgress sx={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} />

                      </Box>}
                      {auditData.map((item) => (
                        <Button
                          key={item._id}
                          className={`patient-button mb-2 ${inactive ? "blur-sm" : ""}`}
                          onClick={() => {
                            fetchPatient(item.PatientId);
                            handleClickPatient(item._id, item.PatientId);
                          }}

                          style={{
                            backgroundColor:
                              showByRoom ? item.status === "complete" ? "#00a36c" :
                                item.iv_prep === "complete" ? "#B7E127" :
                                  item.status === "pickup" ? "#f6c4f8" : "#ffd6a5" :
                                item.status === "complete" ? "#00a36c" :
                                  item.iv_prep === "complete" ? "#B7E127" :
                                    item.status === "scheduled" ? "#89cff0" :
                                      item.status === "arrived" ? "orange" :
                                        item.status === "pickup" ? "#f6c4f8" : "#ffd6a5"
                          }}
                        // disabled={isTreatmentNoteEditing}
                        >
                          {showNameInitials ? item.patientName.split(' ').map(word => word[0].toUpperCase()).join('') : item.patientName} {!showByRoom && ((selectedRooms[0] === "All Rooms" || selectedRooms.length === 0) ? (item.room ? `- ${item.room}` : "unassigned") : "")}-{" "}
                          {item.custom}
                        </Button>
                      ))}
                    </div>
                  }
                </>
                // <div></div>
              )}

              {/* {showByRoom && <Grid container spacing={2} justifyContent={'center'}>
                {distinctRooms.length > 0 && distinctRooms.filter(item => item !== 'unassigned' && item !== '').map((room) => (
                  <Grid item xs={1.7} key={room} style={{ display: 'flex', flexDirection: 'column' }}>
                    <RoomSection room={room} patients={auditData.filter(item => item.room === room)} showNameInitials={showNameInitials} fetchPatient={fetchPatient} handleClickPatient={handleClickPatient} inactive={inactive} selectedRooms={selectedRooms} />
                  </Grid>
                ))
                }
              </Grid>} */}
              {/* {showByRoom &&
                <DragDropContext onDragEnd={onDragEnd}>
                  <div className="flex flex-col items-center gap-3">
                    <Grid container spacing={2} justifyContent={'center'}>
                      {roomsData?.map((data) => {
                        // const filteredArray = roomsData?.filter(item => item.room !== 'Education Room').length;
                        return data.patients.length > 0 && <Droppable droppableId={data.room} key={data.room} direction="vertical">
                          {(provided) => (
                            <Grid item
                              xs={roomsData.length === 7 ? 1.7 : roomsData.length === 6 ? 2 : roomsData.length === 5 ? 2.4 : roomsData.length === 4 ? 3 : roomsData.length === 3 ? 4 : roomsData.length === 2 ? 4 : 4}
                              // xs={1.5} 
                              key={data.room}
                              style={{ display: 'flex', flexDirection: 'column' }} ref={provided.innerRef} {...provided.droppableProps}>
                              <RoomSection room={data.room} patients={data.patients} showNameInitials={showNameInitials} fetchPatient={fetchPatient} handleClickPatient={handleClickPatient} inactive={inactive} selectedRooms={selectedRooms} setRoomsData={setRoomsData} toastifyToast={toastifyToast} />
                              {provided.placeholder}
                            </Grid>
                          )}
                        </Droppable>
                      })}

                    </Grid>

                  </div>
                </DragDropContext>
              } */}
            </>}
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                width: "100%",
                height: "auto",
                padding: "20px",
              },
            }}
          >
            <DialogContent>
              <div className="flex justify-start items-center">
                {" "}
                <CampaignIcon style={{ fontSize: 56 }} />
                <p className="ml-[40px]" style={{ fontSize: 22 }}>
                  Please accept Consent form for {currentPatient?.["Full Name"]}
                </p>
              </div>
            </DialogContent>
          </Dialog>

          {selectedPatient && (
            <div className="patient-action-container">
              <h3>Patient Information</h3>
              <div className={`${styles.patientDetailsContainer} mb-7`}>
                <div className={styles.patientInformtaionFlexPrep}>
                  {/* Div start */}
                  <div className="selected-patient-content">
                    {selectedPatient.patientName && (
                      <p
                        className={`${styles.selected_patient_contentTextPrep} leading-relaxed `}
                      >
                        <span className={styles.patientContentPlaceholder}>
                          Name
                        </span>{" "}
                        <br></br>
                        <span
                          className={`font-extrabold ${inactive ? "blur-sm" : ""
                            }`}
                        >
                          {showNameInitials ? selectedPatient.patientName.split(' ').map(word => word[0].toUpperCase()).join('') : selectedPatient.patientName}
                        </span>
                      </p>
                    )}
                    {selectedPatient.room ? (
                      <p
                        className={`${styles.selected_patient_contentTextPrep} leading-relaxed `}
                      >
                        <span
                          className={`${styles.patientContentPlaceholder} mb-6`}
                        >
                          Room
                        </span>{" "}
                        <br></br>
                        <span className="font-semibold">
                          {selectedPatient.room}
                        </span>
                      </p>
                    ) : (
                      <p
                        className={`${styles.selected_patient_contentTextPrep} leading-relaxed `}
                      >
                        <span
                          className={`${styles.patientContentPlaceholder} mb-6`}
                        >
                          Room
                        </span>{" "}
                        <br></br>
                        <span className="font-semibold">Unassigned</span>
                      </p>
                    )}
                    <div
                      className={`${styles.editableField} mt-2 leading-relaxed`}
                    >
                      <span className={styles.patientContentPlaceholder}>
                        Sex
                      </span>
                      <br></br>
                      <Radio.Group
                        onChange={(e) => handleSexChange(e.target.value)}
                        value={selectedPatientDetails.sex}
                      >
                        <Radio value="Male">Male</Radio>
                        <Radio value="Female">Female</Radio>
                      </Radio.Group>
                    </div>

                    {selectedPatient.custom && (
                      <p
                        className={`${styles.selected_patient_contentTextPrep} leading-relaxed `}
                      >
                        <span className={styles.patientContentPlaceholder}>
                          Custom Info
                        </span>{" "}
                        <br></br>
                        <span className="font-semibold">
                          {selectedPatient.custom}
                        </span>
                      </p>
                    )}
                  </div>
                  {/* g6pd and dates */}
                  <div>
                    <div className="selected-patient-content">
                      {/* Container for checkboxes */}
                      {/* G6PD Checkbox */}
                      <div className={styles.editableField}>
                        <label className={`${styles.checkboxLabel}`}>
                          <span className="selected_patient_contentTextPrep leading-relaxed font-semibold">
                            G6PD Cleared:
                          </span>
                          <input
                            type="checkbox"
                            className="checkboxStyle w-5 h-5"
                            checked={selectedPatientDetails.G6PD || false}
                            onChange={handleG6PDChange}
                          />
                        </label>
                      </div>

                      <div className={styles.editableField}>
                        <label className={`${styles.checkboxLabel}`}>
                          <span className="selected_patient_contentTextPrep leading-relaxed font-semibold">
                            Patient Consent Form:
                          </span>
                          <input
                            type="checkbox"
                            className="checkboxStyle w-5 h-5"
                            checked={
                              selectedPatientDetails.ConsentForm || false
                            }
                            onChange={handleConsentFormChange}
                          />
                        </label>
                      </div>
                      <div className="patient-info">
                        <p
                          className={`${styles.selected_patient_contentTextPrep} leading-relaxed`}
                        >
                          <span className={styles.patientContentPlaceholder}>
                            Last Visited <br></br>
                          </span>{" "}
                          <span className="font-semibold">
                            {moment(selectedPatient.lastVisit).format(
                              "MM/DD/YYYY"
                            )}
                          </span>
                        </p>
                        <p
                          className={`${styles.selected_patient_contentTextPrep} leading-relaxed`}
                        >
                          <span className={styles.patientContentPlaceholder}>
                            Selected Treatment Date <br></br>
                          </span>{" "}
                          <span className="font-semibold">
                            {/* Date Update #2 */}
                            {moment.utc(selectedPatient.date).format("MM/DD/YYYY")}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* iv notes */}
                  <div className="selected-patient-content">
                    <div
                      className={`${styles.editableField}  mt-2 leading-relaxed`}
                    >
                      <span className={`${styles.patientContentPlaceholder}`}>
                        Tx Notes
                      </span>
                      <br></br>
                      <input
                        type="text"
                        className="py-2 px-2"
                        defaultValue={selectedPatientDetails.Note || ""}
                        onBlur={handleTxNotesChange}
                      />
                    </div>

                    <div
                      className={`${styles.editableField} mt-2 leading-relaxed`}
                    >
                      <span className={`${styles.patientContentPlaceholder}`}>
                        IV Note
                      </span>
                      <br></br>
                      <input
                        type="text"
                        className="py-4 px-2"
                        value={ivNote}
                        onChange={(event) => {
                          // Update the local value (ivNote) when the input value changes
                          setIVNote(event.target.value);
                        }}
                        onBlur={() => {
                          // Call handleIVNoteChange when the input field loses focus (onBlur)
                          handleIVNoteChange();
                        }}
                      />
                    </div>
                  </div>
                  {/* inputs */}
                  <div className="selected-patient-content inputPatient-infoPrep mt-2">
                    <div>
                      <span
                        className={`${styles.patientContentPlaceholder} leading-relaxed`}
                      >
                        Select Room:
                      </span>
                      <br></br>
                      <Select
                        value={treatmentRoom} // Set the default value to the current room value
                        style={{
                          marginTop: "4px",
                          width: "100%",
                          height: 44,
                        }}
                        onChange={(e) => {
                          settreatmentRoom(e);
                          handleCellChange(
                            treatmentID,
                            "room",
                            e,
                            "patientInfo"
                          );
                        }}
                      >
                        <MenuItem key="empty-option" value="">
                          Empty
                        </MenuItem>
                        {roomOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mt-1">
                      <span className={`${styles.patientContentPlaceholder}`}>
                        Assignee:
                      </span>
                      <br></br>
                      <div className={styles.roomAndAssignee_Prep}>
                        {/* <Select
                          value={treatmentRoom} // Set the default value to the current room value
                          style={{ width: "100%" }} // Adjust width as needed
                          onChange={(e) => {
                            settreatmentRoom(e);
                            handleCellChange(
                              treatmentID,
                              "room",
                              e,
                              "patientInfo"
                            );
                          }}
                        >
                          <MenuItem key="empty-option" value="">
                            Empty
                          </MenuItem>
                          {roomOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select> */}

                        <Select
                          mode="multiple" // Enable multiple selections
                          value={treatmentAssignees || []} // Use the assignees from the record
                          onChange={(valueArray) => {
                            handleCellChange(
                              treatmentID,
                              "assignees",
                              valueArray,
                              "patientInfo"
                            );
                            settreatmentAssignees(valueArray);
                          }}
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          {assigneeOptions.map((assignee, index) => (
                            <Select.Option key={index} value={assignee}>
                              {assignee}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  {/* Allergy and dob */}
                  <div className="selected-patient-content inputPatient-infoPrep mt-2">
                    <div>
                      <div className={styles.editableField}>
                        <span className={styles.patientContentPlaceholder}>
                          Allergies
                        </span>{" "}
                        <br></br>
                        <input
                          type="text"
                          className="py-4 px-2"
                          defaultValue={selectedPatientDetails.Allergies}
                          onBlur={handleAllergiesChange} // Define this function to handle the change
                        />
                      </div>
                    </div>
                    <div>
                      <div className={`${styles.editableField} mt-2`}>
                        <span className={`${styles.patientContentPlaceholder}`}>
                          DOB:
                        </span>
                        <DatePicker
                          key={selectedPatientDetails.DOB}
                          format="MM-DD-YYYY"
                          value={
                            selectedPatientDetails.DOB
                              ? dayjs(selectedPatientDetails.DOB)
                              : undefined
                          }
                          style={{
                            backgroundColor: "#F2F2F2",
                            borderRadius: "6px",
                            fontSize: "18px",
                            marginTop: "8px",
                            fontWeight: "500",
                            lineHeight: "19px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            width: "100%",
                            height: "44px",
                            border: "none",
                          }}
                          onChange={handleDateChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedPatient && (
            <>
              <div className="flex items-center mb-4 justify-between">
                {allTreatments.length > 0 && (
                  <>
                    <div>
                      <Button
                        className="btn-color h-12 py-2 px-3 text-xs md:text-sm w-auto"
                        type="default"
                        style={{
                          marginLeft: "10px",
                          backgroundColor:
                            selectedPatient.review === "true"
                              ? "#B7E127"
                              : "#ffd6a5",
                        }}
                        onClick={fetchLatestTreatments}
                      >
                        Show Latest Treatments
                      </Button>
                    </div>
                  </>
                )}

                <div className="flex flex-row items-center gap-3 shadow-lg py-2 px-4 bg-white rounded-lg">
                  {" "}
                  <Typography>Current Selected Treatment</Typography>
                  <div className="matching-row p-4"></div>
                  <Typography>Order</Typography>
                  <div className="order-row p-4"></div>
                </div>
              </div>

              <div className="treatmentView-Table-prep flex flex-col ">
                {allTreatments.length > 0 && (
                  <Provider>
                    <MultipleTreatmentsView
                      allTreatments={allTreatments}
                      setAllTreatments={setAllTreatments}
                      handleRefresh={handleRefresh}
                      toastifyToast={toastifyToast}
                      treatmentId={treatmentID}
                      selectedDateCurrent={selectedPatient.date}
                      setRoomsData={setRoomsData}
                    // setTreatmentUpdate={setTreatmentUpdate}
                    />
                  </Provider>
                )}
                {(!allTreatments.length > 0 || showSkeleton) && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      mb: 5,
                    }}
                  >
                    <Skeleton variant="rounded" width={170} height={160} />
                    <Skeleton variant="rounded" width={170} height={160} />
                    <Skeleton variant="rounded" width={170} height={160} />
                    <Skeleton variant="rounded" width={170} height={160} />
                    <Skeleton variant="rounded" width={170} height={160} />
                    <Skeleton variant="rounded" width={170} height={160} />
                    <Skeleton variant="rounded" width={170} height={160} />
                    <Skeleton variant="rounded" width={170} height={160} />
                  </Box>
                )}
                {allTreatments.length > 0 && (
                  <div
                    className="flex items-center gap-4"
                    style={{ margin: "0 auto" }}
                  >
                    <Button
                      className="btn-color h-12 py-2 px-3 text-xs md:text-sm w-auto"
                      type="default"
                      style={{
                        marginLeft: "10px",
                        backgroundColor:
                          selectedPatient.review === "true"
                            ? "#B7E127"
                            : "#ffd6a5",
                      }}
                      onClick={fetchPreviousTreatments}
                    >
                      Show More Treatments
                    </Button>
                    {allTreatments && allTreatments.length > 1 && (
                      <Button
                        className="btn-color h-12 py-2 px-3 text-xs md:text-sm w-auto"
                        type="default"
                        style={{
                          marginLeft: "10px",
                          backgroundColor:
                            selectedPatient.review === "true"
                              ? "#B7E127"
                              : "#ffd6a5",
                        }}
                        onClick={handleHideAllOtherTreatments}
                      >
                        Hide All Other Treatments
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          <div className="table-flex-prepview">
            <div></div>
            <div className="tables-treatments-prep">
              {/* All buttons */}

              {selectedPatient && (
                <>
                  <div
                    className="action-buttons-container"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <button
                      className="btn-color h-12 py-2 px-3 text-xs md:text-sm w-auto"
                      type="default"
                      style={{
                        marginLeft: "10px",
                        backgroundColor:
                          selectedPatient.review === "true"
                            ? "#B7E127"
                            : "#ffd6a5",
                      }}
                      onClick={toggleModal}
                    >
                      Final Review Complete
                    </button>
                  </div>
                </>
              )}

              {/* procedures and dispensed */}
              <div>
                {selectedPatient && (
                  <div className="table-proced-dispense-prepFlex">
                    <div className="">
                      {/* Table 3: Labs and filters */}
                      <div
                        className="action-buttons-container"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      >
                        {/* In progress */}
                        <button
                          type="default"
                          className={`py-2 h-10 px-3 text-xs md:text-sm w-auto ${areAllLabsPrepared() ? "green-btn" : "btn-color"
                            }`}
                          style={{ marginLeft: "10px" }}
                          onClick={handlePrepareAllLab}
                        >
                          Prepare All Labs
                        </button>
                        <button
                          type="default"
                          className={`py-2 h-10 px-3 text-xs md:text-sm w-auto ${!selectedPatientDetails.ConsentForm
                            ? "btn-color-red"
                            : areAllLabsProcessed()
                              ? "green-btn"
                              : "btn-color"
                            }`}
                          style={{ marginLeft: "10px" }}
                          onClick={handleAllLabsProcessed}
                        >
                          All Labs Processed
                        </button>
                      </div>
                      <Table
                        dataSource={filterLabs()}
                        columns={labsColumns}
                        rowKey="_id"
                        pagination={false}
                        className="audit-table"
                      />
                    </div>

                    {/* Table 1: Procedures and filters */}
                    <div>
                      <div
                        className="action-buttons-container"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: "10px",
                        }}
                      >
                        {/* Done + Tested */}
                        <button
                          type="default"
                          className={`py-2 h-10 px-3 text-xs md:text-sm w-auto ${areAllProceduresPrepared()
                            ? "green-btn"
                            : "btn-color"
                            }`}
                          style={{ marginLeft: "10px" }}
                          onClick={handlePrepareAllProcedure}
                        >
                          Prepare All Procedure
                        </button>
                        {/* Done + Tested */}
                        <button
                          className={`${selectedPatientDetails.ConsentForm
                            ? "btn-color"
                            : "btn-color-red"
                            } h-10 py-1 px-3 ml-1 text-xs md:text-sm w-auto`}
                          type="primary"
                          onClick={handleAdministerAll}
                        >
                          Administer All
                        </button>
                      </div>
                      <Table
                        dataSource={filterProcedures()}
                        columns={proceduresColumns}
                        rowKey="_id"
                        pagination={false}
                        className="audit-table"
                      />
                    </div>
                    {/* Table 2: Dispensed and filter  */}
                    <div>
                      <div
                        className="action-buttons-container"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: "10px",
                        }}
                      >
                        <button
                          type="default"
                          className={`py-2 h-10 px-3 text-xs md:text-sm w-auto ${
                            // !selectedPatientDetails.ConsentForm
                            //   ? "btn-color-red"
                            //   :
                            areAllDispensedPrepared()
                              ? "green-btn"
                              : "btn-color"
                            }`}
                          style={{ marginLeft: "10px" }}
                          onClick={handlePrepareAllDispensed}
                        >
                          Prepare All Dispensed Item
                        </button>
                        <button
                          className={`${selectedPatientDetails.ConsentForm
                            ? "btn-color"
                            : "btn-color-red"
                            } h-10 py-1 px-3 ml-1 text-xs md:text-sm w-auto`}
                          type="primary"
                          onClick={handleDispensedAllClick}
                        >
                          Dispensed All
                        </button>
                      </div>
                      <Table
                        dataSource={filterDispensedItems()}
                        columns={dispensedColumns}
                        rowKey="_id"
                        pagination={false}
                        className="audit-table"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="w-[50%]">
                {selectedPatient && getSelectedLabNames() && (
                  <div className="selected-labs-container">
                    <div className="selected-labs-title">Labs</div>
                    <div className="selected-labs-content">
                      {/* {getSelectedLabNames()} */}
                      <p></p>
                      <div>
                        <div className="tubeInfoContainer">
                          {tubesSummary &&
                            Object?.entries(tubesSummary)?.map(
                              ([tubeType, quantity]) => (
                                <div
                                  key={tubeType}
                                  className="tubeInfoItem"
                                  style={{
                                    backgroundColor:
                                      tubeColors[tubeType] || "black",
                                  }}
                                >
                                  <span>{tubeType}</span>
                                  <span>Quantity: {quantity}</span>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedPatient && selectedPatient.vitals && (
                  <>
                    <div className="vitals-container">
                      <div className="flex flex-row justify-between items-center">
                        <h3 className="my-6">Vitals</h3>
                        <button
                          className="btn-color px-1.5 py-0.5 mt-[-5px] mb-2"
                          onClick={() =>
                            handleOpenVitalsModal(selectedPatient._id)
                          }
                        >
                          <PlusOutlined></PlusOutlined>
                        </button>
                      </div>
                      <table className="vitals-table">
                        <thead>
                          <tr className="rounded">
                            <th>Time</th>
                            <th>BP</th>
                            <th>HR</th>
                            <th>RR</th>
                            <th>SPO2</th>
                            <th>T (°C)</th>
                            <th>W (kg)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedPatient.vitals.map((vital, index) => (
                            <tr key={index}>
                              <td>
                                {new Date(vital.time).toLocaleTimeString()}
                              </td>
                              <td>{vital.BP}</td>
                              <td>{vital.HR}</td>
                              <td>{vital.RR}</td>
                              <td>{vital.SPO2}</td>
                              <td>{vital.T}</td>
                              <td>{vital.W}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <VitalsModal
                      open={vitalsModalOpen}
                      VitalsItems={currentVitalsItems}
                      onClose={() => setVitalsModalOpen(false)}
                      patientId={selectedPatient._id}
                      handleCellChange={handleCellChange}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="tables-treatments-Note max-w-[2000px]">
            {selectedPatient && (
              <>
                <TreatmentNotes
                  treatment={selectedPatient}
                  isTreatmentNoteEditing={isTreatmentNoteEditing.current}
                  setIsTreatmentNoteEditing={setIsTreatmentNoteEditing}
                  treatmentUpdate={treatmentUpdate}
                  setTreatmentUpdate={setTreatmentUpdate}
                  toastifyToast={toastifyToast}
                />
              </>
            )}
          </div>
        </div>
      )
      }
      <ToastContainer />
      {
        isModalVisible && (
          <Modal
            title={
              <span>
                <SolutionOutlined style={{ marginRight: 8 }} />
                Confirm Treatment
              </span>
            }
            visible={isModalVisible}
            onCancel={toggleModal}
            footer={[
              <Button
                key="back"
                onClick={toggleModal}
                icon={<CloseCircleOutlined />}
                style={{ borderColor: "red", color: "red" }}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                onClick={handleReviewChange}
                icon={<CheckCircleOutlined />}
                style={{ borderColor: "#52c41a", color: "#52c41a" }}
              >
                Confirm
              </Button>,
            ]}
          >
            <p style={{ fontSize: 16 }}>
              Are you sure you want to confirm the treatment and send the details
              to the front desk?
            </p>
          </Modal>
        )
      }

    </div >
  );
};

export default AuditView;