import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./labsModel.css";
import { BarLoader, BounceLoader } from "react-spinners";
import { Button, Table, Select, DatePicker, Input, Popconfirm } from "antd";
import { Modal as AntdModal } from "antd";
import {
  UpOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

import { Select as MuiSelect } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  Checkbox,
  Modal,
} from "@mui/material";
import { MenuItem, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";

import styles from "./styles.module.css";

import ProcedureModal from "../../Components/ProcedureModal";
import DispenseModal from "../../Components/DispenseModal";
import VitalsModal from "../../Components/VitalsModal";
import { DataContext } from "../../context/DataContext";
import {
  dispensesSortingFunction,
  getLabDisplayName,
  labsSortingFunction,
  proceduresSortingFunction,
} from "../../utils";
import TreatmentNotes from "../../Components/TreatmentNotes";

const { Option } = Select;

// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750, // You can adjust the width as needed
  maxHeight: "80vh", // Adjust the max height as needed
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "& .MuiTypography-h6": {
    // Targeting Typography with variant h6
    fontSize: "1rem", // Maintained decreased font size
  },
  "& .MuiListItemText-primary": {
    fontSize: "0.9rem", // Setting font size for primary text
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Add ellipsis for overflowed text
  },
  "& .MuiCheckbox-root": {
    // Targeting Checkbox
    padding: "2px", // Reduced padding for a smaller checkbox
    "& svg": {
      // Targeting the SVG icon in checkbox
      fontSize: "1rem", // Reduced icon size
    },
  },
  "& .MuiGrid-container": {
    rowGap: 1, // Adjust row spacing if needed
  },
  "& .MuiGrid-item": {
    display: "flex", // Ensure the items in grid are flex containers
    alignItems: "center", // Align items vertically in the center
  },
};

const SingleTreatmentView = ({ treatments, setTreatmentUpdate }) => {
  const {
    data: {
      labs: contextLabs,
      dispensed: contextDispensed,
      procedures: contextProcedures,
    },
  } = useContext(DataContext);

  const [sortedTreatments, setSortedTreatments] = useState([]);

  const [loader, setLoader] = useState(false);
  const [customNote, setCustomNote] = useState();
  const [roomOptions, setRoomOptions] = useState([]);
  const [newLabsMap, setNewLabsMap] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [labsModalOpen, setLabsModalOpen] = useState(false);
  const [procedureModalOpen, setProcedureModalOpen] = useState(false);
  const [dispenseModalOpen, setDispenseModalOpen] = useState(false);
  const [vitalsModalOpen, setVitalsModalOpen] = useState(false);
  const [assigneeOptions, setassigneeOptions] = useState([]);

  const [currentProcedures, setCurrentProcedures] = useState([]);
  const [currentDispensedItems, setCurrentDispensedItems] = useState([]);
  const [currentVitalsItems, setCurrentVitalsItems] = useState([]);
  const [treatmentNoteModalOpen, setTreatmentNoteModalOpen] = useState(false);
  const [currentTreatment, setCurrentTreatment] = useState();

  const isTreatmentNoteEditing = useRef(false);

  const [currentPatientIdForModal, setCurrentPatientIdForModal] =
    useState(null);

  const [currentPatientLabs, setCurrentPatientLabs] = useState({
    labs: [],
    orderId: null,
  });
  const navigate = useNavigate();

  const dataReset = async () => {
    if (!treatments || !treatments._id) {
      console.error("No treatment ID available for data reset");
      return;
    }

    try {
      const response = await axios.get(
        `/orderget/${treatments._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.data) {
        const formattedData = formatDataForTable(response.data);
        setSortedTreatments([formattedData]); // Update the sortedTreatments array
        // Any other state updates needed after fetching new data can be added here
      }
    } catch (error) {
      console.error("Error fetching updated treatment data:", error);
    }
  };

  useEffect(() => {
    axios
      .get("/get-Assignee-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      }) // Replace with your actual API endpoint
      .then((response) => {
        setassigneeOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
        // Handle the error appropriately in your application
      });
  }, []);

  const handleCustomNoteSelect = (value) => {
    setCustomNote(value);
  };

  const handleNoteUpdate = async (key, newNote) => {
    // Construct the payload
    const payload = {
      note: newNote,
    };

    // API call to update the note
    try {
      const response = await axios.put(
        `/patient/order/update-note/${key}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      
      dataReset();
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };
  const NoteInput = ({ defaultValue, recordKey }) => {
    const [noteVal, setNoteVal] = useState(defaultValue);
    const [inputWidth, setInputWidth] = useState("100px");

    const handleFocus = () => {
      setInputWidth("auto");
    };

    const handleBlur = () => {
      setInputWidth("100px");
      handleNoteUpdate(recordKey, noteVal); // Assuming handleNoteUpdate is defined elsewhere
    };

    const handleChange = (e) => {
      setNoteVal(e.target.value);
    };

    return (
      <Input
        value={noteVal} // Changed from defaultValue to value
        style={{ minWidth: "100px", width: inputWidth }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange} // Updated the onChange handler
      />
    );
  };
  const fetchRoomOptions = async () => {
    //Get available rooms
    axios
      .get("/get-room-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      }) // Replace with your actual API endpoint
      .then((response) => {
        setRoomOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
        // Handle the error appropriately in your application
      });
  };

  const handlePatientClickTreatmentView = (e, patientId2) => {
    e.preventDefault(); // Prevent default link behavior
    navigate(`/treatmentviewdisplay/${patientId2}`);
  };

  const columns = [
    {
      title: (
        <div
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            letterSpacing: "0em",
            fontFamily: "Raleway, sans-serif",
            textAlign: "left",
          }}
        >
          Patient Name
        </div>
      ),
      dataIndex: "patientName",
      key: "patientName",
      render: (text, record) => (
        <a
          href="/treatmentviewdisplay"
          onClick={(e) => {
            const patient = treatments;
            if (treatments) {
              handlePatientClickTreatmentView(e, treatments.PatientId);
            }
          }}
          style={{ color: "blue" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: (
        <div
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            letterSpacing: "0em",
            fontFamily: "Raleway, sans-serif",
            textAlign: "left",
          }}
        >
          LPDV
        </div>
      ),
      key: "lpdv",
      render: (record) => (
        <div className="buttonsarray">
          <IconButton
            size="small"
            onClick={() => handleOpenLabsModal(record.key)}
            aria-label="labs"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: record.labs.length > 0 ? "#b9f6ca" : "#e3f2fd",
              margin: "2px",
              color: "black", // Text color
            }}
          >
            L
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenProcedureModal(record.key)}
            aria-label="procedures"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                record.procedures.length > 0 ? "#b9f6ca" : "#90caf9",
              margin: "2px",
              color: "black", // Text color
            }}
          >
            P
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenDispenseModal(record.key)}
            aria-label="dispense"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                record.dispensed.length > 0 ? "#b9f6ca" : "#42a5f5",
              margin: "2px",
              color: "black", // Text color
            }}
          >
            D
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenTreatmentNoteModal(record)}
            aria-label="TreatmentNote"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: record.treatmentNotes.includes("Notes by:")
                ? "#81e984"
                : "#0e75e5",
              margin: "2px",
              color: "black", // Text color
            }}
          >
            N
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenVitalsModal(record.key)}
            aria-label="vitals"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: record.vitals.length > 0 ? "#81e984" : "#1e88e5",
              margin: "2px",
              color: "black", // Text color
            }}
          >
            V
          </IconButton>
        </div>
      ),
    },

    {
      title: (
        <div
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            letterSpacing: "0em",
            fontFamily: "Raleway, sans-serif",
            textAlign: "left",
          }}
        >
          Room
        </div>
      ),
      dataIndex: "room",
      key: "room",
      render: (room, record) => (
        <Select
          defaultValue={room} // Set the default value to the current room value
          style={{ height: 32, fontSize: "13px" }} // Adjust width as needed
          onChange={(e) =>
            handleCellChange(record.key, "room", e, "patientInfo")
          }
        >
          <MenuItem key="empty-option" value="">
            Empty
          </MenuItem>
          {roomOptions?.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      title: (
        <div
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            letterSpacing: "0em",
            fontFamily: "Raleway, sans-serif",
            textAlign: "left",
          }}
        >
          Procedures
        </div>
      ),
      dataIndex: "procedures",
      key: "procedures",
      render: (procedures) => procedures.join(", "),
    },
    {
      title: (
        <div
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            letterSpacing: "0em",
            fontFamily: "Raleway, sans-serif",
            textAlign: "left",
          }}
        >
          Dispensed Injections
        </div>
      ),
      dataIndex: "dispensed",
      key: "dispensed",
      render: (dispensed) => dispensed.join(", "),
    },
    {
      title: (
        <div
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            letterSpacing: "0em",
            fontFamily: "Raleway, sans-serif",
            textAlign: "left",
          }}
        >
          Labs
        </div>
      ),
      dataIndex: "labs",
      key: "labs",
      className: "labs-column",
      onCell: () => ({
        className: "labs-column-cell", // Use this for cell-specific styling if needed
      }),
      render: (labs) => labs.join(", "),
    },
    {
      title: (
        <div
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            letterSpacing: "0em",
            fontFamily: "Raleway, sans-serif",
            textAlign: "left",
          }}
        >
          Notes
        </div>
      ),
      dataIndex: "note",
      key: "note",
      render: (note, record) => (
        <NoteInput
          style={{ height: 25 }}
          defaultValue={note}
          recordKey={record.key}
        />
      ),
    },
    {
      title: (
        <div
          style={{
            fontSize: "13px",
            lineHeight: "15px",
            letterSpacing: "0em",
            fontFamily: "Raleway, sans-serif",
            textAlign: "left",
          }}
        >
          Assignees
        </div>
      ),
      dataIndex: "assignees",
      key: "assignees",
      render: (assignees, record) => (
        <MuiSelect
          value={assignees || []} // Use the assignees from the record
          onChange={(e) => {
            const valueArray = e.target.value; // This is already an array because it's a multi-select
            handleCellChange(
              record.key,
              "assignees",
              valueArray,
              "patientInfo"
            );
          }}
          renderValue={(selected) => selected.join(", ")}
          multiple
          style={{ height: 32 }} // Adjust width as needed
        >
          {assigneeOptions?.map((assignee, index) => (
            <MenuItem key={index} value={assignee}>
              <Checkbox checked={assignees.includes(assignee)} />
              {assignee}
            </MenuItem>
          ))}
        </MuiSelect>
      ),
    },
  ];

  const getSingleLabDisplayName = async (testName) => {
    try {
      const response = await axios.get(
        `/getsinglelab/${testName}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      // Assuming the display name is provided in the response
      const displayName = response.data.lab.display_name;

      // Return the displayName if found, otherwise return the testName
      return displayName ? displayName : testName;
    } catch (error) {
      console.error(`Error fetching lab ${testName}:`, error);
      // Return the testName in case of an error
      return testName;
    }
  };

  const [allLabs, setAllLabs] = useState([]);
  const [newLabs, setNewLabs] = useState([]);
  const fetchallLabs = async () => {
    try {
      const response = await axios.get("/allLabs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json",
        },
      });
      setAllLabs(response.data.allLabs);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const fetchDataForAllLabs = async (labs) => {
    try {
      // Map each testName to its display name in the allLabs array
      const displayNames = labs.map((testName) => {
        // Find the lab with the matching test_name in the allLabs array
        const lab = allLabs.find((lab) => lab.test_name === testName);

        // If the lab is found and it has a display_name, return the display_name
        // Otherwise, return the testName
        return lab && lab.display_name ? lab.display_name : testName;
      });

      // Filter out any null values
      const validDisplayNames = displayNames.filter((name) => name !== null);

      return validDisplayNames;
    } catch (error) {
      console.error("Error fetching lab data:", error);
      return [];
    }
  };

  const formatDataForTable = (data) => {
    // Include userLabs where checked is true
    const labs = data.userLabs
      .filter((lab) => lab.checked)
      ?.map((lab) => lab.test_name);
    if (labs.length !== newLabs.length) {
      fetchDataForAllLabs(labs).then((labsResp) => {
        if (JSON.stringify(labsResp) !== JSON.stringify(newLabs)) {
          // Update the state only if there's a change
          if (labsResp.length > 0) {
            setNewLabs(labsResp);
          }
          
        }
      });
    }
    // Include userdispensed items with non-empty selectedField
    const dispensed = data.userdispensed
      .filter((d) => d.selectedField !== "")
      ?.map((d) => `${d.name} (${d.selectedField}) x${d.quantity}`);

    // Include userProcedures where checked is true or selectedField is not an empty string
    const procedures = data.userProcedures
      .filter(
        (procedure) =>
          procedure.checked === true ||
          (procedure.selectedField && procedure.selectedField !== "")
      )
      ?.map((procedure) => {
        if (procedure.selectedField && procedure.selectedField !== "") {
          return `${procedure.procedure_name} (${procedure.selectedField})`;
        }
        return procedure.procedure_name;
      });

    // Filter vitals
    const vitals = data?.vitals?.some(
      (vital) =>
        vital.HR !== 0 ||
        vital.BP !== "" ||
        vital.T !== 0 ||
        vital.W !== 0 ||
        vital.RR !== 0 ||
        vital.SPO2 !== 0
    )
      ? data.vitals
      : [];

    // Use the correct properties from the data structure
    return {
      key: data["_id"],
      patientName: data.patientName, // Assuming patient name is provided
      room: data.room || "unassigned", // Provide a fallback if room is undefined
      procedures,
      dispensed,
      labs: newLabs,
      note: data.note || "",
      assignees: data.assignees || "",
      status: data.status,
      treatmentNotes: data.treatmentNotes,
      vitals,
    };
  };

  useEffect(() => {
    if (treatments) {
      setSortedTreatments([formatDataForTable(treatments)]);
    }
  }, [treatments]);

  useEffect(() => {
    fetchallLabs();
    fetchRoomOptions();
  }, []);

  const changeTreatmentStatus = async (treatmentId, newStatus) => {
    try {
      const response = await axios.put(
        `/treatments/updateStatus/${treatmentId}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Treatment status updated successfully");
        // You might want to fetch the updated list of treatments here
      } else {
        toast.error("Failed to update treatment status");
      }
    } catch (error) {
      toast.error("Error updating treatment status");
      console.error("Error updating treatment status:", error);
    }
  };

  //LPDV FUNCTIONALITY

  const getCheckedLabsSummary = () => {
    const checkedLabs = currentPatientLabs.labs
      .filter((lab) => lab.checked)
      .map((lab) => lab.test_name)
      .join(", ");

    return checkedLabs;
  };

  const handleOpenLabsModal = async (orderId) => {
    try {
      const response = await axios.get(
        `/orderget/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      // Separate checked and unchecked labs
      const checkedLabs = response.data.userLabs.filter((lab) => lab.checked);
      const uncheckedLabs = response.data.userLabs.filter(
        (lab) => !lab.checked
      );

      // Combine them with checked labs first
      const sortedLabs = [...checkedLabs, ...uncheckedLabs];


      setCurrentPatientLabs({ labs: sortedLabs, orderId: orderId });
      setLabsModalOpen(true);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenProcedureModal = async (patientId) => {
    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      const userProcedures = response.data.userProcedures;
      proceduresSortingFunction(contextProcedures, userProcedures);
      setCurrentProcedures(userProcedures);
      
      setProcedureModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLabs = currentPatientLabs.labs.filter((lab) =>
    lab.test_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleOpenDispenseModal = async (patientId) => {
    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      const userDispenses = response.data.userdispensed;
      dispensesSortingFunction(contextDispensed, userDispenses);
      setCurrentDispensedItems(userDispenses);
      setDispenseModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenTreatmentNoteModal = async (treatmentNote) => {
    //treatmentNote is basically the treatment we want to send to treatment Note
    if (treatmentNote) {
      const selectedTreatment = treatments;

      setCurrentTreatment({
        ...treatmentNote,
        custom: selectedTreatment.custom,
        date: selectedTreatment.date.split("T")[0],
        userLabs: selectedTreatment.userLabs,
        userProcedures: selectedTreatment.userProcedures,
        userdispensed: selectedTreatment.userdispensed,
        PatientId: selectedTreatment.PatientId,
        _id: selectedTreatment._id,
      });
      setTreatmentNoteModalOpen(true);
      isTreatmentNoteEditing.current = true;
    }
  };

  const handleCloseTreatmentNoteModal = () => {
    setCurrentTreatment(null);
    setTreatmentNoteModalOpen(false);
  };

  const setIsTreatmentNoteEditing = (isTreatmentNoteEditingVal) => {
    isTreatmentNoteEditing.current = isTreatmentNoteEditingVal;
  };

  const handleOpenVitalsModal = async (patientId) => {
    
    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      
      setCurrentVitalsItems(response.data.vitals);
      setVitalsModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
      // Handle the error appropriately in your application
    }
  };

  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }

  const handleCellChange = (patientId, columnName, newValue, category) => {
    
    
    

    let payload;

    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
      };
    }

    // Send the update request using axios
    axios
      .put(`/patient/order/update/${patientId}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      })
      .then((response) => {
        
        dataReset();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCloseLabsModal = () => {
    setLabsModalOpen(false);
    setSearchTerm(""); // Clear the search term
    dataReset();
    setTreatmentUpdate(true);
  };

  const handleLabsCheckboxChange = (labId, checked) => {
    setCurrentPatientLabs((prevState) => {
      // Find the lab by ID to get its name
      const lab = prevState.labs.find((lab) => lab._id === labId);
      const labName = lab ? lab.test_name : "Unknown Lab";
      const labItemNumber = lab?.item_number;

      

      // {
      //   "category": "patientLabs",
      //
      // }

      // Construct the payload for the POST request
      const payload = {
        category: "patientLabs",
        updates: {
          checked: checked,
          value: labItemNumber ? `${labItemNumber}` : labName,
          type: labItemNumber ? "item_number" : "test_name",
        },
      };

      // Send the update to the backend using a POST request
      axios
        .put(
          `/patient/order/update/${currentPatientLabs.orderId}`,

          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          // Handle the successful update
          
        })
        .catch((error) => {
          // Handle any errors
          console.error("Update failed:", error);
        });

      // Update the labs state
      return {
        ...prevState,
        labs: prevState.labs?.map((lab) =>
          lab._id === labId ? { ...lab, checked: checked } : lab
        ),
      };
    });
  };

  return (
    <>
      <div className={styles.todayViewContainer}>
        <div className={styles.todayViewWrapper}>
          {/* Table Section */}
          <div className={styles.tableContainer}>
            <Table
              columns={columns}
              dataSource={sortedTreatments}
              pagination={false}
            />
            <div
              style={{
                marginLeft: "47%",
                marginRight: "50%",
                marginTop: "15px",
              }}
            >
              {loader && <BounceLoader color="#000" />}
            </div>
          </div>
        </div>
        <ProcedureModal
          open={procedureModalOpen}
          procedures={currentProcedures}
          onClose={() => {
            setProcedureModalOpen(false);
            dataReset(); // Refresh data after closing the ProcedureModal
            setTreatmentUpdate(true);
          }}
          patientId={currentPatientIdForModal}
          handleCellChange={handleCellChange}
        />

        <DispenseModal
          open={dispenseModalOpen}
          dispensedItems={currentDispensedItems}
          onClose={() => {
            setDispenseModalOpen(false);
            dataReset(); // Refresh data after closing the DispenseModal
            setTreatmentUpdate(true);
          }}
          patientId={currentPatientIdForModal}
          handleCellChange={handleCellChange}
        />

        <VitalsModal
          open={vitalsModalOpen}
          VitalsItems={currentVitalsItems}
          onClose={() => {
            setVitalsModalOpen(false);
            dataReset(); // Refresh data after closing the VitalsModal
            setTreatmentUpdate(true);
          }}
          patientId={currentPatientIdForModal}
          handleCellChange={handleCellChange}
        />
        <Modal
          open={labsModalOpen}
          onClose={handleCloseLabsModal}
          aria-labelledby="labs-modal-title"
        >
          <Box
            sx={{ ...modalStyle, backgroundColor: "#f3f4f6", padding: "24px" }}
          >
            <span className="labstitleStyling" sx={{ marginBottom: "16px" }}>
              Patient Labs
            </span>

            {/* Search bar */}
            <input
              className="labsInput"
              fullWidth
              variant="outlined"
              placeholder="Search labs..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ marginBottom: "16px" }}
            />
            <div className="labsactiveStyling">
              <strong>Active Labs:</strong> {getCheckedLabsSummary()}
            </div>
            {/* Labs list */}
            <Grid container spacing={0}>
              {filteredLabs?.map((lab) => {
                const displayName = getLabDisplayName(contextLabs, lab);
                return (
                  <Grid item xs={6} key={lab._id}>
                    <ListItem>
                      <ListItemText
                        primary={`${
                          displayName ? displayName : lab.test_name
                        } - (${lab.item_number})`}
                      />
                      <Checkbox
                        checked={lab.checked}
                        onChange={(e) =>
                          handleLabsCheckboxChange(lab._id, e.target.checked)
                        }
                      />
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Modal>
        <AntdModal
          title="Treatment Note"
          visible={treatmentNoteModalOpen} // Changed to use the state variable for treatment notes
          onCancel={handleCloseTreatmentNoteModal} // Changed to the corresponding close handler function for treatment notes
          footer={null}
          width="80vw" // Set the width to 80% of the viewport width
          styles={{ maxHeight: "70vh", overflowY: "scroll" }}
        >
          <TreatmentNotes
            treatment={currentTreatment}
            isTreatmentNoteEditing={isTreatmentNoteEditing.current}
            setIsTreatmentNoteEditing={setIsTreatmentNoteEditing}
          />
        </AntdModal>
        <ToastContainer />
      </div>
    </>
  );
};

export default SingleTreatmentView;
