import React, { useState } from 'react';
import { Modal, Row, Col, List, Typography, Checkbox, Descriptions, Button, message, Collapse, Switch } from 'antd';
const { Panel } = Collapse;

const OrderDetailsModal = ({ visible, onClose, shopifyOrder = {}, treatmentOrder = [], setActionLoading, onCompare }) => {
    const [showDifferences, setShowDifferences] = useState(false);
    const [selectedLabs, setSelectedLabs] = useState([]);
    const [selectedProcedures, setSelectedProcedures] = useState([]);
    const [selectedDispensed, setSelectedDispensed] = useState([]);
    const [selectedSupplements, setSelectedSupplements] = useState([]);
    const treatmentDetails = treatmentOrder[0] || {};

    const compareLabs = (labs1, labs2) => {
        return labs1.filter(lab1 => lab1.checked && !labs2.some(lab2 => lab2.test_name === lab1.test_name && lab2.checked));
    };

    const compareProcedures = (procs1, procs2) => {
        return procs1.filter(proc1 => {
            const proc2 = procs2.find(proc => proc.procedure_name === proc1.procedure_name);
            return proc1.checked && (!proc2 || !proc2.checked) ||
                (proc1.selectedField && proc1.selectedField !== (proc2 && proc2.selectedField));
        });
    };

    const compareDispensed = (disp1, disp2) => {
        return disp1.filter(d1 => {
            const d2 = disp2.find(d => d.name === d1.name);
            return d1.selectedField && (!d2 || d1.selectedField !== d2.selectedField);
        });
    };

    const compareSupplements = (supps1, supps2) => {
        return supps1.filter(s1 => {
            const s2 = supps2.find(s => s.name === s1.name);
            return s1.quantity && (!s2 || s1.quantity !== s2.quantity);
        });
    };


    const renderLabs = (labs1, labs2, isShopifySide = false) => {
        const labsToShow = showDifferences ? compareLabs(labs1, labs2) : labs1.filter(lab => lab.checked);
        const sortedItems = labsToShow.sort((a, b) => {
            const isSelectedA = selectedProcedures.some(selectedItem => selectedItem === a);
            const isSelectedB = selectedProcedures.some(selectedItem => selectedItem === b);
            return isSelectedB - isSelectedA;
        });
        return sortedItems.map(lab => (
            <List.Item key={lab.test_name}>
                {isShopifySide && (
                    <Checkbox
                        checked={selectedLabs.some(selectedLab => selectedLab.test_name === lab.test_name)}
                        onChange={() => handleSelectItem(lab, 'labs')}
                    />
                )}
                {lab.test_name}
            </List.Item>
        ));
    };



    const renderProcedures = (procs1, procs2, isShopifySide = false) => {
        const procsToShow = showDifferences ? compareProcedures(procs1, procs2) : procs1.filter(proc => proc.checked || proc.selectedField);
        const sortedItems = procsToShow.sort((a, b) => {
            const isSelectedA = selectedProcedures.some(selectedItem => selectedItem === a);
            const isSelectedB = selectedProcedures.some(selectedItem => selectedItem === b);
            return isSelectedB - isSelectedA;
        });
        return sortedItems.map(proc => (
            <Descriptions.Item key={proc.procedure_name} label={
                <>
                    {isShopifySide && (
                        <Checkbox
                            checked={selectedProcedures.includes(proc)}
                            onChange={() => handleSelectItem(proc, 'procedures')}
                            style={{ marginRight: '8px' }}
                        />
                    )}
                    {proc.procedure_name}
                </>
            }>
                {proc.selectedField || '-'}
            </Descriptions.Item>
        ));
    };


    const renderDispensed = (disp1, disp2, isShopifySide = false) => {
        const dispToShow = showDifferences ? compareDispensed(disp1, disp2) : disp1.filter(d => d.selectedField);
        const sortedItems = dispToShow.sort((a, b) => {
            const isSelectedA = selectedProcedures.some(selectedItem => selectedItem === a);
            const isSelectedB = selectedProcedures.some(selectedItem => selectedItem === b);
            return isSelectedB - isSelectedA;
        });
        return sortedItems.map(d => (
            <Descriptions.Item key={d.name} label={
                <>
                    {isShopifySide && (
                        <Checkbox
                            checked={selectedDispensed.some(selectedD => selectedD.name === d.name)}
                            onChange={() => handleSelectItem(d, 'dispensed')}
                            style={{ marginRight: '8px' }}
                        />
                    )}
                    {d.name}
                </>
            }>
                {d.selectedField} - Quantity: {d.quantity}
            </Descriptions.Item>
        ));
    };


    const renderSupplements = (supps1, supps2, isShopifySide = false) => {
        const suppsToShow = showDifferences ? compareSupplements(supps1, supps2) : supps1.filter(s => s.quantity > 0);
        const sortedItems = suppsToShow.sort((a, b) => {
            const isSelectedA = selectedProcedures.some(selectedItem => selectedItem === a);
            const isSelectedB = selectedProcedures.some(selectedItem => selectedItem === b);
            return isSelectedB - isSelectedA;
        });
        return sortedItems.map(s => (
            <List.Item key={s.name}>
                {isShopifySide && (
                    <Checkbox
                        checked={selectedSupplements.some(selectedS => selectedS.name === s.name)}
                        onChange={() => handleSelectItem(s, 'supplements')}
                        style={{ marginRight: '8px' }}
                    />
                )}
                {s.name} - Quantity: {s.quantity}
            </List.Item>
        ));
    };


    const handleSelectItem = (item, type) => {
        const updateSelection = (selectedItems) => {
            const exists = selectedItems.some(selected => selected === item);
            if (exists) {
                return selectedItems.filter(selected => selected !== item);
            } else {
                return [...selectedItems, item];
            }


        };

        switch (type) {
            case 'labs':
                setSelectedLabs(prev => updateSelection(prev));
                break;
            case 'procedures':
                setSelectedProcedures(prev => updateSelection(prev));
                break;
            case 'dispensed':
                setSelectedDispensed(prev => updateSelection(prev));
                break;
            case 'supplements':
                setSelectedSupplements(prev => updateSelection(prev));
                break;
            default:
                break;
        }
    };



    const renderCollapsibleSection = (title, content) => (
        <Collapse bordered={false} defaultActiveKey={['1']}>
            <Panel header={title} key="1">
                {content}
            </Panel>
        </Collapse>
    );
    // Handles merge and overwrite actions
    const handleAction = async (actionType) => {
        try {
            setActionLoading(true);
            const response = await fetch(`/process-action`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                },
                body: JSON.stringify({
                    action: actionType,
                    shopifyOrderId: shopifyOrder,
                    treatmentOrderId: treatmentDetails._id
                })
            });
            if (response.ok) {
                message.success(`Successfully ${actionType}ed the orders.`);
                onClose(); // Close modal on success
                setActionLoading(false);
                onCompare();
            } else {
                const error = await response.json();
                message.error(`Failed to ${actionType} the orders: ${error.message}`);
            }
        } catch (error) {
            console.error('Error during the API request:', error);
            message.error(`Failed to ${actionType} the orders due to an error.`);
        }
    };

    const handleActionSelected = async (actionType) => {
        try {
            setActionLoading(true);
            const payload = {
                action: actionType,
                shopifyOrderId: {
                    userLabs: selectedLabs,
                    userProcedures: selectedProcedures,
                    userdispensed: selectedDispensed,
                    userSupplements: selectedSupplements,
                },
                treatmentOrderId: treatmentDetails._id,
            };
            const response = await fetch(`/process-selected-action`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                },
                body: JSON.stringify(payload)
            });
            if (response.ok) {
                message.success(`Successfully ${actionType}ed selected items.`);
                onClose(); // Close modal on success
                setActionLoading(false);
                onCompare();
            } else {
                const error = await response.json();
                message.error(`Failed to ${actionType} selected items: ${error.message}`);
            }
        } catch (error) {
            console.error('Error during the API request:', error);
            message.error(`Failed to ${actionType} selected items due to an error.`);
        }
    };

    return (
        <Modal
            title="Order Details Comparison"
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>Cancel</Button>,
                <Button key="mergeSelected" onClick={() => handleActionSelected('merge')} style={{ color: "white", backgroundColor: "#1976d2" }}>Merge Selected</Button>,
                <Button key="merge" onClick={() => handleAction('merge')} style={{ color: "white", backgroundColor: "#1976d2" }}>Merge</Button>,
                <Button key="overwriteSelected" onClick={() => handleActionSelected('overwrite')} style={{ color: "white", backgroundColor: "#4caf50" }}>Overwrite Selected</Button>,
                <Button key="overwrite" onClick={() => handleAction('overwrite')} style={{ color: "white", backgroundColor: "#4caf50" }}>Overwrite</Button>,
            ]}
            width={960}
        >
            <Row gutter={16}>
                <Col span={12} >
                    <p>
                        Date: {treatmentOrder[0].date.split('T', 1)}
                    </p>
                </Col>
                <Col span={12} style={{ display: "flex", marginBottom: "20px" }}>

                    <Checkbox
                        checked={showDifferences}
                        onChange={e => setShowDifferences(e.target.checked)}
                        style={{ marginRight: '10px' }}
                    />
                    <p>Show Difference: </p>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Typography.Title level={5}>Treatment</Typography.Title>
                    {renderCollapsibleSection("Labs", <List>{renderLabs(treatmentDetails.userLabs || [], shopifyOrder.userLabs || [], false)}</List>)}
                    {renderCollapsibleSection("Procedures", <Descriptions bordered column={1}>{renderProcedures(treatmentDetails.userProcedures || [], shopifyOrder.userProcedures || [], false)}</Descriptions>)}
                    {renderCollapsibleSection("Dispensed", <Descriptions bordered column={1}>{renderDispensed(treatmentDetails.userdispensed || [], shopifyOrder.userdispensed || [], false)}</Descriptions>)}
                    {renderCollapsibleSection("Supplements", <List>{renderSupplements(treatmentDetails.userSupplements || [], shopifyOrder.userSupplements || [], false)}</List>)}
                </Col>
                <Col span={12}>
                    <Typography.Title level={5}>Shopify Order</Typography.Title>
                    {renderCollapsibleSection("Labs", <List>{renderLabs(shopifyOrder.userLabs || [], treatmentDetails.userLabs || [], true)}</List>)}
                    {renderCollapsibleSection("Procedures", <Descriptions bordered column={1}>{renderProcedures(shopifyOrder.userProcedures || [], treatmentDetails.userProcedures || [], true)}</Descriptions>)}
                    {renderCollapsibleSection("Dispensed", <Descriptions bordered column={1}>{renderDispensed(shopifyOrder.userdispensed || [], treatmentDetails.userdispensed || [], true)}</Descriptions>)}
                    {renderCollapsibleSection("Supplements", <List>{renderSupplements(shopifyOrder.userSupplements || [], treatmentDetails.userSupplements || [], true)}</List>)}
                </Col>
            </Row>
        </Modal>
    );
};

export default OrderDetailsModal;
