// import logo from './logo.svg';
import "./header.css";

function Header() {
  const userType = localStorage.getItem("user");
  return (
    <div className="AdminPorta-Header">
      <div className="header-flexbox">
        <div className="Headingblock-Text my-auto">
          {userType == "user" ? <h3>User Portal</h3> : <h3>Admin View</h3>}
        </div>
        <div className="UserRoll-Block my-auto">
          <div className="circle-username">
            <h2 className="m-auto">G.F</h2>
          </div>
          <div className="username-userRoll my-auto">
            <h2>Gio Franco</h2>
            <h6>Admin</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
