import React, { useState } from "react";
import { Modal, Button, Input, message, Form } from "antd";
import axios from "axios";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons"; // Assuming you're using antd icons.
import { ToastContainer, toast } from "react-toastify";

const AddLocalUser = ({ isModalVisible, setIsModalVisible, handleRefresh }) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});

  const handleSignup = async () => {
    setError("");
    const newErrors = {}

    if (!userName.trim()) {
      newErrors.userName = "Username is required."
    }
    if (!password.trim()) {
      newErrors.password = "Password is required."
    }
    if (!confirmPassword.trim()) {
      newErrors.confirmPassword = "Confirm Password is required."
    }
    if (!email.trim()) {
      newErrors.email = "Email is required."
    }
    if (email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        newErrors.email = "Please enter a valid email."
      }
    }

    if (password !== confirmPassword) {
      newErrors.password = "Please enter a valid email."
    }
    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        username: userName,
        password,
        email
      }

      try {
        const response = await axios.post(`/auth/signup`, payload);

        toast.success("User Added");
        setUserName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        handleRefresh();
        setIsModalVisible(false);
      } catch (error) {
        if (error.response && error.response.status === 409) {

          setError(error.response.data.message);
        }
        toast.error("SignUp Error");
        console.error("Signup Error:", error);
      }
    }
  };

  const handleOk = async () => {

    handleSignup();
    if (errors.length === 0) {
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setError("");
    setErrors({});
    setUserName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        title="Local User"
        visible={isModalVisible}
        onOk={handleOk}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        onCancel={handleCancel}
      >
        <div>
          <div>Create a New Account</div>

          <div>Username</div>
          <Form.Item validateStatus={errors.userName ? 'error' : ''}
            help={errors.userName || null}
            style={{ marginBottom: errors.userName ? '24px' : '0px' }}

          >
            <Input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Enter your username"
            />
          </Form.Item>

          <div>Email</div>
          <Form.Item validateStatus={errors.email ? 'error' : ''}
            help={errors.email || null}
            style={{ marginBottom: errors.email ? '24px' : '0px' }}
          >
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </Form.Item>
          <div>Password</div>

          <div>
            <Form.Item validateStatus={errors.password ? 'error' : ''}
              help={errors.password || null}
              style={{ marginBottom: errors.password ? '24px' : '0px' }}
            >
              <div className="flex flex-row items-center gap-2">
                <Input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Choose a password"
                />
                <span onClick={() => setShowPassword((prevState) => !prevState)}>
                  {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </span>
              </div>
            </Form.Item>
          </div>

          <div>Confirm Password</div>
          <div>
            <Form.Item validateStatus={errors.confirmPassword ? 'error' : ''}
              help={errors.confirmPassword || null}
              style={{ marginBottom: errors.confirmPassword ? '24px' : '0px' }}
            >
              <div className="flex flex-row items-center gap-2">
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm your password"
                />
                <span
                  onClick={() => setShowConfirmPassword((prevState) => !prevState)}
                >
                  {showConfirmPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </span>
              </div>
            </Form.Item>
          </div>

          {error && <div>{error}</div>}
        </div>
      </Modal >
    </>
  );
};

export default AddLocalUser;
