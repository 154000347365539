import React, { useState, useEffect } from 'react';
import { Modal, Select, Input, Button, List } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';

const { Option } = Select;

function ManageSupplements() {
    const [supplements, setSupplements] = useState([]);
    const [selectedSupplement, setSelectedSupplement] = useState(null);
    const [newFrequency, setNewFrequency] = useState('');
    const [isAddingFrequency, setIsAddingFrequency] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchSupplements();
    }, []);

    const fetchSupplements = async () => {
        const response = await axios.get('/getAllSupplements', {
            headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
        });
        const updatedData = response.data.map(supplement => ({
            ...supplement,
            frequency: supplement.frequency[0] === "1 dosage twice a day" ? [...supplement.frequency] : ["1 dosage twice a day", ...supplement.frequency],
        }));
        setSupplements(updatedData);
    };

    const handleSelectSupplement = (supplementId) => {
        setSelectedSupplement(supplementId);
        setIsAddingFrequency(false); // Reset adding state whenever a new supplement is selected
    };

    const addFrequency = async () => {
        if (!newFrequency) return;
        try {
            const response = await axios.post(`/supplements/${selectedSupplement}/add-frequency`, {
                newFrequency
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
            });
            const updatedSupplements = supplements.map(supp =>
                supp._id === selectedSupplement ? { ...supp, frequency: [...supp.frequency, newFrequency] } : supp
            );
            setSupplements(updatedSupplements);
            setIsAddingFrequency(false);
            setNewFrequency('');
            toast('Frequency added successfully', { type: 'success' });
        } catch (error) {
            toast(`Error adding frequency: ${error.response?.data?.message || error.message}`, { type: 'error' });
        }
    };

    const removeFrequency = async (frequency) => {
        try {
            await axios.post(`/supplements/${selectedSupplement}/remove-frequency`, {
                frequencyToRemove: frequency
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
            });
            const updatedSupplements = supplements.map(supp =>
                supp._id === selectedSupplement ? { ...supp, frequency: supp.frequency.filter(freq => freq !== frequency) } : supp
            );
            setSupplements(updatedSupplements);
            toast('Frequency removed successfully', { type: 'success' });
        } catch (error) {
            toast(`Error removing frequency: ${error.response?.data?.message || error.message}`, { type: 'error' });
        }
    };

    return (
        <>
            <button
                className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
                onClick={() => setIsModalOpen(true)}
            >
                Manage Supplements
            </button>
            <Modal
                title="Manage Supplements"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <Select
                    showSearch
                    style={{ width: '100%', marginBottom: '20px' }}
                    placeholder="Select a supplement"
                    onChange={handleSelectSupplement}
                    value={selectedSupplement}
                >
                    {supplements.map(supp => (
                        <Option key={supp._id} value={supp._id}>{supp.supplement_name}</Option>
                    ))}
                </Select>
                {selectedSupplement && (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                            {!isAddingFrequency ? (
                                <Button onClick={() => setIsAddingFrequency(true)}>
                                    Add Frequency
                                </Button>
                            ) : (
                                <div style={{ display: 'flex', width: "100%" }}>
                                    <Input
                                        placeholder="New Frequency"
                                        value={newFrequency}
                                        onChange={e => setNewFrequency(e.target.value)}
                                        style={{ marginRight: '10px' }}
                                    />
                                    <Button onClick={addFrequency} style={{ marginRight: '10px' }}>
                                        Add
                                    </Button>
                                    <Button onClick={() => setIsAddingFrequency(false)} type="default">
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </div>
                        <h3>Current Frequencies:</h3>
                        <List
                            dataSource={supplements.find(supp => supp._id === selectedSupplement)?.frequency}
                            renderItem={freq => (
                                <List.Item
                                    actions={[
                                        <Button type="danger" onClick={() => removeFrequency(freq)}>
                                            Remove
                                        </Button>
                                    ]}
                                >
                                    {freq}
                                </List.Item>
                            )}
                        />
                    </>
                )}
            </Modal>
        </>
    );
}

export default ManageSupplements;
