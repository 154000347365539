export const labsSortingFunction = (contextLabs, userLabs) => {
  const indexMap = new Map();
  contextLabs.forEach((item) => {
    indexMap.set(item.item_number, item.index);
  });
  userLabs.sort((a, b) => {
    const indexA = indexMap.get(a.item_number);
    const indexB = indexMap.get(b.item_number);
    return indexA - indexB;
  });
  return userLabs;
};
export const dispensesSortingFunction = (contextDispenses, userdispensed) => {
  const indexMap = new Map();
  contextDispenses.forEach((item) => {
    indexMap.set(item.name, item.index);
  });
  userdispensed.sort((a, b) => {
    const indexA = indexMap.get(a.name);
    const indexB = indexMap.get(b.name);
    return indexA - indexB;
  });
  return userdispensed;
};
export const proceduresSortingFunction = (
  contextProcedures,
  userProcedures
) => {
  const indexMap = new Map();
  contextProcedures.forEach((item) => {
    indexMap.set(item.procedure_name, item.index);
  });
  userProcedures.sort((a, b) => {
    const indexA = indexMap.get(a.procedure_name);
    const indexB = indexMap.get(b.procedure_name);
    return indexA - indexB;
  });
  return userProcedures;
};

export const getLabDisplayName = (contextLabs, lab) => {
  const foundLab = contextLabs.find(
    (item) => item.item_number === lab.item_number
  );
  if (foundLab) {
    return foundLab.display_name;
  }
  return null;
};
