import React, { useState } from "react";
import {
  Button,
  Modal,
  Input,
  Form,
  DatePicker,
  Select,
  Switch,
  Row,
  Col,
} from "antd";
import moment from "moment";
import "./Updations.css";

function AddPatient({ showToast }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleFormSubmit = async (values) => {
    setConfirmLoading(true);
    try {
      // Format the date
      const formattedDate = values.DOB || "";

      const fullName = `${values.firstName} ${values.lastName}`;
      const dataToSend = {
        ...values,
        "Full Name": fullName,
        DOB: formattedDate, // Use the formatted date
      };
      const response = await fetch("/patient-profiles", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers you need here
        },

        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {
        showToast("Patient added successfully", "success");
        
      } else {
        console.error("Failed to add patient");
      }
    } catch (error) {
      console.error("Error adding patient:", error);
    }
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    
    setOpen(false);
  };

  const { TextArea } = Input;
  const { Option } = Select;

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        type="primary"
        onClick={showModal}
      >
        Add Patient
      </button>
      <Modal
        title="Add Patient"
        visible={open}
        onOk={handleFormSubmit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form
          name="patientForm"
          initialValues={{ remember: true }}
          onFinish={handleFormSubmit}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="Customer ID"
                rules={[
                  { required: true, message: "Please enter Customer ID" },
                ]}
              >
                <Input placeholder="Customer ID" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: "Please enter First Name" }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: "Please enter Last Name" }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="email"
                rules={[
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="City">
                <Input placeholder="City" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="address1">
                <Input placeholder="Address Line 1" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="address2">
                <Input placeholder="Address Line 2" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="Province">
                <Input placeholder="Province" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Province Code">
                <Input placeholder="Province Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="Country">
                <Input placeholder="Country" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Country Code">
                <Input placeholder="Country Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="Zip">
                <Input placeholder="Zip" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Phone">
                <Input placeholder="Phone" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="Status"
                rules={[{ required: true, message: "Please select Status" }]}
              >
                <Select placeholder="Select Status">
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Note">
                <Input placeholder="Note" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="G6PD" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
                G6PD Deficiency
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="TxNotes">
                <Input rows={4} placeholder="Enter Treatment Notes" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="Allergies">
                <Input rows={4} placeholder="Enter Allergies" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="DOB"
                rules={[
                  { required: true, message: "Please select Date of Birth" },
                ]}
              >
                <DatePicker placeholder="Date of Birth" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <button
              style={{
                color: "white",
                backgroundColor: "#1976d2",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
              className="py-1 px-10 w-full md:w-auto"
              type="primary"
              htmlType="submit"
              loading={confirmLoading}
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddPatient;
