import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Input,
  Form,
  DatePicker,
  Switch,
  Row,
  Col,
  Select,
} from "antd";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import "./Updations.css";

function UpdatePatient({ patients, showToast }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [form] = useForm();

  const showModal = () => {
    setOpen(true);
  };

  const handlePatientSelect = (patientId) => {
    const patient = patients.find((p) => p._id === patientId);
    setSelectedPatient(patient);
    form.resetFields();
    // Convert DOB to MM-DD-YYYY format using moment
    const formattedDOB = patient["DOB"]
      ? moment(patient["DOB"]).format("MM-DD-YYYY")
      : undefined;
    form.setFieldsValue({
      ...patient,
      firstName: patient["Full Name"].split(" ")[0],
      lastName: patient["Full Name"].split(" ")[1],
      email: patient["Email"],
      address1: patient["Address1"],
      address2: patient["Address2"],
      city: patient["City"],
      province: patient["Province"],
      provinceCode: patient["Province Code"],
      country: patient["Country"],
      countryCode: patient["Country Code"],
      zip: patient["Zip"],
      phone: patient["Phone"],
      note: patient["Note"],
      status: patient["Status"],
      allergies: patient["Allergies"],
      G6PD: patient["G6PD"],
      DOB: formattedDOB ? moment(formattedDOB, "MM-DD-YYYY") : undefined, // Set DOB as a moment object in the correct format
      TxNotes: patient["TxNotes"],
    });
  };

  const handleFormSubmit = async (values) => {
    setConfirmLoading(true);
    try {
      const payload = {
        ...values,
        "Full Name": values.firstName + " " + values.lastName,
        Email: values.email,
        Address1: values.address1,
        Address2: values.address2,
        City: values.city,
        Province: values.province,
        "Province Code": values.provinceCode,
        Country: values.country,
        "Country Code": values.countryCode,
        Zip: values.zip,
        Phone: values.phone,
        Note: values.note,
        Status: values.Status,
        Allergies: values.allergies,
        G6PD: values.G6PD,
        TxNotes: values.TxNotes,
      };

      if (values.DOB) {
        payload.DOB = values.DOB.toISOString();
      }

      const response = await fetch(
        `/patient-profiles/${selectedPatient._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers you need here
          },
          body: JSON.stringify(payload),
        }
      );
      if (response.ok) {
        showToast("Patient updated successfully", "success");
        
      } else {
        console.error("Failed to update patient");
      }
    } catch (error) {
      console.error("Error updating patient:", error);
    }
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    
    setOpen(false);
  };

  const { TextArea } = Input;
  const { Option } = Select;

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        type="primary"
        onClick={showModal}
      >
        Update Patient
      </button>
      <Modal
        title="Update Patient"
        visible={open}
        onOk={form.submit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form
          form={form}
          name="patientForm"
          onFinish={handleFormSubmit}
          key={selectedPatient ? selectedPatient._id : "form"}
        >
          <Form.Item
            name="_id"
            rules={[{ required: true, message: "Please select a patient" }]}
          >
            <Select
              showSearch
              placeholder="Select a patient"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handlePatientSelect}
            >
              {patients.map((patient) => (
                <Option key={patient._id} value={patient._id}>
                  {patient["Full Name"]}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: "Please enter First Name" }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: "Please enter Last Name" }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="email"
                rules={[
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="City">
                <Input placeholder="City" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="address1">
                <Input placeholder="Address Line 1" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="address2">
                <Input placeholder="Address Line 2" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="Province">
                <Input placeholder="Province" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Province Code">
                <Input placeholder="Province Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="Country">
                <Input placeholder="Country" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Country Code">
                <Input placeholder="Country Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="Zip">
                <Input placeholder="Zip" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Phone">
                <Input placeholder="Phone" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="Status"
                rules={[{ required: true, message: "Please select Status" }]}
              >
                <Select placeholder="Select Status">
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Note">
                <Input placeholder="Note" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="G6PD" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
                G6PD Deficiency
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="TxNotes">
                <Input rows={4} placeholder="Enter Treatment Notes" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="Allergies">
                <Input rows={4} placeholder="Enter Allergies" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="DOB"
                rules={[
                  { required: true, message: "Please select Date of Birth" },
                ]}
              >
                <DatePicker
                  placeholder="Date of Birth"
                  style={{ width: "100%" }}
                  format="MM-DD-YYYY" // Set the format you want to display in the DatePicker
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <button
              style={{
                color: "white",
                backgroundColor: "#1976d2",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
              className="py-1 px-10 w-full md:w-auto"
              type="primary"
              htmlType="submit"
              loading={confirmLoading}
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UpdatePatient;
