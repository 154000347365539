import React, { useState, useEffect } from "react";
import { Select, Modal, Input, Form } from "antd";
import axios from "axios";

function UpdateCalendar({ calendarId, initialData, showToast }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendarId, setSelectedCalendarId] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    const fetchCalendars = async () => {
      try {
        const response = await axios.get(
          "/google-calendars",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        setCalendars(response.data);
      } catch (error) {
        showToast("Error fetching calendars", "error");
        console.error("Error fetching calendars:", error);
      }
    };
    fetchCalendars();
  }, [showToast]);
  const showModal = () => setIsModalOpen(true);

  const handleOk = async () => {
    const values = await form.validateFields();
    try {
      await axios.put(
        `/google-calendar/update/${selectedCalendarId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      showToast("Calendar updated successfully", "success");
      setIsModalOpen(false);
    } catch (error) {
      showToast("Error updating calendar", "error");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCalendarChange = (value) => {
    setSelectedCalendarId(value);
    const selectedCalendar = calendars.find(
      (calendar) => calendar._id === value
    );
    if (selectedCalendar) {
      form.setFieldsValue({ name: selectedCalendar.name }); // Set initial value
    }
    // Optionally, fetch and set initial form values based on the selected calendar
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        Update Calendar
      </button>
      <Modal
        title="Update Calendar"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="calendarId"
            label="Select Calendar"
            rules={[{ required: true, message: "Please select a calendar!" }]}
          >
            <Select
              placeholder="Select a calendar"
              onChange={handleCalendarChange}
              allowClear
            >
              {calendars.map((calendar) => (
                <Select.Option
                  key={calendar._id}
                  value={calendar._id}
                  className="capitalize"
                >
                  {calendar.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="hidden"
            name="name"
            label="Calendar Name"
            rules={[
              { required: true, message: "Please input the calendar name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cid"
            label="Calendar ID"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            name="account"
            label="Account Data"
            rules={[{ required: true, message: "Please input account data!" }]}
          >
            <Input.TextArea />
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

export default UpdateCalendar;
