import { Button, Modal } from "antd";
import React, { useEffect } from "react";
import axios from "axios";
import DragAndDropList from "./DragAndDropList";

export const SortSupplements = ({ showToast }) => {
  const [Open, setOpen] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [supplements, setSupplements] = React.useState([]);
  const [modalText, setModalText] = React.useState("Content of the modal");

  const fetchSupplements = () => {
    axios
      .get("/getAllSupplements", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        
        setSupplements(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the supplements", error);
      });
  };

  const saveSupplementsIndexes = () => {
    axios
      .post(
        "/saveSupplementIndexes",
        {
          supplements: supplements,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      )
      .then((response) => {
        showToast("Saved", "success");
      })
      .catch((error) => {
        
        showToast("Something went wrong", "error");
      });
  };

  useEffect(() => {
    fetchSupplements();
  }, []);

  const saveSupplements = () => {
    setOpen(false);
    saveSupplementsIndexes();
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    saveSupplements();
  };
  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Sort Supplements
      </button>
      <Modal
        title="Sort Supplements"
        open={Open}
        onOk={handleOk}
        okText={"Save"}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        styles={{
          body: {
            maxHeight: "700px",
            overflowY: "scroll",
          },
        }}
      >
        <DragAndDropList
          data={supplements}
          setParentItems={setSupplements}
          primaryKey={"supplement_name"}
          secondaryKey={"shopify_product_id"}
        />
      </Modal>
    </>
  );
};
