import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  Input,
  Form,
  DatePicker,
  Select,
  Row,
  Col,
  Checkbox,
  Radio,
  Slider,
  Steps,
} from "antd";
import axios from "axios";
import { BarLoader } from "react-spinners";
import styles from "./AddPatient.module.css";
import { toast, ToastContainer } from "react-toastify";

function AddPatientForm() {
  const { Step } = Steps;
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [hasAllergies, setHasAllergies] = useState(false);
  const [activePatient, setActivePatient] = useState([]);
  const [hasWeightProblems, setHasWeightProblems] = useState(false);
  const [patientLoader, setPatientLoader] = useState(false);
  const [form] = Form.useForm();
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handlePatientChange = (value) => {
    setSelectedPatient(value);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const today = new Date();
  const month = months[today.getMonth()];
  const day = today.getDate();
  const year = today.getFullYear();

  const formattedDate = `Day: ${day} - Month: ${month} - Year: ${year}`;
  const handleWeightProblemsChange = (checkedValues) => {
    setHasWeightProblems(checkedValues.includes("Other"));
    const hasOther = checkedValues.includes("Other");

    // If "Other" is included, ensure it's saved as an array
    if (hasOther) {
      // Save as an array with only "Other" string
      form.setFieldsValue({
        medicationsSupplements: {
          weightProblems: ["Other"],
        },
      });
    }
  };
  const handleAllergiesChange = (e) => {
    setHasAllergies(e.target.value === "Yes");
  };
  const { Option } = Select;
  const goalsOptions = [
    "Improve cognitive function",
    "Increase energy",
    "Minimize symptoms of fatigue",
    "Improve depression",
    "Minimize symptoms of menopause",
    "Reduce cancer risk/care for cancer",
    "Reduce risk of osteoporosis",
    "Improve overall health",
    "Reduce cardiovascular disease",
    "Improve sleep",
    "Improve memory",
    "Stabilize mood",
    "Reduce headaches/migraines",
    "Reduce joint pain",
    "Reduce food allergies",
    "Improve digestive issues",
    "Other",
  ];

  const familyHistoryOptions = [
    "Asthma",
    "Autoimmune disease",
    "Cancer",
    "Heart disease",
    "Diabetes",
    "High blood pressure",
    "Mental illness",
    "Stroke",
    "Other",
  ];

  const weightProblemsOptions = [
    "30% over your recommended weight",
    "Anorexia",
    "Binge eating",
    "Eat to gain weight",
    "Eat to lose weight",
    "Bulimia",
    "Unable to gain weight",
    "Rapid/unexplained weight loss",
    "None",
    "Other",
  ];

  const alcoholConsumptionOptions = [
    "Daily",
    "Weekly",
    "Monthly",
    "Occasionally",
    "Never",
  ];

  const symptomsOptions = [
    "Fever/Chills",
    "Unexplained change in weight",
    "Fatigue/Malaise/Generalized weakness",
    "Headaches/Migraines",
    "Dizziness",
    "Sinus Pain/Pressure/Discharge",
    "Excessive snoring",
    "Wheezing/Chronic Cough",
    "Shortness of breath",
    "Chest pain, pressure or tightness",
    "Swelling of hands/feet/ankles",
    "Nausea/Vomiting",
    "Abdominal pain",
    "Heartburn",
    "Constipation or diarrhea",
    "Stiffness/Pain in joints/muscles",
    "Joint swelling",
    "Bleeding/Easy bruising",
    "Excessive urination",
    "Excessive thirst/hunger",
    "Hot flashes",
    "Painful/Bloody urination",
    "Difficulty urinating/Night-time urination",
    "Urinary incontinence (leakage)",
    "Sexual Difficulties/Painful intercourse",
    "Rash",
    "Anxiety/Panic Attacks",
    "Concentration Difficulty",
    "Feelings of Guilt",
    "Insomnia/Problems with Sleep",
    "Loss of energy",
    "Thoughts of harming self or others",
  ];

  const yesNoOptions = ["Yes", "No"];

  const GetActivePatientProfiles = async () => {
    try {
      setPatientLoader(true);
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setActivePatient(response.data);
        setPatientLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        
      }
      if (error.response && error.response.status === 409) {
        
      }
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    GetActivePatientProfiles();
  }, []);

  const handleFormSubmit = async (values) => {
    setConfirmLoading(true);
    values.signature.date = new Date();
    // values["Customer ID"] = selectedPatient;
    
    try {
      const response = await fetch("/form-data", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers you need here
        },

        body: JSON.stringify(values),
      });
      if (response.ok) {
        setConfirmLoading(false);
        toast("Patient form added successfully", "success");
        
      } else {
        setConfirmLoading(false);
        console.error("Failed to add patient form");
      }
    } catch (error) {
      setConfirmLoading(false);
      console.error("Error adding patient form:", error);
    }
  };

  return (
    <>
      <div className={`${styles.headerFlexbox} mb-[10px]`}>
        <div>
          <h3 className={`${styles.exportHeading}`}>Add Patient</h3>
        </div>
      </div>
      <ToastContainer />
      <Row className="px-[40px]" gutter={16}>
        <Col xs={24}>
          <Steps className="cursor-pointer" current={currentStep}>
            <Step onClick={() => setCurrentStep(0)} title="Step 1" />
            <Step onClick={() => setCurrentStep(1)} title="Step 2" />
            <Step onClick={() => setCurrentStep(2)} title="Step 3" />
            <Step onClick={() => setCurrentStep(3)} title="Step 4" />
          </Steps>
        </Col>
      </Row>
      <div className="px-[40px]">
        <Form
          name="patientForm"
          initialValues={{ remember: true }}
          onFinish={handleFormSubmit}
        >
          {patientLoader ? (
            <div className="m-[10px]">
              <BarLoader color="#000" />
            </div>
          ) : (
            <Form.Item
              name="Customer ID"
              rules={[{ required: true, message: "Please select Patient" }]}
            >
              <Select
                className="m-[10px]"
                showSearch
                style={{ width: 200 }}
                placeholder="Select a patient"
                optionFilterProp="children"
                // onChange={handlePatientChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {activePatient.map((patient) => (
                  <Option key={patient["_id"]} value={patient["_id"]}>
                    {patient["Full Name"]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {currentStep === 0 && (
            <>
              <h3 className={`${styles.formHeading}`}>
                Personal Health Information
              </h3>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    name={["fullName", "firstName"]}
                    rules={[
                      { required: true, message: "Please enter First Name" },
                    ]}
                  >
                    <Input size="large" placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item name={["fullName", "middleName"]}>
                    <Input size="large" placeholder="Middle Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    name={["fullName", "lastName"]}
                    rules={[
                      { required: true, message: "Please enter Last Name" },
                    ]}
                  >
                    <Input size="large" placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    name="birthDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select Date of Birth",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-full py-2 px-3"
                      placeholder="Date of Birth"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please Select Gender" },
                    ]}
                    name="gender"
                  >
                    <Select size="large" placeholder="Select Gender">
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please enter Phone Number" },
                    ]}
                    name="phoneNumber"
                  >
                    <Input size="large" placeholder="Phone Number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    rules={[{ required: true, message: "Please enter City" }]}
                    name={["address", "city"]}
                  >
                    <Input size="large" placeholder="City" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please enter Province" },
                    ]}
                    name={["address", "stateProvince"]}
                  >
                    <Input size="large" placeholder="Province" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please enter Zip Code" },
                    ]}
                    Province
                    name={["address", "postalZipCode"]}
                  >
                    <Input size="large" placeholder="Zip Code" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please enter Address" },
                    ]}
                    name={["address", "streetAddress"]}
                  >
                    <Input size="large" placeholder="Address Line 1" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item name={["address", "streetAddressLine2"]}>
                    <Input size="large" placeholder="Address Line 2" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter Emergency Contact First Name",
                      },
                    ]}
                    name={["emergencyContact", "name", "firstName"]}
                  >
                    <Input
                      size="large"
                      placeholder="Emergency Contact First Name"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={6}>
                  <Form.Item name={["emergencyContact", "name", "lastName"]}>
                    <Input
                      size="large"
                      placeholder="Emergency Contact Last Name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item name={["emergencyContact", "relationship"]}>
                    <Input size="large" placeholder="Relationship" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter Emergency Contact Phone Number",
                      },
                    ]}
                    name={["emergencyContact", "phoneNumber"]}
                  >
                    <Input size="large" placeholder="Emergency Phone Number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item name="referralSource">
                    <Input
                      size="large"
                      rows={4}
                      placeholder="How did you hear about us?"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    name="race"
                    rules={[
                      { required: true, message: "Please select your race" },
                    ]}
                  >
                    <Select size="large" placeholder="Select race">
                      <Option value="Black">Black</Option>
                      <Option value="Alaskan Native">Alaskan Native</Option>
                      <Option value="American Indian">American Indian</Option>
                      <Option value="Asian">Asian</Option>
                      <Option value="Caucasian">Caucasian</Option>
                      <Option value="Hispanic/Latino">Hispanic/Latino</Option>
                      <Option value="Pacific Islander">Pacific Islander</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    name="maritalStatus"
                    rules={[
                      {
                        required: true,
                        message: "Please select your marital status",
                      },
                    ]}
                  >
                    <Select size="large" placeholder="Select marital status">
                      <Option value="Married">Married</Option>
                      <Option value="Single">Single</Option>
                      <Option value="Divorced">Divorced</Option>
                      <Option value="Widowed">Widowed</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name="goals"
                    label="Please advise us of all your goals"
                    valuePropName="checked"
                  >
                    <Checkbox.Group>
                      <div
                        style={{
                          display: "grid",
                          marginLeft: "10px",
                          gridTemplateColumns: "repeat(3, 1fr)",
                        }}
                      >
                        {goalsOptions.map((goal, index) => (
                          <div key={index}>
                            <Checkbox value={goal}>{goal}</Checkbox>
                          </div>
                        ))}
                      </div>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex justify-between">
                <IconButton>
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton onClick={handleNext}>
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </>
          )}

          {currentStep === 1 && (
            <>
              <h3 className={`${styles.formHeading}`}>Family History</h3>
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name={["familyHistory", "diseases"]}
                    label={
                      <span>
                        Has anyone in your immediate family (blood relative){" "}
                        <br /> ever had any of the following diseases or
                        conditions?
                      </span>
                    }
                    valuePropName="checked"
                  >
                    <Checkbox.Group>
                      <div
                        style={{
                          display: "grid",
                          marginLeft: "10px",
                          gridTemplateColumns: "repeat(3, 1fr)",
                        }}
                      >
                        {familyHistoryOptions.map((condition, index) => (
                          <div key={index}>
                            <Checkbox value={condition}>{condition}</Checkbox>
                          </div>
                        ))}
                      </div>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
              <h3 className={`${styles.formHeading}`}>Allergies</h3>
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name={["allergies", "allergicTo"]}
                    label={
                      <span>
                        Do you have any allergies to medications, foods, or
                        environmental allergens?
                      </span>
                    }
                  >
                    <Radio.Group
                      className="ml-[10px]"
                      onChange={handleAllergiesChange}
                    >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                      <Radio value="not-sure">Not Sure</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {hasAllergies && (
                  <Col xs={24}>
                    <Form.Item
                      name={["allergies", "allergicReactions"]}
                      label="What are you allergic to and what are the reaction(s)?"
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <h3 className={`${styles.formHeading}`}>
                Medications / Supplements
              </h3>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    name={["medicationsSupplements", "currentlyTaking"]}
                    label={
                      <span>
                        Are you currently taking any medications or supplements?
                      </span>
                    }
                  >
                    <Radio.Group className="ml-[10px]">
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    name={["medicationsSupplements", "weight"]}
                    rules={[{ required: true, message: "Please enter Weight" }]}
                  >
                    <Input size="large" placeholder="Weight" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Form.Item
                    name={["medicationsSupplements", "height"]}
                    rules={[{ required: true, message: "Please enter Height" }]}
                  >
                    <Input size="large" placeholder="Height" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="" xs={24} sm={12} md={24}>
                  <Form.Item
                    name={["medicationsSupplements", "weightChange"]}
                    label={
                      <span>
                        Has you weight changed in the last year, if so how?
                      </span>
                    }
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["medicationsSupplements", "weightProblems"]}
                    label="Have you ever had problems with your weight? Please describe the problem:"
                  >
                    <Checkbox.Group onChange={handleWeightProblemsChange}>
                      <Row>
                        {weightProblemsOptions.map((option, index) => (
                          <Col key={index} span={8}>
                            <Checkbox value={option}>{option}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                {hasWeightProblems && (
                  <Col xs={24}>
                    <Form.Item label="Please describe other problem">
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row gutter={16}>
                <Col className="" xs={24} sm={12} md={24}>
                  <Form.Item
                    name={["medicationsSupplements", "diet"]}
                    label={<span>Please describe your diet</span>}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="" xs={24} sm={12} md={24}>
                  <Form.Item
                    name={["medicationsSupplements", "exercise"]}
                    label={<span>Please describe any regular exercise</span>}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="" xs={24} sm={12} md={24}>
                  <Form.Item
                    name={["medicationsSupplements", "stressLevel"]}
                    label={
                      <span>
                        Please describe your current level of stress and any
                        ways you use to manage your stress
                      </span>
                    }
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["healthQuestions", "alcoholConsumption"]}
                    label="How often do you consume alcohol?"
                  >
                    <Radio.Group>
                      {alcoholConsumptionOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["healthQuestions", "smokingHistory"]}
                    label="Have you ever smoked cigarettes, cigars, marijuana or a pipe?"
                  >
                    <Radio.Group>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["healthQuestions", "illegalDrugUse"]}
                    label="Do you use or do you have history of using illegal drugs?"
                  >
                    <Radio.Group>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["healthQuestions", "vaccinated"]}
                    label="Have you ever been vaccinated?"
                  >
                    <Radio.Group>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["healthQuestions", "reactionToVaccine"]}
                    label="Have you ever had a reaction to a vaccine?"
                  >
                    <Radio.Group>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["healthQuestions", "surgeryHistory"]}
                    label="Have you ever had surgery?"
                  >
                    <Radio.Group>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex justify-between">
                <IconButton onClick={handlePrev}>
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton onClick={handleNext}>
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </>
          )}

          {currentStep === 2 && (
            <>
              <h3 className={`${styles.formHeading}`}>
                Head, Ears, Eyes, Nose, Throat
              </h3>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={[
                      "healthQuestions",
                      "headEarNoseMouthEyesThroatProblems",
                    ]}
                    label="Do you have or have you ever had eye, ear, nose, mouth or throat problems?"
                  >
                    <Radio.Group>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <h3 className={`${styles.formHeading}`}>Lungs</h3>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["healthQuestions", "lungsProblems"]}
                    label="Do you have or have you ever had lung problems (e.g., asthma, bronchitis, shortness of breath, pneumonia)?"
                  >
                    <Radio.Group>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <h3 className={`${styles.formHeading}`}>Heart</h3>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["healthQuestions", "heartProblems"]}
                    label="Do you have or have you ever had cardiovascular problems (e.g., chest pain, heart attack, heart valve problems, high blood pressure, leg cramps)?"
                  >
                    <Radio.Group>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <h3 className={`${styles.formHeading}`}>Gastrointestinal</h3>

              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["healthQuestions", "gastrointestinalProblems"]}
                    label="Do you have or have you ever had gastrointestinal problems (e.g., blood in your stool, cramping, hepatitis, liver disease, pancreas problems)?"
                  >
                    <Radio.Group>
                      {yesNoOptions.map((option, index) => (
                        <Radio key={index} value={option}>
                          {option}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <h3 className={`${styles.formHeading}`}>Symptoms Checklist</h3>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["symptomsChecklist", "symptoms"]}
                    label="Check if you have ever experienced any of the following symptoms:"
                  >
                    <Checkbox.Group>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                      >
                        {symptomsOptions.map((symptom, index) => (
                          <div key={index}>
                            <Checkbox value={symptom}>{symptom}</Checkbox>
                          </div>
                        ))}
                      </div>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["symptomsChecklist", "generalHealthRating"]}
                    label="How healthy do you feel in general?"
                  >
                    <Slider
                      min={0}
                      max={10}
                      marks={{ 0: "0", 5: "5", 10: "10" }}
                      defaultValue={5}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex justify-between">
                <IconButton onClick={handlePrev}>
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton onClick={handleNext}>
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["symptomsChecklist", "additionalNotes"]}
                    label="Additional Information"
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Please provide any additional information that you feel is important."
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24}>
                  <p className="text-justify">
                    (a) Patient acknowledges that neither Group nor Physician
                    guarantees that communications with Physician using
                    electronic mail ("email"), facsimile, video chat, instant
                    messaging, and cellular telephone are secure or confidential
                    methods of communications. Accordingly, Patient expressly
                    waives Group’s and Physician’s obligations under the Health
                    Insurance Portability and Accountability Act of 1996 (42
                    U.S.C. § 1320d et seq.), as amended by the Health
                    Information Technology for Economic and Clinical Health Act
                    of 2009, and all rules and regulations promulgated
                    thereunder (collectively, "HIPAA"), and other state and
                    federal laws and regulations applicable to the use,
                    maintenance, and disclosure of patient-related information,
                    to guarantee confidentiality with respect to correspondence
                    using such means of communication. Patient acknowledges that
                    all such communications may become a part of Patient’s
                    medical records maintained by Physician.
                    <br /> (b) By providing Patient’s e-mail address to
                    Physician, Patient authorizes Physician to communicate with
                    Patient by e-mail regarding Patient’s "protected health
                    information" ("PHI") (as defined under HIPAA) and Patient
                    understands and agrees to the following: E-mail is not
                    necessarily a secure medium for sending or receiving PHI
                    and, accordingly, any third party may gain access to such
                    PHI; Although Group and Physician will make all reasonable
                    efforts to keep e-mail communications confidential and
                    secure, neither Group nor Physician can assure or guarantee
                    the absolute confidentiality of such e-mail communications.
                  </p>
                </Col>
              </Row>
              <Row className="mt-[20px]" gutter={16}>
                <Col className="" xs={24} sm={12} md={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter Patient Initials",
                      },
                    ]}
                    name={["signature", "patientInitials"]}
                  >
                    <Input size="large" placeholder="Patient Initials" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={16}>
                  <Form.Item
                    name={["waivers", "medicalRecordAccessExceptions"]}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Any exceptions to medical record access"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24}>
                  <p className="text-justify">
                    I agree to accept responsibility for payment of all charges
                    relating to the services provided to me, regardless of the
                    outcomes of the services or whether any insurance applies.
                    <br />
                    By signing below, you, the patient of Sunridge Medical (or
                    the legal representative or parent of the patient), are
                    requesting and authorizing Sunridge Medical Wellness Center
                    to perform services and to provide you with recommendations
                    on optimizing your present and long-term health goals.
                  </p>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24}>
                  <p>{formattedDate}</p>
                </Col>
              </Row>

              <Row className="mt-[10px]" gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name={["signature", "signature"]}
                    label="Signature"
                  >
                    <Input.TextArea rows={4} placeholder="Signature" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                  className="btn-color h-[50px] py-2 px-6 mb-[10px]"
                >
                  Add Patient
                </button>
              </Form.Item>
              <div className="flex justify-between">
                <IconButton onClick={handlePrev}>
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton>
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </>
          )}
        </Form>
      </div>
    </>
  );
}

export default AddPatientForm;
