import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  TableCell as MuiTableCell,
  Paper,
  Slide,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import axios from "axios"; // Import axios at the top of your file
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { ContentCopyRounded } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Modal from "@mui/material/Modal";
import { Modal as AntdModal } from "antd";
import { BounceLoader } from "react-spinners";
import { ToastContainer, toast as toastifyToast } from "react-toastify";
import CustomToast from "../../Components/CustomToast";
import DispenseModal from "../../Components/DispenseModal";
import MaterialTableRow from "../../Components/MaterialTable/MaterialTableRow";
import ProcedureModal from "../../Components/ProcedureModal";
import TreatmentNotes from "../../Components/TreatmentNotes";
import VitalsModal from "../../Components/VitalsModal";
import { DataContext } from "../../context/DataContext";
import {
  dispensesSortingFunction,
  getLabDisplayName,
  proceduresSortingFunction,
} from "../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700, // Maintained reduced width
  maxHeight: "80vh", // Maintained for screen fit
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 16, // Maintained reduced shadow
  p: 2, // Maintained reduced padding
  "& .MuiTypography-h6": {
    // Targeting Typography with variant h6
    fontSize: "1rem", // Maintained decreased font size
  },
  "& .MuiListItemText-primary": {
    fontSize: "0.9rem", // Setting font size for primary text
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Add ellipsis for overflowed text
  },
  "& .MuiCheckbox-root": {
    // Targeting Checkbox
    padding: "2px", // Reduced padding for a smaller checkbox
    "& svg": {
      // Targeting the SVG icon in checkbox
      fontSize: "1rem", // Reduced icon size
    },
  },
  "& .MuiGrid-container": {
    rowGap: 1, // Adjust row spacing if needed
  },
  "& .MuiGrid-item": {
    display: "flex", // Ensure the items in grid are flex containers
    alignItems: "center", // Align items vertically in the center
  },
};

const TableCell = styled(MuiTableCell)({
  minWidth: "100px",
  textAlign: "center", // Ensure text is centered
  // Add any other styles you need
  backgroundColor: "#eeeeee",
});

const tableContainerStyle = {
  maxHeight: "800px", // You can adjust this value based on your needs
};

// Add this style for the TableHead and its rows
const tableHeaderStyle = {
  position: "sticky",
  top: 0,
  backgroundColor: "#eeeeee !important", // Ensure the background is not transparent
  zIndex: 101, // To make sure the header is above all other table elements
};

const PatientDataTable = React.forwardRef(
  (
    {
      data,
      patientID,
      contextD,
      contextP,
      onDeleteOrder,
      setTableData,
      handlePatientSelect,
      filters,
      setLoader,
    },
    ref
  ) => {

    const {
      data: {
        labs: contextLabs,
        dispensed: contextDispensed,
        procedures: contextProcedures,
      },
    } = useContext(DataContext);
    const [treatmentNoteModalOpen, setTreatmentNoteModalOpen] = useState(false);
    const [procedureModalOpen, setProcedureModalOpen] = useState(false);
    const [currentProcedures, setCurrentProcedures] = useState([]);
    const [dispenseModalOpen, setDispenseModalOpen] = useState(false);
    const [pasteAction, setPasteAction] = useState(null);
    const [currentTreatment, setCurrentTreatment] = useState();

    const isTreatmentNoteEditing = useRef(false);

    const [currentDispensedItems, setCurrentDispensedItems] = useState([]);
    const [vitalsModalOpen, setVitalsModalOpen] = useState(false);
    const [currentVitalsItems, setCurrentVitalsItems] = useState([]);
    const [editedValues, setEditedValues] = useState({});
    const [currentPatientIdForModal, setCurrentPatientIdForModal] =
      useState(null);
    const [labsModalLoader, setLabsModalLoader] = useState(false);
    const [selectedDate, setSelectedDate] = useState(false);
    const [toast, setToast] = useState(null);
    const [checkedLabs, setCheckedLabs] = useState(null);

    // New state and function for Vitals visibility
    const [hideVitals, setHideVitals] = useState(false);
    const toggleVitalsVisibility = () => {
      setHideVitals(!hideVitals);
    };
    const toggleUserDispensedVisibility = () => {
      setHideUserDispensed(!hideUserDispensed);
    };

    const toggleUserProceduresVisibility = () => {
      setHideUserProcedures(!hideUserProcedures);
    };

    //Hide Functionality Variables
    const [HideNotes, setHideNotes] = useState(false);
    const [hiddenEmptyColumns, setHiddenEmptyColumns] = useState(new Set());
    const [hideUserProcedures, setHideUserProcedures] = useState(false);
    const [hideUserDispensed, setHideUserDispensed] = useState(false);
    const [previousTreatmentId, setPreviousTreatmentId] = useState(null);
    const [currentTreatmentId, setCurrentTreatmentId] = useState(null);
    const [pastedTreatmentId, setPastedTreatmentId] = useState(null);
    const [checkboxValues, setCheckboxValues] = useState({
      labs: false,
      dispensed: false,
      procedures: false,
      supplements: false,
      vitals: false
    });
    const [previouslyCheckedValues, setPreviouslyCheckedValues] = useState({});
    const [contextMenu, setContextMenu] = useState(null);
    const [newContextMenu, setNewContextMenu] = useState(null);
    const [pastedText, setPastedText] = useState("");
    const [undoOrderId, setUndoOrderId] = useState(null);
    const [undoToken, setUndoToken] = useState(null);
    const [copyKey, setCopyKey] = useState(null);
    const [showContextMenu, setShowContextMenu] = useState(true);
    const [openCopyConfirmationDialog, setOpenCopyConfirmationDialog] =
      useState(false);

    const onCopyClick = (treatment) => {
      setPreviouslyCheckedValues(checkboxValues);
      let resultString = `Treatment: ${treatment._id}\n`;
      // Check and append labs if selected

      if (checkboxValues.labs) {
        // const checkedLabs = getCheckedLabs(patient.userLabs);
        // resultString += `Labs: ${contextMenu?.data?.userLabs.join(", ")}\n`;
        resultString += `Labs: ${checkedLabs
          .map((lab) => lab.test_name)
          .join(", ")}\n`;
      }

      // Check and append procedures if selected
      if (checkboxValues.procedures) {
        resultString += `Procedures: ${contextMenu?.data.userProcedures.join(
          ", "
        )}\n`;
      }

      // Check and append dispensed if selected
      if (checkboxValues.dispensed) {

        // const dispensedItems = contextMenu?.data.userdispensed.filter(
        //   (item) => !item.includes("SUP")
        // );
        resultString += `Dispensed: ${contextMenu?.data.userdispensed.join(
          ", "
        )}\n`;
      }

      // Check and append supplements if selected
      if (checkboxValues.supplements) {

        const supplements = contextMenu?.data.dispensed
          .filter((item) => item.includes("SUP"))
          .map((item) => item.replace("SUP", "").trim());
        resultString += `Supplements: ${supplements.join(", ")}\n`;
      }

      if (checkboxValues.vitals) {
        const vitals = contextMenu?.data?.userVitals || [];
        resultString += `Vitals: ${vitals.join(", ")}\n`;
      }
      // Output or handle the constructed string
      handleCopy(resultString);
      toastifyToast.success(`${copyKey} Copied Successfully`);
      setOpenCopyConfirmationDialog(false);
    };

    const handleCopy = (text) => {
      navigator.clipboard
        .writeText(text)
        .then((text) => {
          const details = extractDetails(text);
          setPreviousTreatmentId(details.oldTreatment);
          // handleClose();
        })
        .catch((err) => {

        });
    };
    const handlePaste = (action) => {
      // handleOpenCopyConfirmationDialog();
      navigator.clipboard
        .readText()
        .then((text) => {
          setPastedText(text);

          handleCloseCopyConfirmationDialog();
          setShowContextMenu(true);
          handlePasteUpdate(contextMenu?.data._id, text, action);
          setLoader(true);
          handleClose();
        })
        .catch((err) => {

        });
    };

    async function handlePasteUpdate(newTreatmentId, text, action) {
      // 
      setPastedTreatmentId(newTreatmentId);
      const normalizedText = text.replace(/\r\n/g, "\n").replace(/\n/g, "\r\n");
      const details = extractDetails(text);

      try {
        const response = await axios.put(
          `/paste-in-treatment`,
          {
            newTreatmentId,
            action,
            oldTreatmentId: details.oldTreatment,
            dataToUpdate: details,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        toastifyToast.success("Paste Successful");
        handlePatientSelect(patientID);

        const undoToken = response.data.undoToken;
        const undoOrderId = response.data.orderId;
        setUndoOrderId(undoOrderId);
        setUndoToken(undoToken);
        handleClose();
      } catch (error) {
        toastifyToast.error(error);
        console.error(error);
      }
    }
    function extractDetails(resultString) {
      const details = {
        oldTreatment: null,
        hasProcedures: false,
        hasLabs: false,
        hasDispensed: false,
        hasSupplements: false,
      };

      // Extract Treatment
      const oldTreatmentMatch = resultString.match(/Treatment:\s*(.*)/);
      if (oldTreatmentMatch && oldTreatmentMatch[1]) {
        details.oldTreatment = oldTreatmentMatch[1];
      }

      // Check for Procedures
      details.hasProcedures = /Procedures:\s*(.*)/.test(resultString);

      // Check for Labs
      details.hasLabs = /Labs:\s*(.*)/.test(resultString);

      // Check for Dispensed
      details.hasDispensed = /Dispensed:\s*(.*)/.test(resultString);

      // Check for Supplements
      details.hasSupplements = /Supplements:\s*(.*)/.test(resultString);
      // Check for Supplements
      details.hasVitals = /Vitals:\s*(.*)/.test(resultString);

      return details;
    }
    const handleClose = () => {
      setNewContextMenu(null);
      setContextMenu(null);
    };

    async function handleUndo(undoOrderId, undoToken) {
      try {
        await axios.put(
          `/undo-paste/${undoOrderId}`,
          { undoToken },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        toastifyToast.success("Undo Successful");
        handlePatientSelect(patientID);
        handleClose();
        setUndoOrderId(null);
        setUndoToken(null);
        // handleDataReset(selectedDate);
      } catch (error) {
        toastifyToast.error(error.message || "Error undoing changes");
        console.error(error);
      }
    }

    const handleOpenCopyConfirmationDialog = () => {
      setOpenCopyConfirmationDialog(true);
    };
    const handleCloseCopyConfirmationDialog = () => {
      setOpenCopyConfirmationDialog(false);
    };

    // Extract unique headers for userDispensed and userProcedures
    const userDispensedHeaders = extractUniqueHeaders(
      data,
      "userdispensed",
      "name",
      contextD,
      "name"
    );

    const userProcedureHeaders = extractUniqueHeaders(
      data,
      "userProcedures",
      "procedure_name",
      contextP,
      "procedure_name"
    );
    const [visibleHeaders, setVisibleHeaders] = useState({
      // vitals: true,
      userDispensed: new Set(userDispensedHeaders),
      userProcedures: new Set(userProcedureHeaders),
    });
    // Add state for controlling the visibility of the header selection dialog
    const [isHeaderSelectionDialogOpen, setHeaderSelectionDialogOpen] =
      useState(false);

    //For Notes Expansion on click
    const [isFocused, setIsFocused] = useState(false);
    const [rowStates, setRowStates] = useState({});

    useImperativeHandle(ref, () => ({
      handleHideEmptyColumns,
    }));

    //Functions to Show All columns
    const showAllColumns = () => {
      setHideVitals(false);
      setHideNotes(false);
      setHiddenEmptyColumns(new Set());
      setHideUserProcedures(false);
      setHideUserDispensed(false);
    };

    // Function to toggle note field visibility for a specific row
    const toggleNoteField = (patientId) => {
      setRowStates((prev) => ({
        ...prev,
        [patientId]: {
          ...prev[patientId],
          showNoteField: !prev[patientId]?.showNoteField,
        },
      }));
    };

    // Function to determine the color of the note icon
    const getNoteIconColor = (note) => {
      return note ? "#07BC0C" : "#42a5f5"; // Green if note has value, #27d4f1 if empty
    };

    //Notes Expantion Functionality
    const handleFocus = (patientId) => {
      setRowStates((prev) => ({
        ...prev,
        [patientId]: {
          ...prev[patientId],
          isFocused: true,
        },
      }));
    };

    //Notes Expantion Functionality
    const handleBlur = (patient) => (e) => {
      setRowStates((prev) => ({
        ...prev,
        [patient._id]: {
          ...prev[patient._id],
          isFocused: false,
        },
      }));
      handleCellChange(patient._id, "note", e.target.value, "patientInfo");
    };

    const handleDeleteRow = async (orderId) => {
      try {
        const response = await fetch(
          `/patient/order/delete/${orderId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              // Include any other headers you need here
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete the order");
        }
        onDeleteOrder(orderId);
        const result = await response.json();
        // Or handle the response as needed
        // Optionally, refresh the data or update the UI accordingly
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    const handleDuplicateRow = async (orderId, selectedDate, values) => {
      try {
        if (selectedDate) {

          const responseDate = await fetch(
            `/patient/order/duplicate/${orderId}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
              body: JSON.stringify({ selectedDate, values }),
            }
          );
          if (!responseDate.ok) {
            throw new Error("Failed to duplicate the order");
          }

          const duplicatedOrder = await responseDate.json();
          // Or handle the response as needed
          handlePatientSelect(patientID);
          handleHideEmptyColumns();
        } else {
          const response = await fetch(
            `/patient/order/duplicate/${orderId}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to duplicate the order");
          }

          const duplicatedOrder = await response.json();
          // Or handle the response as needed
          handlePatientSelect(patientID);
          handleHideEmptyColumns();
        }

        // Optionally, refresh the data or update the UI accordingly
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    const handleOpenProcedureModal = async (patientId) => {
      try {
        const response = await axios.get(
          `/orderget/${patientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        const userProcedures = response.data.userProcedures;
        proceduresSortingFunction(contextProcedures, userProcedures);
        setCurrentProcedures(userProcedures);

        setProcedureModalOpen(true);
        setCurrentPatientIdForModal(patientId);
      } catch (error) {
        console.error("There was an error fetching the order:", error);
      }
    };

    const handleOpenDispenseModal = async (patientId) => {
      try {
        const response = await axios.get(
          `/orderget/${patientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        const userDispenses = response.data.userdispensed;
        dispensesSortingFunction(contextDispensed, userDispenses);
        setCurrentDispensedItems(userDispenses);
        setDispenseModalOpen(true);
        setCurrentPatientIdForModal(patientId);
      } catch (error) {
        console.error("There was an error fetching the order:", error);
        // Handle the error appropriately in your application
      }
    };

    const handleOpenTreatmentNoteModal = async (patientId) => {
      try {
        const response = await axios.get(
          `/orderget/${patientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
        setCurrentTreatment(response.data);
        setTreatmentNoteModalOpen(true);
        isTreatmentNoteEditing.current = true;
      } catch (error) {
        console.error("There was an error fetching the order:", error);
        // Handle the error appropriately in your application
      }
    };

    const handleCloseTreatmentNoteModal = () => {
      setCurrentTreatment(null);
      setTreatmentNoteModalOpen(false);
    };

    const setIsTreatmentNoteEditing = (isTreatmentNoteEditingVal) => {
      isTreatmentNoteEditing.current = isTreatmentNoteEditingVal;
    };

    const handleOpenVitalsModal = async (patientId) => {
      try {
        const response = await axios.get(
          `/orderget/${patientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        setCurrentVitalsItems(response.data.vitals);
        setVitalsModalOpen(true);
        setCurrentPatientIdForModal(patientId);
      } catch (error) {
        console.error("There was an error fetching the order:", error);
        // Handle the error appropriately in your application
      }
    };

    // Function to toggle the header selection dialog
    const toggleHeaderSelectionDialog = () => {
      setHeaderSelectionDialogOpen(!isHeaderSelectionDialogOpen);
    };

    // State for modal visibility and current patient's labs
    const [isLabsModalOpen, setLabsModalOpen] = useState(false);
    const [roomOptions, setRoomOptions] = useState([]);
    useEffect(() => {
      axios
        .get("/get-room-options", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }) // Replace with your actual API endpoint
        .then((response) => {
          setRoomOptions(response.data[0].name);
        })
        .catch((error) => {
          console.error("There was an error fetching the room options:", error);
          // Handle the error appropriately in your application
        });
    }, []);
    const [assigneeOptions, setassigneeOptions] = useState([]);
    useEffect(() => {
      axios
        .get("/get-Assignee-options", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }) // Replace with your actual API endpoint
        .then((response) => {
          setassigneeOptions(response.data[0].name);
        })
        .catch((error) => {
          console.error("There was an error fetching the room options:", error);
          // Handle the error appropriately in your application
        });
    }, []);
    const [currentPatientLabs, setCurrentPatientLabs] = useState({
      labs: [],
      orderId: null,
    });

    //Functions to hide empty columns
    const handleHideEmptyColumns = () => {
      // If any columns are already hidden, show all columns and reset
      if (hiddenEmptyColumns.size > 0) {

        setHiddenEmptyColumns(new Set());
        return;
      }

      const newHiddenEmptyColumns = new Set();

      // Check userdispensed items for empty columns
      userDispensedHeaders.forEach((header) => {
        let isEmpty = true;
        data.forEach((patient) => {
          const item = patient.userdispensed.find((d) => d.name === header);
          const editedItemValue =
            editedValues[`${patient._id}-${header}-selectedField`];

          if (
            (item && item.selectedField && item.selectedField !== "") ||
            editedItemValue !== undefined
          ) {
            isEmpty = false;
          }
        });

        updateColumnVisibility(header, isEmpty, newHiddenEmptyColumns);
      });

      // Check userProcedures for empty columns
      userProcedureHeaders.forEach((header) => {
        let isEmpty = true;
        data.forEach((patient) => {
          const procedure = patient.userProcedures.find(
            (p) => p.procedure_name === header
          );
          const editedProcedureValue1 =
            editedValues[`${patient._id}-${header}-selectedField`];
          const editedProcedureValue2 =
            editedValues[`${patient._id}-${header}-checked`];

          if (
            (procedure &&
              procedure.selectedField &&
              procedure.selectedField !== "") ||
            procedure?.checked ||
            editedProcedureValue1 !== undefined ||
            editedProcedureValue2
          ) {
            isEmpty = false;
          }
        });

        updateColumnVisibility(header, isEmpty, newHiddenEmptyColumns);
      });

      //console to check all columns being hidden due to being empty

      setHiddenEmptyColumns(newHiddenEmptyColumns);
    };

    const updateColumnVisibility = (header, isEmpty, newHiddenEmptyColumns) => {
      if (isEmpty) {
        newHiddenEmptyColumns.add(header);
      }
    };

    // Function to open the modal and set the current patient's labs
    const handleOpenLabsModal = (patientLabs, orderId, selectedDate) => {
      const checkedLabs = patientLabs.filter((lab) => lab.checked);
      const uncheckedLabs = patientLabs.filter((lab) => !lab.checked);
      const sortedLabs = [...checkedLabs, ...uncheckedLabs];
      setCurrentPatientLabs({ labs: sortedLabs, orderId: orderId });
      setSelectedDate(selectedDate);
      setLabsModalOpen(true);
    };

    const handleCheckboxChange = (labId, checked) => {
      setCurrentPatientLabs((prevState) => {
        // Find the lab by ID to get its name
        const lab = prevState.labs.find((lab) => lab._id === labId);
        const labName = lab ? lab.test_name : "Unknown Lab";
        const labItemNumber = lab?.item_number;



        // {
        //   "category": "patientLabs",
        //
        // }

        // Construct the payload for the POST request
        const payload = {
          category: "patientLabs",
          updates: {
            checked: checked,
            value: labItemNumber ? `${labItemNumber}` : labName,
            type: labItemNumber ? "item_number" : "test_name",
          },
        };

        // Send the update to the backend using a POST request
        axios
          .put(
            `/patient/order/update/${currentPatientLabs.orderId}`,

            payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
          .then((response) => {
            // Handle the successful update

          })
          .catch((error) => {
            // Handle any errors
            console.error("Update failed:", error);
          });

        // Update the labs state
        return {
          ...prevState,
          labs: prevState.labs?.map((lab) =>
            lab._id === labId ? { ...lab, checked: checked } : lab
          ),
        };
      });
    };
    const getCheckedLabsSummary = () => {
      const checkedLabs = currentPatientLabs.labs
        .filter((lab) => lab.checked)
        .map((lab) => lab.test_name)
        .join(", ");

      return checkedLabs;
    };

    // Function to close the modal
    const handleCloseLabsModal = () => {
      setLabsModalOpen(false);
      setSearchTerm(""); // Clear the search term
      handlePatientSelect(patientID);
     
    };

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    const filteredLabs = currentPatientLabs.labs.filter((lab) =>
      lab.test_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const renderLabsModal = () => {
      const findPreviousTreatment = (treatments) => {
        let previousTreatment = null;
        let currentDate = new Date(selectedDate);
        let found = false;

        while (!found && currentDate) {
          currentDate = getPreviousDate(currentDate);
          previousTreatment = treatments.treatments.find((treatment) => {
            let treatmentDateString = new Date(treatment.date)
              .toISOString()
              .split("T")[0];

            return treatmentDateString === currentDate;
          });

          if (previousTreatment) {
            found = true;
          }
        }

        return previousTreatment;
      };
      const findCurrentTreatment = (treatments) => {
        let currentTreatment = null;
        let currentDate = new Date(selectedDate).toISOString().split("T")[0];
        let found = false;

        currentTreatment = treatments.treatments.find((treatment) => {
          let treatmentDateString = new Date(treatment.date)
            .toISOString()
            .split("T")[0];
          return treatmentDateString === currentDate;
        });

        if (currentTreatment) {
          found = true;
        }

        return currentTreatment;
      };
      const getPreviousDate = (dateString) => {
        const currentDate = new Date(dateString);
        const previousDate = new Date(currentDate);
        previousDate.setDate(previousDate.getDate() - 1);
        return previousDate.toISOString().split("T")[0];
      };
      const showToast = (variant, message) => {
        setToast({ variant, message });

        setTimeout(() => {
          setToast(null);
        }, 5000);
      };
      const handleCopyLabsFromPreviousTreatment = async () => {
        setLabsModalLoader(true);
        try {
          // Fetch previous treatments
          const previousTreatmentsResponse = await axios.get(
            `/api/patient/${patientID}/last-treatments`
          );

          if (previousTreatmentsResponse.data.totalCount <= 0) {
            setLabsModalLoader(false);
            showToast(
              "error",
              "There are no labs from the previous treatment to copy from"
            );
            return;
          }

          // Filter to keep only treatments of type "Treatment"
          const treatments = previousTreatmentsResponse.data.treatments.filter(
            (treatment) => treatment.type === "Treatment"
          );

          if (treatments.length <= 0) {
            setLabsModalLoader(false);
            showToast(
              "error",
              "There are no treatments of the required type to copy labs from"
            );
            return;
          }

          const treatment = findPreviousTreatment({ treatments: treatments });
          const currentTreatment = findCurrentTreatment({
            treatments: treatments,
          });

          if (currentTreatment.review == true) {
            showToast(
              "error",
              "The status of this treatment is reviewed, so labs can't be copied"
            );
            setLabsModalLoader(false);
            return;
          }
          const previousLabs = treatment.userLabs.filter(
            (previouslab) => previouslab.checked === true
          );
          if (previousLabs.length <= 0) {
            setLabsModalLoader(false);
            showToast(
              "error",
              "There are no labs from the previous treatment to copy from"
            );
            return;
          }

          const updatePromises = previousLabs.map((previouslab) => {
            const payload = {
              category: "patientLabs",
              updates: {
                checked: true,
                value: previouslab.item_number && `${previouslab.item_number}`,
                type: previouslab.item_number && "item_number",
              },
            };

            return axios.put(
              `/patient/order/update/${currentPatientLabs.orderId}`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "sessionToken"
                  )}`,
                },
              }
            );
          });

          Promise.all(updatePromises)
            .then((responses) => {
              const updatedLabs = responses
                .flatMap((response) => response.data.userLabs)
                .filter((lab) => lab.checked === true);

              handleRefreshLabs();
              setLabsModalLoader(false);

              showToast("success", "Labs Copied Successfully");
            })
            .catch((error) => {
              setLabsModalLoader(false);

              showToast("error", "Failed to copy labs from previous treatment");
            });
        } catch (error) {
          showToast("error", "Error fetching previous treatments:");
          setLabsModalLoader(false);

          showToast("error", "Failed to fetch previous treatments");
        }
      };
      const handleRefreshLabs = async () => {
        setLabsModalLoader(true);
        try {
          // Assuming orderId is available in the component's state or props
          const orderId = currentPatientLabs.orderId;

          // Fetch new labs data
          const labsResponse = await axios.get(
            `/orderget/${orderId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );
          // Function to sort labs if needed
          const sortLabs = (labs) => {
            // Your sorting logic here. Example:
            return labs.sort((a, b) =>
              a.checked === b.checked ? 0 : a.checked ? -1 : 1
            );
          };
          // Sort labs here if necessary
          const sortedLabs = sortLabs(labsResponse.data.userLabs);

          // Fetch new tubes summary
          // const tubesResponse = await axios.get(
          //   `/getLabTubesInfo/${orderId}`,
          //   {
          //     headers: {
          //       "Content-Type": "application/json",
          //       Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          //     },
          //   }
          // );

          // Update the state with the new data
          setCurrentPatientLabs({ labs: sortedLabs, orderId: orderId });
          // if (tubesResponse?.data?.TubesSummary) {
          //   setTubesSummary(tubesResponse?.data?.TubesSummary);
          // }

          // setLoading(false);
          setLabsModalLoader(false);
        } catch (error) {
          // setLoading(false);
          console.error("Error refreshing labs and tubes:", error);
        }
      };
      return (
        <Modal
          open={isLabsModalOpen}
          onClose={handleCloseLabsModal}
          aria-labelledby="labs-modal-title"
        >
          <Box
            sx={{ ...modalStyle, backgroundColor: "#f3f4f6", padding: "24px" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography id="labs-modal-title" variant="h6" component="h2">
                Patient Labs
              </Typography>
              <Tooltip
                title={
                  <h1 className="text-sm">Copy Labs From Previous Treatment</h1>
                }
                placement="right"
              >
                <IconButton onClick={handleCopyLabsFromPreviousTreatment}>
                  <ContentCopyRounded />
                </IconButton>
              </Tooltip>
            </Box>
            {labsModalLoader && (
              <div className="loader-backdrop">
                <BounceLoader color="#000" />
              </div>
            )}
            <Box>
              {/* Search bar */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search labs..."
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ marginBottom: "16px" }}
              />
              {toast && (
                <CustomToast message={toast.message} variant={toast.variant} />
              )}
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold" }} // optional, for emphasis
              >
                Active Labs: {getCheckedLabsSummary()}
              </Typography>
              {/* Labs list */}
              <Grid container spacing={0}>
                {filteredLabs.map((lab) => {
                  const displayName = getLabDisplayName(contextLabs, lab);
                  return (
                    <Grid item xs={6} key={lab._id}>
                      <ListItem>
                        <ListItemText
                          primary={`${displayName ? displayName : lab.test_name
                            } - (${lab.item_number})`}
                        />
                        <Checkbox
                          checked={lab.checked}
                          onChange={(e) =>
                            handleCheckboxChange(lab._id, e.target.checked)
                          }
                        />
                      </ListItem>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Modal>
      );
    };
    // Function to handle header visibility change
    const handleHeaderVisibilityChange = (category, header, isVisible) => {
      setVisibleHeaders((prevHeaders) => {
        const newHeaders = { ...prevHeaders };
        if (isVisible) {
          newHeaders[category].add(header);
        } else {
          newHeaders[category].delete(header);
        }
        return newHeaders;
      });
    };
    const handleToggle = () => {
      setHideNotes(!HideNotes);
    };
    const renderHeaderSelectionDialog = () => {
      return (
        <Dialog
          onClose={toggleHeaderSelectionDialog}
          open={isHeaderSelectionDialogOpen}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Select headers to display</DialogTitle>
          <FormGroup>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="userDispensed-content"
                id="userDispensed-header"
              >
                <Typography>User Dispensed Headers</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {userDispensedHeaders.map((header) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={header}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={visibleHeaders.userDispensed.has(header)}
                            onChange={(e) =>
                              handleHeaderVisibilityChange(
                                "userDispensed",
                                header,
                                e.target.checked
                              )
                            }
                          />
                        }
                        label={header}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="userProcedures-content"
                id="userProcedures-header"
              >
                <Typography>User Procedures Headers</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {userProcedureHeaders.map((header) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={header}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={visibleHeaders.userProcedures.has(header)}
                            onChange={(e) =>
                              handleHeaderVisibilityChange(
                                "userProcedures",
                                header,
                                e.target.checked
                              )
                            }
                          />
                        }
                        label={header}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </FormGroup>
          <Button onClick={toggleHeaderSelectionDialog}>Close</Button>
        </Dialog>
      );
    };
    // This effect runs when the component mounts or when data changes
    useEffect(() => {
      setVisibleHeaders({
        userDispensed: new Set(
          extractUniqueHeaders(data, "userdispensed", "name", contextD, "name")
        ),
        userProcedures: new Set(
          extractUniqueHeaders(
            data,
            "userProcedures",
            "procedure_name",
            contextP,
            "procedure_name"
          )
        ),
      });
      // Add other dependencies as needed
    }, [data, contextP, contextD]); // Include all relevant dependencies

    // Helper function to calculate the number of subheaders for each procedure
    const calculateSubHeadersForProcedure = (header, data) => {
      // Find a representative item to determine the number of subheaders
      const exampleItem = data
        .flatMap((patient) => patient.userProcedures)
        .find((procedure) => procedure.procedure_name === header);

      let numSubHeaders = 0;

      // If dosages array is present and has items, count it as a sub-header
      if (
        exampleItem &&
        Array.isArray(exampleItem.dosages) &&
        exampleItem.dosages.length > 0 &&
        "note" in exampleItem
      ) {
        if (HideNotes) {
          numSubHeaders = 1;
        } else {
          numSubHeaders = 2;
        }
      }

      if (
        exampleItem &&
        Array.isArray(exampleItem.dosages) &&
        exampleItem.dosages.length > 0 &&
        !("note" in exampleItem)
      ) {
        numSubHeaders = 1;
      }
      // If 'checked' exists, count it as a sub-header
      if (
        exampleItem.dosages.length === 0 &&
        exampleItem &&
        "checked" in exampleItem &&
        "note" in exampleItem
      ) {
        if (HideNotes) {
          numSubHeaders = 1;
        } else {
          numSubHeaders = 2;
        }
      }
      // If 'checked' exists, count it as a sub-header
      if (
        exampleItem.dosages.length === 0 &&
        exampleItem &&
        "checked" in exampleItem &&
        !("note" in exampleItem)
      ) {
        numSubHeaders = 1;
      }

      if (exampleItem && "customDosage" in exampleItem) {
        numSubHeaders += 1;
      }

      return numSubHeaders;
    };
    // State to hold edited values
    function splitByLastDash(columnName) {
      // Find the index of the last dash
      const lastIndex = columnName.lastIndexOf("-");

      // If there's no dash, or the dash is the first character, return an error or handle it as needed
      if (lastIndex <= 0) {
        console.error(
          "No dash found, or the dash is at the start of the string"
        );
        return columnName;
      }

      // Split the string into two parts
      const itemName = columnName.substring(0, lastIndex);
      const field = columnName.substring(lastIndex + 1);

      return [itemName, field];
    }
    const handleCellChange = (patientId, columnName, newValue, category) => {




      if (columnName != "custom" || columnName != "note") {
        // Update local state first
        setEditedValues((prevValues) => ({
          ...prevValues,
          [`${patientId}-${columnName}`]: newValue,
        }));
      }

      let payload;

      if (category === "vitals") {
        // Make sure newValue is an array and directly assign it to updates
        payload = {
          category: category,
          updates: newValue, // Ensure this is correctly formatted as an array of vitals
        };
      } else {
        // Handling for other categories remains the same
        const identifierKey =
          category === "userProcedures" ? "procedure_name" : "name";
        let [itemName, field] = splitByLastDash(columnName);

        if (category === "patientInfo") {
          field = columnName;
        }

        payload = {
          category: category,
          identifier: {
            key: identifierKey,
            value: itemName,
          },
          updates: {
            [field]: newValue,
          },
        };
      }

      // Send the update request using axios
      axios
        .put(
          `/patient/order/update/${patientId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {

        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    const updateEditedValues = (newValues) => {
      setEditedValues((prevValues) => ({
        ...prevValues,
        ...newValues,
      }));
    };

    // Extracts unique headers from a given data set
    function extractUniqueHeaders(
      data,
      dataKey,
      nameKey,
      contextData,
      contextNameKey = "name"
    ) {


      const itemNames = data.flatMap((patient) =>
        patient[dataKey].map((item) => item[nameKey])
      );

      const uniqueItemNames = Array.from(new Set(itemNames));

      const itemsWithIndex = uniqueItemNames.map((name) => {
        const item = contextData.find(
          (cItem) => cItem[contextNameKey] === name
        );
        return {
          name,

          index:
            item && !isNaN(item.index)
              ? Number(item.index)
              : Number.MAX_SAFE_INTEGER,
        };
      });

      const sortedItems = itemsWithIndex.sort((a, b) => a.index - b.index);


      return sortedItems.map((item) => item.name);
    }
    // Renders the main headers for the table
    const renderMainHeaders = () => {
      return (
        <>
          <TableRow className="MaterialTableHeaders">
            {/* Static headers */}
            <TableCell class="Date-Container-Fix-Header" align="center">
              Date
            </TableCell>
            <TableCell align="center" style={{ minWidth: "200px" }}>
              Custom Name
            </TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Final</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Assignees</TableCell>
            <TableCell align="center">Room</TableCell>
            <TableCell align="center">Note</TableCell>
            <TableCell align="center" style={{ width: "auto" }}>
              Del/Dup
            </TableCell>
            <TableCell align="center" style={{ width: "auto" }}>
              LPDV
            </TableCell>

            {/* <TableCell align="center" style={{ width: "100px" }}>
              Summary
            </TableCell> */}

            {/* Vitals header with a colSpan to account for sub-headers */}
            {/* {!hideVitals && (
          <TableCell align="center" colSpan={4}>
            Vitals
          </TableCell>
        )} */}

            {!hideUserProcedures &&
              userProcedureHeaders.map((header) =>
                visibleHeaders.userProcedures.has(header) &&
                  !hiddenEmptyColumns.has(header) ? (
                  <TableCell
                    key={header}
                    align="center"
                    colSpan={calculateSubHeadersForProcedure(header, data)}
                  >
                    {header}
                  </TableCell>
                ) : null
              )}
            {/* Dynamic headers for userDispensed */}
            {!hideUserDispensed &&
              userDispensedHeaders.map((header) =>
                visibleHeaders.userDispensed.has(header) &&
                  !hiddenEmptyColumns.has(header) ? (
                  <TableCell key={header} align="center" colSpan={3}>
                    {header}
                  </TableCell>
                ) : null
              )}
          </TableRow>
        </>
      );
    };

    // Renders the sub-headers for the table
    const renderSubHeaders = () => {
      return (
        <TableRow className="MaterialTableSubHeaders">
          {/* Placeholder cells for static headers */}
          <TableCell
            class="Date-Container-Fix-Header"
            align="center"
            colSpan={1}
          ></TableCell>
          <TableCell
            align="center"
            colSpan={1}
            style={{ width: "100px" }}
          ></TableCell>
          <TableCell align="center" colSpan={1}></TableCell>
          <TableCell align="center" colSpan={1}></TableCell>
          <TableCell align="center" colSpan={1}></TableCell>
          <TableCell align="center" colSpan={1}></TableCell>
          <TableCell align="center" colSpan={1}></TableCell>
          <TableCell align="center" colSpan={1}></TableCell>
          <TableCell align="center" colSpan={1}></TableCell>
          <TableCell align="center" colSpan={1}></TableCell>
          {/* Sub-headers for Vitals */}

          {/* {!hideVitals && (
          <>
            <TableCell align="center" style={{ width: "auto" }}>
              HR
            </TableCell>
            <TableCell align="center" style={{ width: "auto" }}>
              BP
            </TableCell>
            <TableCell align="center" style={{ width: "auto" }}>
              T
            </TableCell>
            <TableCell align="center" style={{ width: "auto" }}>
              W
            </TableCell>
          </>
        )} */}
          {/* Dynamic sub-headers for userProcedures */}
          {!hideUserProcedures &&
            userProcedureHeaders.flatMap((header) => {
              if (
                !visibleHeaders.userProcedures.has(header) ||
                hiddenEmptyColumns.has(header)
              ) {
                return [];
              }

              // Find a representative item to determine the type of sub-headers needed
              const exampleItem = data
                .flatMap((patient) => patient.userProcedures)
                .find((procedure) => procedure.procedure_name === header);

              let subHeaders = [];
              if (exampleItem) {
                // If dosages array is present and has items, add a Dosages sub-header
                if (
                  Array.isArray(exampleItem.dosages) &&
                  exampleItem.dosages.length > 0
                ) {
                  subHeaders.push(
                    <TableCell key={`${header}-dosages`} align="center">
                      Dosages
                    </TableCell>
                  );
                  // Add Note sub-header if HideNotes is not true and note exists in exampleItem
                  if (!HideNotes && "note" in exampleItem) {
                    subHeaders.push(
                      <TableCell key={`${header}-note`} align="center">
                        Note
                      </TableCell>
                    );
                  }
                }
                // If 'checked' is a property and dosages array is empty, add a Checked sub-header
                if (
                  "checked" in exampleItem &&
                  exampleItem.dosages.length === 0
                ) {
                  subHeaders.push(
                    <TableCell
                      key={`${header}-checked`}
                      className="MaterialTableSubHeaderCheckBox"
                      align="center"
                    >
                      <DoneIcon color="primary" />
                    </TableCell>
                  );
                  // Add Note sub-header if HideNotes is not true and note exists in exampleItem
                  if (!HideNotes && "note" in exampleItem) {
                    subHeaders.push(
                      <TableCell key={`${header}-note`} align="center">
                        Note
                      </TableCell>
                    );
                  }
                }
                // If 'customDosage' is a property add a Dosage sub-header
                if ("customDosage" in exampleItem) {
                  subHeaders.push(
                    <TableCell key={`${header}-customDosage`} align="center">
                      Dosage
                    </TableCell>
                  );
                }
              }

              return subHeaders;
            })}
          {/* Dynamic sub-headers for userDispensedItems */}
          {!hideUserDispensed &&
            userDispensedHeaders.flatMap((header) => {
              if (
                !visibleHeaders.userDispensed.has(header) ||
                hiddenEmptyColumns.has(header)
              ) {
                return []; // Skip rendering if header is not visible or is hidden
              }
              return [
                <TableCell key={`${header}-dosages`} align="center">
                  Dosages
                </TableCell>,
                <TableCell key={`${header}-quantity`} align="center">
                  Quantity
                </TableCell>,
                // Only render the "Note" cell if HideNotes is not true
                !HideNotes && (
                  <TableCell key={`${header}-note`} align="center">
                    Note
                  </TableCell>
                ),
              ];
            })}
        </TableRow>
      );
    };

    // Renders the body rows for the table
    const renderBodyRows = () => {


      const filteredData = data.filter((patient) => {
        let include = true;

        if (filters.Orders) {
          include = include && patient.type === "Order";
        }

        if (filters["Active Labs"]) {
          include = include && patient.userLabs.some((lab) => lab.checked);
        }

        if (filters["Active Procedures"]) {
          include =
            include &&
            patient.userProcedures.some((procedure) => procedure.checked);
        }

        if (filters["Active Dispensed"]) {
          include =
            include &&
            patient.userdispensed.some(
              (dispensed) => dispensed.selectedField !== ""
            );
        }

        return include;
      });

      return filteredData.map((patient) => (
        <MaterialTableRow
          treatments={data}
          patient={patient}
          key={patient._id}
          type={patient.type}
          assigneeOptions={assigneeOptions}
          roomOptions={roomOptions}
          rowStates={rowStates}
          toggleNoteField={toggleNoteField}
          getNoteIconColor={getNoteIconColor}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          handleDeleteRow={handleDeleteRow}
          handleDuplicateRow={handleDuplicateRow}
          handleOpenLabsModal={handleOpenLabsModal}
          handleOpenProcedureModal={handleOpenProcedureModal}
          handleOpenDispenseModal={handleOpenDispenseModal}
          handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
          handleOpenVitalsModal={handleOpenVitalsModal}
          hideUserProcedures={hideUserProcedures}
          userProcedureHeaders={userProcedureHeaders}
          visibleHeaders={visibleHeaders}
          hiddenEmptyColumns={hiddenEmptyColumns}
          HideNotes={HideNotes}
          hideUserDispensed={hideUserDispensed}
          userDispensedHeaders={userDispensedHeaders}
          updateEditedValues={updateEditedValues}
          customNameCellStyle={{ minWidth: "200px" }}
          setCheckedLabs={setCheckedLabs}
          setPreviousTreatmentId={setPreviousTreatmentId}
          onCopyClick={onCopyClick}
          checkboxValues={checkboxValues}
          setCheckboxValues={setCheckboxValues}
          contextMenu={contextMenu}
          setContextMenu={setContextMenu}
          newContextMenu={newContextMenu}
          setNewContextMenu={setNewContextMenu}
          handleClose={handleClose}
          handlePaste={handlePaste}
          previousTreatmentId={previousTreatmentId}
          handleOpenCopyConfirmationDialog={handleOpenCopyConfirmationDialog}
          // setUndoOrderId={setUndoOrderId}
          // setUndoToken={setUndoToken}
          undoOrderId={undoOrderId}
          undoToken={undoToken}
          handleUndo={handleUndo}
          pastedTreatmentId={pastedTreatmentId}
          previouslyCheckedValues={previouslyCheckedValues}
          setPasteAction={setPasteAction}
          setCopyKey={setCopyKey}
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
        />
      ));
    };

    return (
      <>
        <div className="button-container mt-2 flex flex-wrap">
          <button
            className="py-2 px-4 text-sm bg-gray-300 hover:bg-gray-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={handleToggle}
          >
            {HideNotes ? "Show Notes" : "Hide Notes"}
          </button>
          <button
            className="py-2 px-4 text-sm bg-gray-300 hover:bg-gray-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={toggleUserDispensedVisibility}
          >
            {hideUserDispensed ? "Show User Dispensed" : "Hide User Dispensed"}
          </button>
          <button
            className="py-2 px-4 text-sm bg-gray-300 hover:bg-gray-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={toggleUserProceduresVisibility}
          >
            {hideUserProcedures
              ? "Show User Procedures"
              : "Hide User Procedures"}
          </button>

          {/* <Button variant="contained" onClick={toggleVitalsVisibility}>
    {hideVitals ? "Show Vitals" : "Hide Vitals"}
    </Button> */}

          <button
            className="py-2 px-4 text-sm bg-gray-300 hover:bg-gray-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={() => {
              handleHideEmptyColumns();
            }}
          >
            {hiddenEmptyColumns.size > 0
              ? "Show All Empty Columns"
              : "Hide All Empty Columns"}
          </button>

          <button
            className="py-2 px-4 text-sm bg-gray-300 hover:bg-gray-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={showAllColumns}
          >
            Show All Columns
          </button>
        </div>

        <div>
          {renderHeaderSelectionDialog()}
          {renderLabsModal()}
          <TableContainer
            component={Paper}
            style={{ maxHeight: 650, overflow: "auto" }}
            className="TableContainer"
          >
            <Table stickyHeader className="my-treatments-table">
              <TableHead>
                {renderMainHeaders()}
                {renderSubHeaders()}
              </TableHead>
              <TableBody>{renderBodyRows()}</TableBody>
            </Table>
          </TableContainer>
        </div>
        <ProcedureModal
          open={procedureModalOpen}
          procedures={currentProcedures}
          onClose={() => {
            setProcedureModalOpen(false);
            handlePatientSelect(patientID);
           
          }}
          patientId={currentPatientIdForModal}
          handleCellChange={handleCellChange}
        />

        <DispenseModal
          open={dispenseModalOpen}
          dispensedItems={currentDispensedItems}
          onClose={() => {
            setDispenseModalOpen(false);
            handlePatientSelect(patientID);
            
          }}
          patientId={currentPatientIdForModal}
          handleCellChange={handleCellChange}
        />

        <AntdModal
          title="Treatment Note"
          visible={treatmentNoteModalOpen} // Changed to use the state variable for treatment notes
          onCancel={handleCloseTreatmentNoteModal} // Changed to the corresponding close handler function for treatment notes
          footer={null}
          width="80vw" // Set the width to 80% of the viewport width
          styles={{ maxHeight: "70vh", overflowY: "scroll" }}
        >
          <TreatmentNotes
            treatment={currentTreatment}
            isTreatmentNoteEditing={isTreatmentNoteEditing.current}
            setIsTreatmentNoteEditing={setIsTreatmentNoteEditing}
          />
        </AntdModal>

        <VitalsModal
          open={vitalsModalOpen}
          VitalsItems={currentVitalsItems}
          onClose={() => setVitalsModalOpen(false)}
          patientId={currentPatientIdForModal}
          handleCellChange={handleCellChange}
        />
        <ToastContainer />
        <Dialog
          open={openCopyConfirmationDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseCopyConfirmationDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box sx={{ padding: "10px" }}>
            <DialogTitle>{"Confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to paste ({pasteAction === 'modify' ? "merge" : pasteAction}) data to this
                treatment?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={handleCloseCopyConfirmationDialog}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  handlePaste(pasteAction);
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </>
    );
  }
);

export default PatientDataTable;
